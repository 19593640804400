import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from "@material-ui/icons/Add";
import SmsContactsListItem from '../SmsContactsListItem';
import InputBase from '@material-ui/core/InputBase';

import {
    // updateCallJournal,
    // getCallJournal,
    // getFaxes,
    // getRecordings,
    // getVoicemails,
    getSmsConversations,
  } from "../../utils/utils";
  import {
    setNotifications,
    setNotificationsLoading,
  } from "../../store/features/notification/notificationSlice";
//   import { setCalls } from "../../store/features/calls/callSlice";
//   import { setVoicemails } from "../../store/features/voicemails/voicemailSlice";
import { setSmsConversations } from "../../store/features/smsConversations/smsConversationsSlice";
import { setSmsContact } from "../../store/features/smsConversations/smsContactSlice";
import { setSmsNewConversationActive } from "../../store/features/smsConversations/smsNewConversationSlice";
import { initNewSms } from "../../store/features/smsConversations/smsNewMessageSlice";
import { initNewMms } from "../../store/features/smsConversations/smsNewMmsSlice";
import { setFaxes } from "../../store/features/faxes/faxSlice";
import { setRecordings } from "../../store/features/recordings/recordingSlice";
import { setError } from "../../store/features/error/errorSlice";
import { useStyles } from "./styles";
import { resetSession } from "../../store/features/session/sessionSlice";

const mapDispatchToProps = {
    // setCalls,
    // setVoicemails,
    setSmsConversations,
    setSmsContact,
    setSmsNewConversationActive,
    initNewSms,
    initNewMms,
    setFaxes,
    setRecordings,
    setNotifications,
    setError,
    setNotificationsLoading,
    resetSession,
};

function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    useEffect(() => {
      savedCallback.current = callback;
    });
  
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
  
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  }

const SmsContactsList = (props) => {
    const {
        smsConversations,
        smsNewConversation,
        session,
        resetSession,
        setSmsConversations,
        setSmsContact,
        smsContact,
        setSmsNewConversationActive,
        initNewSms,
        initNewMms,
        setValue,
        history,
      } = props;

    const classes = useStyles();
    const [filterText, setFilterText] = useState(['']);

    const defaultValues = {
        server: session.lastServer,
        token: session.token,
    };

    const parseUnreadMessages = (data = []) => {
        return data.filter((item) => item.unreadMessages !== 0);
      }

    const location = useLocation();
    const matches = location.pathname.match(/(?:^\/dashboard\/sms\/sms\/all)\/(?<conversation_id>[\d]+-[\d]+-[\d]+)/g);

    let urlConversationId = "";
    if (matches) {
        urlConversationId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
        history.push(`/dashboard/sms/sms/all`);
    }

    useInterval(() => {
        refetchNotifications();
    }, 60000);

    const refetchNotifications = () => {
        let fetchSmsConversations = getSmsConversations(defaultValues, setSmsConversations);

        Promise.all([fetchSmsConversations])
            .then((res) => {
                for (var i = 0; i < res.length; i++) {
                    if (res[i] instanceof Error && res[i].message === "Unauthorized") {
                        resetSession();
                        history.push('/login');
                        return;
                    }
                }

                if (Array.isArray(res[0].Conversations) && res[0].Conversations.length) {
                    initNewSms("new")
                    initNewMms("new")
                    if (urlConversationId && (!smsContact.id || smsContact.id !== urlConversationId)) {
                        let item = res[0].Conversations.find(item => item.conversation_id === urlConversationId);
                        initNewSms(item.conversation_id)
                        initNewMms(item.conversation_id)
                        setSmsContact(item);
                        setValue(item.number);
                    }
                    else {
                        if (!smsContact.id) {
                            // by default always open the first conversation
                            // history.push(`/dashboard/sms/sms/all/${res[0].Conversations[0].conversation_id}`);
                            initNewSms(res[0].Conversations[0].conversation_id)
                            initNewMms(res[0].Conversations[0].conversation_id)
                            setSmsContact(res[0].Conversations[0]);
                        }
                        setValue(res[0].Conversations[0].number);
                    }
                }
                setNotifications({
                    conversation: parseUnreadMessages(res[0].Conversations),
                });
                setNotificationsLoading(false);
            })
            .catch((err) => {
                console.error(err);
            });
    }; 

    const clearCurrentNotifications = () => {
        try {
            refetchNotifications();
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        clearCurrentNotifications();
        // eslint-disable-next-line
      }, [smsContact]);

    const handleNewConversation = () => {
        setSmsNewConversationActive(true);
        setSmsContact(smsNewConversation);
        setValue("")
    }

    return (
        <div key="smsContactsList" className={classes.smsContactsList}>
            <div key="smsContactsSearch" className={classes.smsContactsSearch}>
                <InputBase
                    key="smsContactsSearch_Input"
                    type="search"
                    className={classes.smsContactsSearchInput}
                    placeholder="Search..."
                    value={filterText}
                    onChange={event => setFilterText(event.target.value)}
                />
            </div>
            { smsNewConversation.active 
                              ? <SmsContactsListItem
                                    key='New message'
                                    data={smsNewConversation}
                                />
                              : <div/> }
            {
                smsConversations.filter(f => f.name.includes(filterText) || 
                                             f.number.includes(filterText) ||
                                             f.lastMessage.message.includes(filterText) ||
                                             filterText === '')
                    .map(item =>
                    <SmsContactsListItem
                        key={item.conversation_id}
                        data={item}
                        setValue={setValue}
                    />
                )
            }
            <div key="smsContactsButton" className={classes.smsContactsButton}>
                <IconButton
                    aria-label="sms"
                    className={classes.newSmsButton}
                    onClick={handleNewConversation}
                >
                    <AddIcon />
                </IconButton>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
        smsConversations: state.smsConversations,
        smsNewConversation: state.smsNewConversation,
        smsContact: state.smsContact,
        notifications: state.notifications,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SmsContactsList);