import { createSlice } from "@reduxjs/toolkit";

const ErrorSlice = createSlice({
  name: "error",
  initialState: {
    visible: false,
    type: "",
    message: "",
  },
  reducers: {
    setError(state, action) {
      let {visible, type, message} = action.payload;

      return {
        ...state,
        visible: visible,
        type: type,
        message: message
      };
    },
  },
});

export const { setError } = ErrorSlice.actions;
export default ErrorSlice.reducer;
