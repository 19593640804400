import React,{useState} from "react";
import clsx from "clsx";
import {
  setUser,
} from "../store/features/user/userSlice";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Tabs, Tab, Grid } from "@material-ui/core";
import TabPanel from "../components/tabPanel";
import UserDetails from "../components/userDetails";
import AdditionalSettings from "../components/additionalSettings";
import EmergencySettings from "../components/emergencySettings";
import { updateUserProfile, getUserProfileData } from "../utils/utils";
import HelpMenu from "../components/helpMenu";

const mapDispatchToProps = {
  setUser,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  title: {
    fontSize: "60px",
    fontWeight: "300",
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(4),
    margin: "0 !important",
  },
  tabs: {
    marginBottom: theme.spacing(3),
  },
  emergency: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "750px",
    },
  },
}));

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const UserProfile = (props) => {
    const classes = useStyles();
    const { session, setUser, user } = props;

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    

    return (
      <div className={classes.root}>
        <HelpMenu />
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <h1 className={classes.title}>My Profile</h1>
          </Grid>
          <Grid item xs={12} >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              className={classes.tabs}
            >
              <Tab label="User Details" {...a11yProps(0)} />
              <Tab label="Additional Settings" {...a11yProps(1)} />
              <Tab label="Emergency" {...a11yProps(2)} />
            </Tabs>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={0}
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} className={classes.gridContainer}>
            <TabPanel value={value} index={0}>
              <Paper className={clsx(classes.paper, classes.emergency)}>
                <UserDetails
                  user={user}
                  setUser={setUser}
                  updateUserProfile={updateUserProfile}
                  getUserProfileData={getUserProfileData}
                  session={session}
                />
              </Paper>
            </TabPanel>
          </Grid>
          <TabPanel value={value} index={1}>
            <Grid item xs={12}>
              <AdditionalSettings
                user={user}
                setUser={setUser}
                updateUserProfile={updateUserProfile}
                getUserProfileData={getUserProfileData}
                session={session}
              />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid item xs={12}>
              <Paper className={clsx(classes.paper, classes.emergency)}>
                <EmergencySettings
                  user={user}
                  setUser={setUser}
                  updateUserProfile={updateUserProfile}
                  session={session}
                />
              </Paper>
            </Grid>
          </TabPanel>
        </Grid>
      </div>
    );
};

const mapStateToProps = (state) => {
    return { 
        session: state.session, 
        user: state.user 
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
