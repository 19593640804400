import { createSlice } from "@reduxjs/toolkit";

const SmsNewMms = createSlice({
    name: "smsNewMms",
    initialState: {},
    reducers: {
        initNewMms(state, action) {
            const id = action.payload;
            
            if (Object.prototype.hasOwnProperty.call(state, id)) {
                return state;
            }

            return (state = {
                ...state, 
                [id]: null
            });
        },
        setNewMms(state, action) {
            const { id, mms } = action.payload;
            return (state = {
                ...state, 
                [id]: mms
            });
        },
        clearMms(state, action) {
            const id = action.payload;
            return (state = {
                ...state, 
                [id]: null 
            });
        },
    },
});

export const { initNewMms, setNewMms, clearMms } = SmsNewMms.actions;
export default SmsNewMms.reducer;