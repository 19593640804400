import React, { useState } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import clsx from "clsx";
import { connect } from "react-redux";
import { setError } from "../store/features/error/errorSlice";
import Drawer from '@material-ui/core/Drawer';
import { 
  setActiveCall,
  setSipSessions,
  setCallingNumber,
  setCalling,
  setSipCalling,
} from "../store/features/user/userSlice";
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { 
  renderDrawerMenu,
} from "../utils/common";
import { useStyles } from "./styles/viewStyles";
import HelpMenu from "../components/helpMenu";
import Contacts from "../components/contacts";

const mapDispatchToProps = {
  setError,
  setActiveCall,
  setSipSessions,
  setCallingNumber,
  setCalling,
  setSipCalling,
};

const ContactsView = (props) => {
  const classes = useStyles();
  const {
    match,
    history,
    window,
    setCallingNumber,
    setCalling,
    setSipCalling,
    voip,
  } = props;
  const tab = match.params.type;
  const isCurrentTab = (item) => tab.toLowerCase() === item.type.toLowerCase();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerMenuItems = [
    {
      text: "All",
      type: "All",
      to: "all"
    },
    {
      text: "Personal",
      type: "Personal",
      to: "personal"
    },
    {
      text: "Internal",
      type: "Internal",
      to: "internal"
    },
    {
      text: "External",
      type: "External",
      to: "external"
    },
  ];

  const drawerMenu = renderDrawerMenu(
    classes,
    drawerMenuItems,
    isCurrentTab,
    handleDrawerToggle,
    '/dashboard/contacts/'
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  const contactsMenu = [
    // {
    //   title: "Internal",
    //   linkId: "internal",
    // },
    // {
    //   title: "External",
    //   linkId: "external",
    // },
    // {
    //   title: "Personal",
    //   linkId: "personal",
    // },
  ];

  return (
    <div className={classes.root}>
      <HelpMenu />
      <nav className={classes.drawer} aria-label="contacts bar">
        <Hidden mdUp>
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div
              className={
                mobileOpen
                  ? clsx(classes.menuOpen, classes.menuButton)
                  : clsx(classes.menuClosed, classes.menuButton)
              }
            >
              <IconButton
                aria-label="open menu"
                onClick={handleDrawerToggle}
                className={classes.menuButtonIcon}
              >
                {mobileOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            {drawerMenu()}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawerMenu()}
          </Drawer>
        </Hidden>
      </nav>
      <Switch history={history} className={classes.content}>
        <Route path={`/dashboard/contacts/:tab`}>
          <Contacts
            title="Contacts"
            menu={false}
            tab={tab}
            menuItems={contactsMenu}
            setCalling={voip.active === 'yes' ? setSipCalling : setCalling}
            setValue={setCallingNumber}
          />
        </Route>
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    phonebook: state.phonebook,
    voip: state.user.voip,
    remoteNumber: state.user.remoteNumber,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactsView)
);
