import React,{useState,useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import EnhancedTable from "./enhancedTable";
import moment from "moment";
import {
  getCallJournal,
  getFaxes,
  getRecordings,
  getVoicemails,
  getSmsConversations,
  updateRecordingStatus,
  updateRecordings,
  forwardRecording,
  deleteRecording,
} from "../utils/utils";
import {
  setNotifications,
  setNotificationsLoading,
} from "../store/features/notification/notificationSlice";
import { setCalls } from "../store/features/calls/callSlice";
import { setVoicemails } from "../store/features/voicemails/voicemailSlice";
import { setSmsConversations } from "../store/features/smsConversations/smsConversationsSlice";
import { setFaxes } from "../store/features/faxes/faxSlice";
import { setRecordings } from "../store/features/recordings/recordingSlice";
import { setError } from "../store/features/error/errorSlice";
import Forward from "./forward";
import EditTitle from "./editTitle";
import { resetSession } from "../store/features/session/sessionSlice";

const mapDispatchToProps = {
  setError,
  setCalls,
  setVoicemails,
  setSmsConversations,
  setFaxes,
  setRecordings,
  setNotifications,
  setNotificationsLoading,
  resetSession,
};

const headCells = {
  all: [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
    },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "received", numeric: true, disablePadding: false, label: "Received" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ],
  new: [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
    },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "received", numeric: true, disablePadding: false, label: "Received" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ],
  viewed: [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
    },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "received", numeric: true, disablePadding: false, label: "Received" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ],
  urgent: [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
    },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "received", numeric: true, disablePadding: false, label: "Received" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ],
  deleted: [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
    },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "received", numeric: true, disablePadding: false, label: "Received" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ],
};


const Recording = (props) => {
  const {
    title,
    menu,
    menuItems,
    history,
    recordings,
    session,
    resetSession,
    setVoicemails,
    setSmsConversations,
    setFaxes,
    setRecordings,
    setNotifications,
    setNotificationsLoading,
    handleDialClick,
    notifications,
    setValue,
    match,
    setError,
  } = props;

  let recordingTab = match.params.recordingTab;
  const [page,setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [forward, setForward] = useState(false);
  const [forwardItem, setForwardItem] = useState(null)
  const [titleChangeItem, setTitleChangeItem] = useState(null);
  const [titleModal, setTitleModal] = useState(false)
  const defaultValues = {
    server: session.lastServer,
    token: session.token,
  };

  const generateRows = ()=>{
    switch (recordingTab) {
      case "all":
        return recordings
          .filter((item) => item.type.toLowerCase() !== "deleted")
          .map((item) => {
            return {
              title: item.title,
              status: item.type,
              received: moment(item.dateTime).format("LLL"),
              actions: [
                { type: "delete" },
                { type: "download" },
                { type: "editTitle" },
                { type: "play" },
                { type: "forward" },
              ],
              object: item,
            };
          });
      case "new":
        return recordings
          .filter((item) => item.type.toLowerCase() === "new")
          .map((item) => {
            return {
              title: item.title,
              status: item.type,
              received: moment(item.dateTime).format("LLL"),
              actions: [
                { type: "delete" },
                { type: "download" },
                { type: "editTitle" },
                { type: "play" },
                { type: "forward" },
              ],
              object: item,
            };
          });
      case "viewed":
        return recordings
          .filter((item) => item.type.toLowerCase() === "old")
          .map((item) => {
            return {
              title: item.title,
              status: item.type,
              received: moment(item.dateTime).format("LLL"),
              actions: [
                { type: "delete" },
                { type: "download" },
                { type: "editTitle" },
                { type: "play" },
                { type: "forward" },
              ],
              object: item,
            };
          });
      case "urgent":
        return recordings
          .filter((item) => item.type.toLowerCase() === "urgent")
          .map((item) => {
            return {
              title: item.title,
              status: item.type,
              received: moment(item.dateTime).format("LLL"),
              actions: [
                { type: "delete" },
                { type: "download" },
                { type: "editTitle" },
                { type: "play" },
                { type: "forward" },
              ],
              object: item,
            };
          });
      case "deleted":
        return recordings
          .filter((item) => item.type.toLowerCase() === "deleted")
          .map((item) => {
            return {
              title: item.title,
              status: item.type,
              received: moment(item.dateTime).format("LLL"),
              actions: [{ type: "delete" }],
              object: item,
            };
          });
      default:
        break;
    }
  }

  const parseNewItems = (data = []) => {
    return data.filter((item) => item.viewed === "no");
  };

  const parseUnreadMessages = (data = []) => {
    return data.filter((item) => item.unreadMessages !== 0);
  }

  const refetchNotifications = () => {
    const values = defaultValues;

    let fetchCalls = getCallJournal(values, setCalls),
      fetchFaxes = getFaxes(values, setFaxes),
      fetchRecordings = getRecordings(values, setRecordings),
      fetchVoicemails = getVoicemails(values, setVoicemails),
      fetchSmsConversations = getSmsConversations(values, setSmsConversations);

    setNotificationsLoading(true);

    Promise.all([fetchCalls, fetchFaxes, fetchRecordings, fetchVoicemails, fetchSmsConversations])
      .then((res) => {
        for (var i = 0; i < res.length; i++) {
          if (res[i] instanceof Error && res[i].message === "Unauthorized") {
            resetSession();
            history.push('/login');
            return;
          }
        }

        setNotifications({
          call: parseNewItems(res[0].journalEntries),
          fax: parseNewItems(res[1].faxDocuments),
          recording: parseNewItems(res[2].callRecordings),
          voicemail: parseNewItems(res[3].voicemailRecordings),
          conversation: parseUnreadMessages(res[4].Conversations),
        });
        setNotificationsLoading(false);
      })
      .catch((err) => {
        setNotificationsLoading(false);
        console.error(err);
      });
  };

  const clearCurrentNotifications = () =>{
    let values = defaultValues;

    values.recordings = recordings
      .filter(
        (recording) =>
          recording.viewed !== "yes"
      )
      .map((recording) => {
        return {
          rid: recording.rid,
          viewed: "yes",
          title: recording.title,
          type: recording.type
        };
      });

    try {
      updateRecordings(values);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    clearCurrentNotifications();
    // eslint-disable-next-line
  }, [recordingTab, title]);

  const handleStatusChange = async (type,item) => {
    let recordingType =
      type.toLowerCase() === "viewed" ? "old" : type.toLowerCase();
    try {
      await updateRecordingStatus(defaultValues, item.rid, recordingType);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  const handleDelete = (items) => {
    let values = defaultValues;

    values.recordings = items.map((item) =>
      item.hasOwnProperty("object")
        ? {
            rid: item.object.rid,
            title: item.object.title,
            viewed: "yes",
            type: "deleted",
          }
        : {
            rid: item.rid,
            title: item.title,
            viewed: "yes",
            type: "deleted",
          }
    );

    try {
      updateRecordings(values);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  const handleDeleteForever = (item) => {
    let values = defaultValues;
    values.id = item.rid

    try {
      deleteRecording(values);
      setError({
        visible: true,
        type: "success",
        message: "Successfully deleted Item",
      });
      refetchNotifications();
    } catch (error) {
      setError({
        visible: true,
        type: "error",
        message: error,
      });
      console.error(error);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Forward
          open={forward}
          setOpen={setForward}
          value={forwardItem}
          type="Recording"
          forwardFunction={forwardRecording}
          idKey="rid"
          defaultValues={defaultValues}
        />
        {titleModal && (
          <EditTitle
            open={titleModal}
            setOpen={setTitleModal}
            value={titleChangeItem}
            type="Recording"
            defaultValues={defaultValues}
            setTitleChangeItem={setTitleChangeItem}
            reloadNotifications={refetchNotifications}
          />
        )}

        <EnhancedTable
          setError={setError}
          type="recording"
          headerData={headCells}
          rowData={generateRows()}
          dense={false}
          hasActions={true}
          selectable={true}
          menu={menu ? menuItems : false}
          title={title}
          tab={recordingTab}
          history={history}
          hasClear={false}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          handleDialClick={handleDialClick}
          setValue={setValue}
          setForwardItem={setForwardItem}
          setForward={setForward}
          loading={notifications.loading}
          statusChangeFunction={handleStatusChange}
          handleDelete={handleDelete}
          handleDeleteForever={handleDeleteForever}
          setTitleChangeItem={setTitleChangeItem}
          setEditTitle={setTitleModal}
          useGroupFilter={false}
          refreshDataOnSameTab={clearCurrentNotifications}
        />
      </Grid>
    </Grid>
  );
};

Recording.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      linkId: PropTypes.string,
    })
  ),
  tab: PropTypes.string.isRequired,
  handleDialClick: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    recordings: state.recordings,
    notifications: state.notifications,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Recording)
);