import { createSlice } from "@reduxjs/toolkit";

const VoicemailSlice = createSlice({
  name: "voicemails",
  initialState: [],
  reducers: {
    setVoicemails(state, action) {
      let voicemails = action.payload.voicemailRecordings;

      return voicemails;
    },
  },
});

export const { setVoicemails } = VoicemailSlice.actions;
export default VoicemailSlice.reducer;
