import React,{useState,useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Tooltip } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import EnhancedTable from "./enhancedTable";
import moment from "moment";
import {
  updateCallJournal,
  getCallJournal,
  getFaxes,
  getRecordings,
  getVoicemails,
  getSmsConversations,
} from "../utils/utils";
import {
  setNotifications,
  setNotificationsLoading,
} from "../store/features/notification/notificationSlice";
import { setCalls } from "../store/features/calls/callSlice";
import { setVoicemails } from "../store/features/voicemails/voicemailSlice";
import { setSmsConversations } from "../store/features/smsConversations/smsConversationsSlice";
import { setFaxes } from "../store/features/faxes/faxSlice";
import { setRecordings } from "../store/features/recordings/recordingSlice";
import { setError } from "../store/features/error/errorSlice";
import { resetSession } from "../store/features/session/sessionSlice";

const mapDispatchToProps = {
  setCalls,
  setVoicemails,
  setSmsConversations,
  setFaxes,
  setRecordings,
  setNotifications,
  setError,
  setNotificationsLoading,
  resetSession,
};

const headCells = {
  all: [
    {
      id: "from",
      numeric: false,
      disablePadding: false,
      label: "From/To",
    },
    { id: "duration", numeric: true, disablePadding: false, label: "Duration" },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "received", numeric: true, disablePadding: false, label: "Received" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ],
  missed: [
    {
      id: "from",
      numeric: false,
      disablePadding: false,
      label: "From",
    },
    { id: "duration", numeric: true, disablePadding: false, label: "Duration" },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "received", numeric: true, disablePadding: false, label: "Received" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ],
  incoming: [
    {
      id: "from",
      numeric: false,
      disablePadding: false,
      label: "From",
    },
    { id: "duration", numeric: true, disablePadding: false, label: "Duration" },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "received", numeric: true, disablePadding: false, label: "Received" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ],
  outgoing: [
    {
      id: "to",
      numeric: false,
      disablePadding: false,
      label: "To",
    },
    { id: "duration", numeric: true, disablePadding: false, label: "Duration" },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "received", numeric: true, disablePadding: false, label: "Received" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ],
};

const CallJournal = (props) => {
  const {
    title,
    menu,
    menuItems,
    history,
    calls,
    session,
    resetSession,
    setCalls,
    setVoicemails,
    setSmsConversations,
    setFaxes,
    setRecordings,
    setNotifications,
    setNotificationsLoading,
    handleDialClick,
    setCalling,
    setValue,
    match,
    notifications,
    setError,
  } = props;

  let journalTab = match.params.journalTab;
  const [page,setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const defaultValues = {
    server: session.lastServer,
    token: session.token,
  };

  const formatTime = (time)=>{
    if(time>0 && time<60){
      return moment
        .unix(time)
        .utc()
        .format("s [sec]");
    }else if (time>=60 && time<3600){
      return moment
        .unix(time)
        .utc()
        .format("m [min and] s [sec]");
    }else if (time>=3600){
      return moment
        .unix(time)
        .utc()
        .format("H [hrs,] m [min, and] s [sec]");
    }else{
      return "-"
    }
  }

  const getFromValue = (item) =>
    !item.hasOwnProperty("name") ||
    (item.hasOwnProperty("name") && item.name === "") ? (
      item.number
    ) : (
      <Tooltip title={item.number} placement="right">
        <span>{item.name}</span>
      </Tooltip>
    );

  const generateRows = ()=>{
    switch (journalTab) {
      case "missed":
        return calls
          .filter(
            (item) =>
              item.state.toLowerCase() === "no answer" &&
              item.direction === "incoming"
          )
          .map((item) => {
            return {
              from: getFromValue(item),
              duration: formatTime(item.duration),
              status: item.state,
              received: moment(item.dateTime).format("LLL"),
              actions: [{ type: "call" }, { type: "deleteCall" }],
              object: item,
            };
          });
      case "incoming":
        return calls
          .filter((item) => item.direction === "incoming")
          .map((item) => {
            return {
              from: getFromValue(item),
              duration: formatTime(item.duration),
              status: item.state,
              received: moment(item.dateTime).format("LLL"),
              actions: [{ type: "call" }, { type: "deleteCall" }],
              object: item,
            };
          });
      case "outgoing":
        return calls
          .filter((item) => item.direction === "outgoing")
          .map((item) => {
            return {
              to: getFromValue(item),
              duration: formatTime(item.duration),
              status: item.state,
              received: moment(item.dateTime).format("LLL"),
              actions: [{ type: "call" }, { type: "deleteCall" }],
              object: item,
            };
          });
      case "all":
        return calls.map((item) => {
          return {
            from: getFromValue(item),
            duration: formatTime(item.duration),
            status: item.state,
            received: moment(item.dateTime).format("LLL"),
            actions: [{ type: "call" }, { type: "deleteCall" }],
            object: item,
          };
        });
      default:
        break;
    }
  }

  const parseNewItems = (data = []) => {
    return data.filter((item) => item.viewed === "no");
  };

  const parseUnreadMessages = (data = []) => {
    return data.filter((item) => item.unreadMessages !== 0);
  }

  const refetchNotifications = () => {
    let fetchCalls = getCallJournal(defaultValues, setCalls),
        fetchFaxes = getFaxes(defaultValues, setFaxes),
        fetchRecordings = getRecordings(defaultValues, setRecordings),
        fetchVoicemails = getVoicemails(defaultValues, setVoicemails),
        fetchSmsConversations = getSmsConversations(defaultValues, setSmsConversations);

    setNotificationsLoading(true);

    Promise.all([fetchCalls, fetchFaxes, fetchRecordings, fetchVoicemails, fetchSmsConversations])
      .then((res) => {

        for (var i = 0; i < res.length; i++) {
          if (res[i] instanceof Error && res[i].message === "Unauthorized") {
            resetSession();
            history.push('/login');
            return;
          }
        }

        console.log(res)
        setNotifications({
          call: parseNewItems(res[0].journalEntries),
          fax: parseNewItems(res[1].faxDocuments),
          recording: parseNewItems(res[2].callRecordings),
          voicemail: parseNewItems(res[3].voicemailRecordings),
          conversation: parseUnreadMessages(res[4].Conversations),
        });
        setNotificationsLoading(false);
      })
      .catch((err) => {
        console.log("I AM HERE:: " + JSON.stringify(err))
        setNotificationsLoading(false);
        console.error(err);
      });
  };

  const clearCurrentNotifications = () =>{
    let values = {
      server: session.lastServer,
      token: session.token,
    };

    // eslint-disable-next-line
    values.call = calls.map((item) => {
      if (item.viewed.toLowerCase() === "no") {
        return {
          jid: item.jid,
          viewed: "yes",
          type: item.type,
          direction: item.direction,
        };
      }
    }).filter(item=>typeof item!=="undefined");

    try {
      updateCallJournal(values);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  const handleDeleteForever = (item) => {
    let values = defaultValues;
    values.call = [{
      jid: item.jid,
      viewed: item.viewed,
      type: "deleted",
      direction: item.direction,
    }];

    try {
      updateCallJournal(values);
      setError({
        visible: true,
        type: "success",
        message: "Successfully deleted Item",
      });
      refetchNotifications();
    } catch (error) {
      setError({
        visible: true,
        type: "error",
        message: error,
      });
      console.error(error);
    }
  };

  useEffect(() => {
    clearCurrentNotifications();
    // eslint-disable-next-line
  }, [journalTab, title]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <EnhancedTable
          headerData={headCells}
          rowData={generateRows()}
          hasActions={true}
          selectable={false}
          menu={menu ? menuItems : false}
          title={title}
          tab={journalTab}
          history={history}
          hasClear={false}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          handleDialClick={handleDialClick}
          setCalling={setCalling}
          setValue={setValue}
          loading={notifications.loading}
          handleDeleteForever={handleDeleteForever}
          useGroupFilter={false}
          refreshDataOnSameTab={clearCurrentNotifications}
        />
      </Grid>
    </Grid>
  );
};

CallJournal.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      linkId: PropTypes.string,
    })
  ),
  tab: PropTypes.string.isRequired,
  handleDialClick: PropTypes.func.isRequired,
  setCalling: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    calls: state.calls,
    notifications: state.notifications,
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CallJournal));