import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(0,0,0,.25)",
    display: "flex",
    zIndex: "995",
    width: "calc(100vw)",
    height: "calc(100vh)",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiCircularProgress-colorPrimary": {
      color: theme.palette.primary.light,
    },
  },
}));

export default function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
}
