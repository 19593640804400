import React, { useEffect } from "react";
import Loading from "./components/loading";
import { getUserProfileData,getConfig,getSettings } from "./utils/utils";
import CssBaseline from "@material-ui/core/CssBaseline";
import Y2kNeophyte from "./assets/fonts/Y2K_neophyte.ttf";
import { withRouter, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { createTheme, lighten } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ThemeProvider } from "@material-ui/styles";
import Modal from '@mui/material/Modal';
import { themeObjectDark, themeObjectLight } from "./theme";
import { connect } from "react-redux";
import {
  setLoading,
  createSession,
  checkLocalSession,
  resetSession,
} from "./store/features/session/sessionSlice";
import { setError } from "./store/features/error/errorSlice";
import { setUser } from "./store/features/user/userSlice";
import { setBranding } from "./store/features/branding/brandingSlice";
import { setIssueForm } from "./store/features/issueForm/issueFormSlice";
import Login from "./views/login";
import Dashboard from "./views/dashboard";
import PrivateRoute from "./components/privateRoute";
import Toast from "./components/toast";
import ClearCache from 'react-clear-cache';

const mapDispatchToProps = {
  setUser,
  setLoading,
  setBranding,
  createSession,
  checkLocalSession,
  resetSession,
  setError,
  setIssueForm,
};

const App = (props) => {
  const {
    colors,
    setUser,
    loggedIn,
    issueForm,
    setIssueForm,
    checkLocalSession,
    resetSession,
    session,
    isLoading,
    setLoading,
    history,
    createSession,
    token,
    lastServer,
    error,
    setError,
    setBranding,
    branding,
    user,
  } = props;

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
    window.$(function () {
      window.$('#zammad-feedback-form').ZammadForm({
        messageTitle: 'Feedback Form',
        messageSubmit: 'Submit',
        messageThankYou: 'Thank you for your inquiry (#%s)! We\'ll contact you as soon as possible.',
        modal: false,
        attachmentSupport: false,
        noCSS: false,
        debug: true,
        attributes: [
          {
            display: 'Name',
            name: 'name',
            tag: 'input',
            type: 'text',
            placeholder: 'Your Name',
            defaultValue: user.firstName + ' ' + user.lastName,
          },
          {
            display: 'Email',
            name: 'email',
            tag: 'input',
            type: 'email',
            required: true,
            placeholder: 'Your Email',
            defaultValue: user.email,
          },
          {
            display: 'Message',
            name: 'body',
            tag: 'textarea',
            required: true,
            placeholder: 'Your Message...',
            defaultValue: '',
            rows: 12,
          },
        ]
      })
    })
  };
  const handleCloseModal = () => {
    setOpenModal(false)
    setIssueForm({enabled: false})
  };

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  let theme = prefersDarkMode?themeObjectDark:themeObjectLight;
  const typography = {
    fontFamily: `"Roboto", "Helvetica Nue", "Arial", sans-serif`,
    htmlFontSize: 18,
    fontSize: 18,
    body1: {
      fontFamily: "Roboto",
      color: theme.palette.text.primary,
      fontSize: "18px",
      letterSpacing: "0.016em",
      lineHeight: "24px",
      overflow: "hidden",
    },
  };
  const themeConfig = React.useMemo(
    () =>
      createTheme({
        typography,
        props: {},
        overrides: {
          PrivateNotchedOutline: {
            root: {
              borderWidth: "thin",
              top: "-4px",
            },
          },
          MuiTableRow: {
            root: {
              "&$selected, &$selected:hover": {
                backgroundColor: lighten(
                  prefersDarkMode
                    ? colors.darkMode.light
                    : colors.lightMode.light,
                  0.9
                ),
              },
              "&$hover:hover": {
                backgroundColor: lighten(
                  prefersDarkMode
                    ? colors.darkMode.light
                    : colors.lightMode.light,
                  0.9
                ),
              },
            },
          },
          MuiSvgIcon: {
            root: {
              width: "calc(1.5rem)",
              height: "calc(1.5rem)",
            },
          },
          MuiCheckbox: {
            root: {
              "&:hover": {
                backgroundColor: lighten(
                  prefersDarkMode
                    ? colors.darkMode.light
                    : colors.lightMode.light,
                  0.75
                ),
              },
              "&$checked, &$checked:hover": {
                "& svg": {
                  color: prefersDarkMode
                    ? colors.darkMode.light
                    : colors.lightMode.light,
                },
              },
            },
            colorSecondary: {
              "&$checked": {
                "&:hover": {
                  backgroundColor: lighten(
                    prefersDarkMode
                      ? colors.darkMode.light
                      : colors.lightMode.light,
                    0.75
                  ),
                },
              },
            },
          },
          MuiToggleButton: {
            root: {
              padding: ".2em .8em",
              border: "1px solid white",
              color: "white",
              backgroundColor: lighten(theme.palette.primary.light, 0.5),
              "&$selected": {
                backgroundColor: prefersDarkMode
                  ? colors.darkMode.light
                  : colors.lightMode.light,
                color: "white",
                "&:hover": {
                  backgroundColor: prefersDarkMode
                    ? colors.darkMode.light
                    : colors.lightMode.light,
                  color: "white",
                },
              },
              "&:hover": {
                backgroundColor: prefersDarkMode
                  ? colors.darkMode.light
                  : colors.lightMode.light,
                color: "white",
              },
            },
          },
          MuiToggleButtonGroup: {
            groupedHorizontal: {
              "&:not(:first-child)": {
                borderLeft: "1px solid white",
              },
            },
          },
          MuiTabs: {
            root: {
              color: theme.palette.text.secondary,
            },
            indicator: {
              backgroundColor: prefersDarkMode
                ? colors.darkMode.light
                : colors.lightMode.light,
            },
          },
          MuiTab: {
            root: {
              "&$selected": {
                color: prefersDarkMode
                  ? colors.darkMode.light
                  : colors.lightMode.light,
              },
              "&$disabled": {
                color: "rgba(0,0,0,.35)",
              },
            },
          },
          MuiCssBaseline: {
            "@global": {
              "@font-face": [Y2kNeophyte],
            },
          },
          MuiOutlinedInput: {
            root: {
              "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                borderColor: theme.palette.primary.light,
              },
              "&$focused $notchedOutline": {
                borderColor: theme.palette.primary.light,
              },
            },
          },
          MuiInputBase: {
            input: {
              "&:disabled": {
                "-webkit-text-fill-color": theme.palette.text.disabled,
              },
            },
          },
        },
        palette: {
          ...theme.palette,
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            ...theme.palette.primary,
            light: prefersDarkMode
              ? colors.darkMode.light
              : colors.lightMode.light,
            main: prefersDarkMode
              ? colors.darkMode.main
              : colors.lightMode.main,
            dark: prefersDarkMode
              ? colors.darkMode.dark
              : colors.lightMode.dark,
          },
        },
      }),
    // eslint-disable-next-line
    [prefersDarkMode, branding]
  );

  let location = useLocation();
  const values = {
    server: lastServer,
    token: session.token,
  };

  const loginFailed = () => {
    setLoading(false);
    resetSession();
    history.push("/login");
  };

  const getBrandingData = async () => {
    try{
      let fetchConfig = await getConfig(values, setBranding);
      if(fetchConfig instanceof Error) throw fetchConfig.message;

      setBranding(fetchConfig);
    }catch(error){
      setError({
        visible: true,
        type: "error",
        message: error,
      });
    }
    // eslint-disable-next-line
  };

  const refreshUserProfile = () => {
    (async function attemptGetProfile() {
      try {
        let profile = await getUserProfileData(values, setUser);

        if (profile && profile.userProfile) {
          createSession({ ...session, token: token, server: lastServer, loggedIn: true });

          location.state.from.pathname !== undefined
            ? history.push(location.state.from.pathname,{initialLoad:true})
            : history.push("/dashboard/call/phone/all",{initialLoad:true});
          setLoading(false);
        } else {
          throw new Error(
            "Failed to fetch user data. Please login to continue."
          );
        }
      } catch (error) {
        loginFailed();
        console.warn(error);
      }
    })();
    return false;
  };

  useEffect(() => {
    if (!loggedIn && isLoading === false && lastServer === "") {
      (async function attemptSessionRestore() {
        try {
          await checkLocalSession();
          if (localStorage.getItem("mcl-appData") === null) {
            loginFailed();
          } else {
            setLoading(true);
          }
        } catch (error) {
          console.warn(error);
        }
      })();
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  useEffect(()=>{
    if (branding.default === "yes") {
      getSettings(values, setBranding);
    }
    // eslint-disable-next-line
  },[loggedIn])

  useEffect(() => {
    const minHeight = 700;
    window.addEventListener('resize', function () {
      // Get the current height of the window
      const currentHeight = window.innerHeight;

      // If the current height is less than the minimum height, set it to the minimum height
      if (currentHeight < minHeight) {
        window.resizeTo(window.innerWidth, minHeight);
      }
    });
  })

  useEffect(() => {
    if(isLoading && !loggedIn && lastServer !== ""){
      getBrandingData();
      switch (token) {
        case "":
          try {
            loginFailed();
            if (session.username === "" || session.user_display_name === "") {
              throw new Error("Session not found. Please login to continue.");
            } else {
              throw new Error(
                "Session Expired. Please login again to continue."
              );
            }
          } catch (error) {
            console.warn(error);
          }
          break;
        default:
          refreshUserProfile();
          break;
      }
    }
    // eslint-disable-next-line
  }, [session, loggedIn]);
  
  useEffect(() => {
    if (issueForm.enabled) { 
      handleOpenModal()
    }
     // eslint-disable-next-line
  }, [issueForm.enabled]);

  const formStyle = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: "left",
    background: "white",
    borderRadius: "5px",
    padding: "24px 24px 22px",
    width: "30%",
    margin: "20px auto",
    boxSizing: "border-box",
    display: "inline-block",
    verticalAlign: "middle",
    boxShadow: "0 0 50px rgba(0, 0, 0, 0.3)",
    color: "#949494",
  };

  return (
    <ClearCache duration={60000} auto={true} filename="build.json">
      {({ isLatestVersion, emptyCacheStorage }) => (
        <div>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
          >
            <div style={formStyle} id="zammad-feedback-form" />
          </Modal>
          <ThemeProvider theme={themeConfig}>
            <CssBaseline />
            {error.visible ? (
              <Toast
                message={error.message}
                severity={error.type}
                setOpen={setError}
                open={error.visible}
              />
            ) : null}
            {isLoading ? <Loading /> : null}
            <Switch history={history}>
              <Route exact path="/login">
                <Login />
              </Route>
              <PrivateRoute exact path="/">
                <Redirect to="/dashboard/call/phone/all" />
              </PrivateRoute>
              <PrivateRoute path="/dashboard">
                <Dashboard />
              </PrivateRoute>
              <PrivateRoute path="/dashboard/*">
                <Dashboard />
              </PrivateRoute>
              <Route path="*">
                {loggedIn ? <Dashboard /> : <Redirect to="/login" />}
              </Route>
            </Switch>
          </ThemeProvider>
        </div>
      )}
    </ClearCache>
  );
};

const mapStateToProps = (state) => {
  return {
    issueForm: state.issueForm,
    error: state.error,
    user: state.user,
    session: state.session,
    isLoading: state.session.isLoading,
    token: state.session.token,
    lastServer: state.session.lastServer,
    loggedIn: state.session.loggedIn,
    colors: state.branding.colors,
    branding: state.branding,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
