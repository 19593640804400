import { createSlice } from "@reduxjs/toolkit";

const SessionSlice = createSlice({
  name: "session",
  initialState: {
    isLoading: false,
    loggedIn: false,
    lastServer: "",
    token: "",
    user_display_name: "",
    username: "",
  },
  reducers: {
    setLoading: (state, action) => {
      return (state = {
        ...state,
        isLoading: action.payload,
      });
    },
    createSession: (state, action) => {
      let { token, server, voipX } = action.payload;
      let newState = {}

      if (typeof action.payload.userProfile !== "undefined") {
        newState = {
          ...state,
          lastServer: server,
          loggedIn: true,
          token: token,
          user_display_name: action.payload.userProfile.user_display_name,
          username: action.payload.userProfile.username,
          voipX: voipX,
        };
      } else {
        newState = {
          ...state,
          lastServer: server,
          loggedIn: true,
          token: token,
          user_display_name: "",
          username: "",
          voipX: voipX,
        };
      }

      return (state = newState);
    },
    resetSession: (state, action) => {
      var tokenCheck = JSON.parse(localStorage.getItem("mcl-appData"));

      if (tokenCheck !== null) {
        localStorage.setItem(
          "mcl-appData",
          JSON.stringify({
            ...tokenCheck,
            token: "",
            user_display_name: "",
            user_email: "",
            username: "",
            voipX: "",
          })
        );
      }

      return (state={
        ...state,
        loggedIn: false,
        token: "",
        user_display_name: "",
        user_email: "",
        username: "",
        voipX: "",
      });
    },
    checkLocalSession: (state, action) => {
      let tokenCheck = JSON.parse(localStorage.getItem("mcl-appData"));

      if (tokenCheck !== null) {
        // token is present
        let { lastServer, token, user_display_name, username, voipX } = tokenCheck;

        return (state = {
          ...state,
          loggedIn: false,
          lastServer: lastServer,
          token: token,
          user_display_name: user_display_name,
          username: username,
          voipX: voipX,
        });
      } else {
        // token is not present
        return (state = {
          ...state,
          loggedIn: false,
          token: "",
          user_display_name: "",
          username: "",
          voipX: "",
        });
      }
    },
    updateLocalSession: (state, action) => {
      let { server, token, userProfile, voipX } = action.payload;

      localStorage.setItem(
        "mcl-appData",
        JSON.stringify({
          lastServer: server,
          token: token,
          user_display_name: userProfile.firstName,
          user_email: userProfile.email,
          username: userProfile.username,
          voipX: voipX,
        })
      );
      return state;
    },
  },
});

export const {
  setLoading,
  resetSession,
  createSession,
  checkLocalSession,
  updateLocalSession,
} = SessionSlice.actions;

export default SessionSlice.reducer;
