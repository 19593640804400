import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
        height: "100vh",
        maxWidth: "100vw",
    },
    main: {
        width: "auto",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        maxHeight: `calc(100vh - 56px)`,
        margin: "8px",
        [theme.breakpoints.up("xs")]: {
            maxHeight: `calc(100vh - 48px)`,
        },
        [theme.breakpoints.up("sm")]: {
            maxHeight: `calc(100vh - 64px)`,
        },
        "& ul": {
            paddingTop: 0,
        },
        "& .MuiTypography-body1": {
            color: theme.palette.text.primary,
        },
        background: theme.palette.primary.lighter,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: "100%",
        height: "auto",
        minWidth: "360px",
        minHeight: "60px",
        borderRadius: "0 0 10px 10px",
        [theme.breakpoints.up("md")]: {
            minHeight: "90px",
        },
    },
    colorDefault: {
        backgroundColor: theme.palette.primary.light,
    },
    title: {
        flex: "auto",
        color: "white",
        fontWeight: 300,
        textAlign: "center",
        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
        },
    },
    sessionContainer: {
        width: "100%",
        // height: "100%",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    sessionContainerMax: {
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    inactiveSession: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        backgroundColor: theme.palette.primary.lighter,
        margin: "0px 0px 8px 0px"
    },
    activeSession: {
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#bbdced",
        margin: "0px 0px 0px 0px" 
    },
    centralAvatar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "6rem",
        height: "6rem",
        fontSize: '1.4em',
        margin: "0px 0px 16px 0px",
    },
    activeRing: {
        border: "2px solid #b6ffb2",
    },
    onHoldRing: {
        border: "2px solid #b3b3b3",
    },
    ringingRing: {
        border: "2px solid #feffb2",
    },
    callingRing: {
        border: "2px solid #ffe3b2",
    },
    incomingRing: {
        border: "2px solid #b2f9ff",
    },
    centralInfo: {
        margin: "0px 0px 0px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    centralSessionStatus: {
        fontWeight: "bold",
        fontSize: "1.0em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        margin: "0px 0px -4px 0px"
    },
    centralName: {
        fontWeight: "normal",
        fontSize: "2em",
        margin: "0px 0px -4px 0px",
        display: "flex",
    },
    centralNumber: {
        fontSize: "1.0em",
        margin: "0px 0px 12px 0px",
        display: "flex",
    },
    centralActions: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        "& button:not(:last-child)" : {
            margin: "0px 12px 0px 0px"
        },
        paddingBottom: "64px",
    },
    centralCallButtons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        "& button:not(:last-child)" : {
            margin: "0px 16px 0px 0px"
        }
    },
    contactPhoto: {
        width: "3rem !important",
        height: "3rem !important",
        borderRadius: '50%',
        objectFit: 'cover',
    },
    centralContactPhoto: {
        width: "6rem !important",
        height: "6rem !important",
        borderRadius: '50%',
        objectFit: 'cover',
    },
    noContactPhoto: {
        width: "3rem !important",
        height: "3rem !important",
        borderRadius: '50%',
        objectFit: 'cover',
        color: 'white',
        background: theme.palette.primary.dark,
    },
    centralNoContactPhoto: {
        width: "6rem !important",
        height: "6rem !important",
        borderRadius: '50%',
        objectFit: 'cover',
        color: 'white',
        background: theme.palette.primary.dark,
    },
    verticalAvatar: {
        marginLeft: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    verticalInfo: {
        margin: "0px 0px 0px 16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "left",
        width: "100%",
    },
    verticalSessionStatus: {
        fontWeight: "bold",
        fontSize: "1.0em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        alignItems: "center",
    },
    verticalName: {
        fontWeight: "bold",
        fontSize: "1.2em",
        margin: "-6px 0px -6px 0px"
    },
    verticalNumber: {
        fontSize: "0.9em",
    },
    verticalActions: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        "& button:not(:last-child)" : {
            margin: "0px 12px 0px 0px"
        }
    },
    verticalCallButtons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        margin: "0px 32px 0px 48px",
        "& button:not(:last-child)" : {
            margin: "0px 16px 0px 0px"
        }
    },
    avatar: {
        width: "3rem",
        height: "3rem",
        fontSize: '1.4em',
        margin: "18px 0px 18px 0px",
    },
    sessionStatusIcon: {
        transform: "scale(0.6)",
        marginLeft: "-6px"
    },
    iconButton: {
        width: "2.25rem",
        height: "2.25rem",
        borderRadius: "50%",
        background: theme.palette.primary.contrastText,
        color: theme.palette.text.primary,
    },
    iconButtonMedium: {
        width: "2.25rem",
        height: "2.25rem",
        borderRadius: "50%",
        background: theme.palette.primary.contrastText,
        color: theme.palette.text.primary,
        fontSize: "medium",
    },
    iconButtonRed: {
        width: "2.25rem",
        height: "2.25rem",
        borderRadius: "50%",
        background: theme.palette.primary.contrastText,
        color: "#f40d1b",
    },
    callIcon: {
        width: "2.5rem",
        height: "2.5rem",
        borderRadius: "50%",
        color: theme.palette.primary.contrastText,
        background: "#1bb04a",
    },
    callIconLarge: {
        width: "3.0rem",
        height: "3.0rem",
        borderRadius: "50%",
        color: theme.palette.primary.contrastText,
        background: "#1bb04a",
    },
    callEndIcon: {
        width: "2.5rem",
        height: "2.5rem",
        borderRadius: "50%",
        color: theme.palette.primary.contrastText,
        background: "#f40d1b",
    },
    callEndIconLarge: {
        width: "3.0rem",
        height: "3.0rem",
        borderRadius: "50%",
        color: theme.palette.primary.contrastText,
        background: "#f40d1b",
    },
    marginRight: {
        marginRight: "16px",
    }
}));

export { useStyles }