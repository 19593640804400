import { createSlice } from "@reduxjs/toolkit";

const SmsContactSlice = createSlice({
  name: "smsContact",
  initialState: { 
    id: "", 
    now: Date.now(),
  },
  reducers: {
    setSmsContact(state, action) {
      let { conversation_id } = action.payload;
      return (state = { 
        id: conversation_id, 
        now: Date.now(),
      });
    },
    resetSmsContact(state, action) {
      return (state = { 
        id: "", 
        now: Date.now(),
      });
    }
  },
});

export const { setSmsContact, resetSmsContact } = SmsContactSlice.actions;
export default SmsContactSlice.reducer;
