import React, {useState} from 'react';
import moment from 'moment';
import clsx from  'clsx';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Image from 'material-ui-image';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { useStyles } from "./styles";

export default function Message(props) {
    const {
      data,
      isMine,
      startsSequence,
      endsSequence,
      showTimestamp,
    } = props;
    const classes = useStyles();
    const [mmsOpen,setMmsOpen] = useState(false);

    let mmsData = "";
    if (data.type === "mms") {
      mmsData = data.file.startsWith("data:") 
              ? data.file
              : `data:image/jpg;base64, ${data.file}`
    }

    const mmsMediaClicked = () => {
      setMmsOpen(true)
    };

    const friendlyTimestamp = moment(data.dateTime).format('LLLL');
    const messageHour = moment(data.dateTime).format('LT');
    return (
      <div className={clsx(classes.message)}>
        {mmsOpen && (
          <Lightbox
            mainSrc={mmsData}
            onCloseRequest={() => setMmsOpen(false)}
            reactModalStyle={{overlay: {
              zIndex: 995,
          }}}
          />
        )}
        {
          showTimestamp &&
            <div className={classes.timestamp}>
                {friendlyTimestamp}
            </div>
        }

        <div className={ isMine ? classes.bubbleContainerMine : classes.bubbleContainer}>
          <div className={clsx(isMine ? classes.bubbleMine : classes.bubble,
                               startsSequence ? isMine ? classes.startBubbleMine : classes.startBubble : null,
                               endsSequence ? isMine ? classes.endBubbleMine : classes.endBubble : null)}
             title={friendlyTimestamp}>
            <div className={classes.newLinesText}>{data.message}</div>
            <div className={classes.bubbleMMS}>
              {data.type === "mms" ? <Image src={mmsData} onClick={mmsMediaClicked}/>
                                   : null}
            </div>
            <div className={classes.bubbleTime}>{messageHour}</div>
          </div>
          {data.status === "failed" ? <ErrorOutlineIcon className={classes.notDeliveredIcon}/>
                                    : null }
        </div>
        {data.status === "failed" ? <div className={classes.messageMineNotDelivered}>Not Delivered</div>
                                  : null }
      </div>
    );
}