import { createSlice } from "@reduxjs/toolkit";

const SmsNewMessage = createSlice({
    name: "smsNewMessage",
    initialState: {},
    reducers: {
        initNewSms(state, action) {
            const id = action.payload;
            
            if (Object.prototype.hasOwnProperty.call(state, id)) {
                return state;
            }

            return (state = {
                ...state, 
                [id]: ""
            });
        },
        setNewSms(state, action) {
            const { id, message } = action.payload;
            return (state = {
                ...state, 
                [id]: message
            });
        },
        clearSms(state, action) {
            const id = action.payload;
            return (state = {
                ...state, 
                [id]: "" 
            });
        },
    },
});

export const { initNewSms, setNewSms, clearSms } = SmsNewMessage.actions;
export default SmsNewMessage.reducer;
