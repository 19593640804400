import { combineReducers } from "redux"
import SessionReducer from "../features/session/sessionSlice"
import UserReducer from "../features/user/userSlice"
import NotificationReducer from "../features/notification/notificationSlice"
import CallsReducer from "../features/calls/callSlice"
import VoicemailsReducer from "../features/voicemails/voicemailSlice"
import FaxesReducer from "../features/faxes/faxSlice"
import RecordingsReducer from "../features/recordings/recordingSlice"
import TimeFrameReducer from "../features/timeFrame/timeFrameSlice"
import ErrorsReducer from "../features/error/errorSlice"
import PhonebookReducer from "../features/phonebook/phonebookSlice"
import ExternalPhonebookReducer from "../features/phonebook/externalPhonebookSlice"
import PersonalPhonebookReducer from "../features/phonebook/personalPhonebookSlice"
import ConferenceReducer from "../features/conferences/conferenceSlice"
import BrandingReducer from "../features/branding/brandingSlice"
import IssueFormReducer from "../features/issueForm/issueFormSlice"
import SmsConversationsReducer from "../features/smsConversations/smsConversationsSlice"
import SmsContactReducer from "../features/smsConversations/smsContactSlice"
import SmsNewConversationReducer from "../features/smsConversations/smsNewConversationSlice"
import SmsNewMessage from "../features/smsConversations/smsNewMessageSlice"
import SmsNewMms from "../features/smsConversations/smsNewMmsSlice"

export default combineReducers({
  error: ErrorsReducer,
  session: SessionReducer,
  user: UserReducer,
  notifications: NotificationReducer,
  calls: CallsReducer,
  voicemails: VoicemailsReducer,
  faxes: FaxesReducer,
  recordings: RecordingsReducer,
  timeFrame: TimeFrameReducer,
  phonebook: PhonebookReducer,
  externalPhonebook: ExternalPhonebookReducer,
  personalPhonebook: PersonalPhonebookReducer,
  conferences: ConferenceReducer,
  branding: BrandingReducer,
  issueForm: IssueFormReducer,
  smsConversations: SmsConversationsReducer,
  smsContact: SmsContactReducer,
  smsNewConversation: SmsNewConversationReducer,
  smsNewMessage: SmsNewMessage,
  smsNewMms: SmsNewMms,
})