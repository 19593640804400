import { createSlice } from "@reduxjs/toolkit";

const PhonebookSlice = createSlice({
  name: "phonebook",
  initialState: [],
  reducers: {
    setPhonebook(state, action) {
      const { internalDirectory } = action.payload;
      return (state = internalDirectory);
    },
  },
});

export const { setPhonebook, getPhonebookEntry } = PhonebookSlice.actions;
export default PhonebookSlice.reducer;
