import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { 
  getPhoneBookEntry,
  getPhoneBookEntryByName,
} from "../utils/common";

const useStyles = makeStyles((theme) => ({
  autocompleteInput: {
    backgroundColor: theme.palette.primary.contrastText,
    fontSize: "16px",
  },
}));

const AutoCompleteSelect = (props) => {
    const classes = useStyles();
    const {
      options,
      placeholder,
      setValue,
      setViewValue,
      multiple,
      viewValue,
      onTextFieldKeyPress,
      phonebook,
    } = props;

    const onChangeCallback = (e, newValue, reason) => {
      isNaN(Number(newValue)) && typeof newValue !== "object"
      ? setValue(null)
      : setValue(newValue)
    }

    const onInputChangeCallback = (e, newValue) => {
      if (!isNaN(Number(newValue))) {
        setViewValue(newValue)
        if (phonebook && newValue !== "" ) {
          const entry = getPhoneBookEntry(phonebook, newValue)
          setValue(entry)
        }
      } else if (phonebook && newValue !== "") {
        const entry = getPhoneBookEntryByName(phonebook, newValue)
        setValue(entry)
      }
    }
  
    return (
      <Autocomplete
        multiple={multiple ? true : false}
        id="combo-box-demo"
        options={options.sort(
          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
        )}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            : `${option.firstName} ${option.lastName}`
        }
        value={viewValue}
        forcePopupIcon
        freeSolo={true}
        onChange={onChangeCallback}
        onInputChange={onInputChangeCallback}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={placeholder}
              variant="outlined"
              fullWidth
              className={classes.autocompleteInput}
              onKeyPress={(e) => {
                if (onTextFieldKeyPress) {
                  onTextFieldKeyPress(e);
                }
              }}
            />
          );
        }}
      />
    );
}

export default AutoCompleteSelect;