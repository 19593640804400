import moment from 'moment';
import React, {useState} from 'react';
import { connect } from "react-redux";
import { Text } from 'react-native';
import { useStyles, useNativeStyles } from "./styles";
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
import { setSmsContact, resetSmsContact } from "../../store/features/smsConversations/smsContactSlice";
import { clearSms, initNewSms } from "../../store/features/smsConversations/smsNewMessageSlice";
import { clearMms, initNewMms } from "../../store/features/smsConversations/smsNewMmsSlice";
import { setSmsNewConversationActive, setSmsNewConversationNumber } from "../../store/features/smsConversations/smsNewConversationSlice";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles';

import { themeObjectDark, themeObjectLight } from "../../theme";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const StyledChip = withStyles({
  label: {
    padding: '0px 6px 0px 6px',
  },
})(Chip);

const mapDispatchToProps = {
  setSmsContact,
  resetSmsContact,
  setSmsNewConversationActive,
  setSmsNewConversationNumber,
  clearSms,
  initNewSms,
  clearMms,
  initNewMms,
};

const SmsContactsListItem = (props) => {
  const {
    smsContact,
    setSmsContact,
    resetSmsContact,
    setSmsNewConversationActive,
    setSmsNewConversationNumber,
    clearSms,
    initNewSms,
    clearMms,
    initNewMms,
    setValue,
  } = props;

  const classes = useStyles();

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  let theme = prefersDarkMode?themeObjectDark:themeObjectLight;

  const nativeClasses = useNativeStyles(theme);
  const [childIsHover, setChildIsHover] = useState(false);

  const discardNewMessage = () => {
    resetSmsContact();
    setSmsNewConversationActive(false);
    setSmsNewConversationNumber("");
    clearSms("new");
    clearMms("new");
  }

  const renderDiscardNewMessage = (conversation_id) => {
    if (conversation_id !== 'new')
      return null;

    return (
      <Button 
        disableRipple
        style={{ backgroundColor: 'transparent', minWidth: '25px', maxWidth: '25px'}}
        id='discard_button'
        onClick={(e) => { discardNewMessage() }}>
        <CloseIcon className={(childIsHover) ? classes.discardNewMessageIconHover : classes.discardNewMessageIcon}/>
      </Button>
    )
  }

  const renderDateOnly = (conversation_id, dateOnly) => {
    if (conversation_id === 'new')
      return null;

    return (
      <p className={classes.smsContactsDate}>{dateOnly}</p>
    )
  }

  const { profileImage, name, number, number_pretty, lastMessage, unreadMessages, conversation_id } = props.data;

  const messageTime = moment(lastMessage.dateTime);
  let lastMessageDateOrTime = messageTime.format('DD/MM/YY');
  const now = moment(Date.now());

  // Check if same day  
  if (now.isSame(messageTime, 'day')) {
    lastMessageDateOrTime = messageTime.format('LT');
  }
  // Check if in last 6 days (no more than a week)
  else if (moment.duration(now.diff(messageTime)).as('days') < 7) {
    lastMessageDateOrTime = messageTime.format('dddd')
  }

  const getName = () => {
    return (name !== "") ? name
                         : (number_pretty !== "") ? number_pretty
                                                  : number;
  }

  const unreadMessagesLabel = (unreadMessages && unreadMessages !== 0) ? ((unreadMessages > 999) ? '999+' : unreadMessages)
                                                                       : null;

  return (
    <div 
      onMouseEnter={() => setChildIsHover(true)}
      onMouseLeave={() => setChildIsHover(false)}
      className={smsContact.id === conversation_id ? classes.smsContactsListItemSelected 
                                                   : classes.smsContactsListItem}>
      <Button
        id={conversation_id}
        disableRipple
        style={{ backgroundColor: 'transparent', flex: 1, justifyContent: 'flex-start', }}
        onClick={(e) => { 
          initNewSms(conversation_id);
          initNewMms(conversation_id); 
          setSmsContact(props.data); 
          if (setValue) { 
            setValue(number); 
          } 
        }}>
        {profileImage ? <img className={classes.smsContactsPhoto} src={profileImage} alt="sms" />
                      : <PersonIcon className={classes.smsNoContactsPhoto} />}
        <div className={classes.smsContactsInfo}>
          <div className={classes.contactNameDate}>
            <h1 className={classes.smsContactsTitle}>{getName()}</h1>
            {renderDateOnly(conversation_id, lastMessageDateOrTime)}
          </div>
          <div style={{
            display: "flex", 
            flexDirection: 'row', 
            justifyContent: "space-between",
           }}>
            <Text
              style={nativeClasses.trimmedMessage}
              numberOfLines={2}
              ellipsizeMode={'tail'}>
              {lastMessage.message}
            </Text>
            <div style={{
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "space-between",
              minHeight: 38,
              paddingLeft: '4px',
              minWidth: 42
             }}>
              {lastMessage.status === "failed" ? <ErrorOutlineIcon className={classes.notDeliveredIcon}/>
                                               : null }
              {unreadMessagesLabel ? <StyledChip
                                        label={unreadMessagesLabel}
                                        className={classes.unreadMessages}
                                        />
                                   : null }
            </div>
          </div>
        </div>
      </Button>
      {renderDiscardNewMessage(conversation_id)}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
      smsContact: state.smsContact,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmsContactsListItem);
