import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import monitorReducersEnhancer from "./enhancers/monitorReducer";
import loggerMiddleware from "./middleware/logger";
import rootReducer from "./reducers/index";

export default function configureAppStore(preloadedState) {
	const store = configureStore({
    reducer: rootReducer,
    middleware: process.env.NODE_ENV !== "production" ? getDefaultMiddleware().concat(loggerMiddleware):getDefaultMiddleware(),
    preloadedState,
    devTools: process.env.NODE_ENV !== "production",
    enhancers: process.env.NODE_ENV !== "production" ?[monitorReducersEnhancer]:[],
  });

	if (process.env.NODE_ENV !== "production" && module.hot) {
		module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
	}
	
	return store;
}