import React,{useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid, Tooltip
} from "@material-ui/core";
import EnhancedTable from "./enhancedTable";
import moment from "moment";
import {
  updateCallJournal,
  updateVoicemailViewed,
  updateRecordingsViewed,
  updateFaxes,
  updateConversationsViewed,
  getCallJournal,
  getFaxes,
  getRecordings,
  getVoicemails,
  getSmsConversations,
} from "../utils/utils";
import {
  setNotifications,
  setNotificationsLoading,
} from "../store/features/notification/notificationSlice";
import { setCalls } from "../store/features/calls/callSlice";
import { setVoicemails } from "../store/features/voicemails/voicemailSlice";
import { setSmsConversations } from "../store/features/smsConversations/smsConversationsSlice";
import { setFaxes } from "../store/features/faxes/faxSlice";
import { setRecordings } from "../store/features/recordings/recordingSlice";
import { resetSession } from "../store/features/session/sessionSlice";

const mapDispatchToProps = {
  setCalls,
  setVoicemails,
  setSmsConversations,
  setFaxes,
  setRecordings,
  setNotifications,
  setNotificationsLoading,
  resetSession,
};

const headCells = {
  call: [
    {
      id: "from",
      numeric: false,
      disablePadding: false,
      label: "From",
    },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "dateTime", numeric: true, disablePadding: false, label: "Date/Time" }
  ],
  voicemail: [
    {
      id: "from",
      numeric: false,
      disablePadding: false,
      label: "From",
    },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "dateTime", numeric: true, disablePadding: false, label: "Date/Time" }
  ],
  fax: [
    {
      id: "from",
      numeric: false,
      disablePadding: false,
      label: "From",
    },
    { id: "title", numeric: true, disablePadding: false, label: "Title" },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "dateTime", numeric: true, disablePadding: false, label: "Date/Time" }
  ],
  recording: [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
    },
    { id: "status", numeric: true, disablePadding: false, label: "Status" },
    { id: "dateTime", numeric: true, disablePadding: false, label: "Date/Time" }
  ],
  conversation: [
    { id: "from", numeric: false, disablePadding: false, label: "From" },
    { id: "unreadMessages", numeric: true, disablePadding: false, label: "Unread messages" },
    { id: "dateTime", numeric: true, disablePadding: false, label: "Last message on" },
  ]
};


const NotificationsTable = (props) => {
  const { 
    title, 
    menu, 
    menuItems, 
    tab, 
    history, 
    notifications,
    session,
    resetSession,
    setCalls,
    setVoicemails,
    setSmsConversations,
    setFaxes,
    setRecordings,
    setNotifications,
    setNotificationsLoading,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getFromValue = (item) =>
    !item.hasOwnProperty("name") || (item.hasOwnProperty("name") && item.name==="")? (
      item.number
    ) : (
      <Tooltip title={item.number}>
        <p>{item.name}</p>
      </Tooltip>
    );

  const getConversationName = (item) => {
    return (item.name && item.name !== "")
      ? item.name
      : (item.number_pretty && item.number_pretty !== "")
        ? item.number_pretty
        : item.number;
  }

  const generateRows = ()=>{
    switch(tab){
      case "call":
        return notifications.call.map((item) => ({
          from: getFromValue(item),
          status: item.state,
          dateTime: moment(item.dateTime).local().format("LLL"),
        }));
      case "voicemail":
        return notifications.voicemail.map((item) => {
          return {
            from: getFromValue(item),
            status: item.type,
            dateTime: moment(item.dateTime).local().format("LLL"),
          };
        });
      case "fax":
        return notifications.fax.map((item) => {
          return {
            from: item.from,
            title: item.title,
            status: item.type,
            dateTime: moment(item.date).local().format("LLL"),
          };
        });
      case "recording":
        return notifications.recording.map((item) => {
          return {
            title: item.title,
            status: item.type,
            dateTime: moment(item.date).local().format("LLL"),
          };
        });
      case "conversation":
        return notifications.conversation.map((item) => {
          return {
            from: getConversationName(item),
            unreadMessages: item.unreadMessages.toString(),
            dateTime: moment(item.lastMessage.dateTime).local().format("LLL"),
          };
        });
      default:
        break;
    }
  }

  const parseNewItems = (data = []) => {
    return data.filter((item) => item.viewed === "no");
  };

  const parseUnreadMessages = (data = []) => {
    return data.filter((item) => item.unreadMessages !== 0);
  }

  const refetchNotifications = () => {
    const values = {
      server: session.lastServer,
      token: session.token,
    };

    let fetchCalls = getCallJournal(values, setCalls);
    let fetchFaxes = getFaxes(values, setFaxes);
    let fetchRecordings = getRecordings(values, setRecordings);
    let fetchVoicemails = getVoicemails(values, setVoicemails);
    let fetchSmsConversations = getSmsConversations(values, setSmsConversations);

    setNotificationsLoading(true);

    Promise.all([fetchCalls, fetchFaxes, fetchRecordings, fetchVoicemails, fetchSmsConversations])
      .then((res) => {
        for (var i = 0; i < res.length; i++) {
          if (res[i] instanceof Error && res[i].message === "Unauthorized") {
            resetSession();
            history.push('/login');
            return;
          }
        }

        setNotifications({
          call: parseNewItems(res[0].journalEntries),
          fax: parseNewItems(res[1].faxDocuments),
          recording: parseNewItems(res[2].callRecordings),
          voicemail: parseNewItems(res[3].voicemailRecordings),
          conversation: parseUnreadMessages(res[4].Conversations),
        });
        setNotificationsLoading(false);
      })
      .catch((err) => {
        setNotificationsLoading(false);
        console.error(err);
      });
  };

  const clearCurrentNotifications = () =>{
    let values = {
      server: session.lastServer,
      token: session.token,
    };
    if (notifications[tab].length > 0) {
      switch (tab) {
        case "call":
          values.call = notifications.call.map((item) => {
            return {
              jid: item.jid,
              viewed: "yes",
              type: item.type,
              direction: item.direction
            };
          });
          console.log(values.call)
          try {
            updateCallJournal(values);
            refetchNotifications();
          } catch (error) {
            console.error(error);
          }

          break;
        case "voicemail":
          values.voicemail = notifications.voicemail.map((item) => {
            return {
              vid: item.vid,
              viewed: "yes",
            };
          });

          try {
            updateVoicemailViewed(values);
            refetchNotifications();
          } catch (error) {
            console.error(error);
          }

          break;
        case "recording":
          values.recording = notifications.recording.map((item) => {
            return {
              rid: item.rid,
              viewed: "yes",
              type: "new",
              title: item.title,
            };
          });
          try {
            updateRecordingsViewed(values);
            refetchNotifications();
          } catch (error) {
            console.error(error);
          }
          break;
        case "fax":
          values.fax = notifications.fax.map((item) => {
            return {
              fid: item.fid,
              viewed: "yes",
              type: "new",
              group: item.group,
              title: item.title,
            };
          });
          try {
            updateFaxes(values);
            refetchNotifications();
          } catch (error) {
            console.error(error);
          }
          break;
        case "conversation":
          values.conversation = notifications.conversation.map((item) => {
            return {
              conversation_id: item.conversation_id,
            };
          });
          try {
            updateConversationsViewed(values);
            refetchNotifications();
          } catch (error) {
            console.error(error);
          }
          break;
        default:
          break;
      }
    }
  }

  const handleClickRow = (row) => {
    let item = notifications.conversation.find(item => row.from === getConversationName(item));
    history.push(`/dashboard/sms/sms/all/${item.conversation_id}`);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <EnhancedTable
          headerData={headCells}
          rowData={generateRows()}
          handleRowClick={(tab === "conversation") ? handleClickRow : null }
          selectable={false}
          menu={menu ? menuItems : false}
          title={title}
          tab={tab}
          history={history}
          hasClear={true}
          clearFunction={clearCurrentNotifications}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          useGroupFilter={false}
        />
      </Grid>
    </Grid>
  );
};

NotificationsTable.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      linkId: PropTypes.string,
    })
  ),
  tab: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    notifications: state.notifications,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTable);