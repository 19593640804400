import React,{useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PhoneIcon from "@material-ui/icons/Phone";
import SendIcon from "@material-ui/icons/Send";
import EmailIcon from "@material-ui/icons/Email";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ImageIcon from "@material-ui/icons/Image";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import PlayArrow from "@material-ui/icons/PlayArrow";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from "@material-ui/icons/Edit";
import { find } from "lodash";
import { downloadFax, downloadVoicemail, downloadRecording } from "../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: theme.spacing(0.5),

    "& button": {
      width: "36px",
      height: "36px",
    },
    "& .MuiSpeedDialIcon-root": {
      height: "26px"
    }
  },
  iconBar: {
    margin: 0,
    listStyleType: "none",
  },
  li: {
    display: "inline-block",
    paddingLeft: theme.spacing(1.5)
  }
}));

const actionTypes = [
  { priority: 8, icon: <PhoneIcon />, name: "Call", type: "call" },
  { priority: 8, icon: <PhoneIcon />, name: "Call", type: "dialConference" },
  { priority: 1, icon: <DeleteForeverIcon />, name: "Delete", type: "delete" },
  { priority: 1, icon: <DeleteForeverIcon />, name: "Delete", type: "deleteCall" },
  { priority: 1, icon: <DeleteForeverIcon />, name: "Delete", type: "deleteVM" },
  { priority: 1, icon: <DeleteForeverIcon />, name: "Delete", type: "deleteConference" },
  { priority: 4, icon: <SendIcon />, name: "Forward Internally", type: "forward" },
  { priority: 3, icon: <EmailIcon />, name: "Email", type: "email" },
  { priority: 3, icon: <EmailIcon />, name: "Email", type: "shareConference" },
  { priority: 7, icon: <PictureAsPdfIcon />, name: "View PDF", type: "viewPdf" },
  { priority: 6, icon: <ImageIcon />, name: "View TIF", type: "viewTif" },
  { priority: 9, icon: <PlayArrow />, name: "Play", type: "play" },
  { priority: 5, icon: <SaveAltIcon />, name: "Download", type: "download" },
  { priority: 2, icon: <EditIcon />, name: "Edit", type: "editTitle" },
  { priority: 2, icon: <AccountCircleIcon />, name: "Details", type: "contactInfo" },
  { priority: 4, icon: <PhoneIcon />, name: "Call", type: "contactCall" },
  { priority: 3, icon: <EmailIcon />, name: "Email", type: "contactEmail" },
  { priority: 2, icon: <AccountCircleIcon />, name: "Edit", type: "contactEdit" },
  { priority: 1, icon: <DeleteForeverIcon />, name: "Delete", type: "contactDelete" },
];

const ActionsSpeedDial = (props)=>{
    const {
      session,
      actions,
      tab,
      rowItem,
      history,
      handleDialClick,
      setValue,
      setCalling,
      phonebook,
      setOpenContactInfo,
      setEditContactInfo,
      setContact,
      setForwardItem,
      setForward,
      handleDelete,
      setPlayTargetObject,
      setDeleteForever,
      setDeleteTarget,
      setPlay,
      setPlayTarget,
      setEditTitle,
      setTitleChangeItem,
      setAttachment,
      setRecipient,
      setSendEmail,
    } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const toggleState = (state) => {
      setOpen(state);
    };
    const defaultValues = {
      server: session.lastServer,
      token: session.token,
    };

    // Create actionItems array
    let actionItems = actions.map((action) => {
      return actionTypes.find(item=>item.type===action.type);
    });
    // Sort based on priority
    actionItems.sort((a, b)=>{
      return a.priority - b.priority;
    })

    const handleActionClick = async (action)=>{
      let values, audioURL;
      switch (action.type) {
        case "call":
          if (!rowItem.object.number)
            break;
          setValue(getPhonebookEntry(rowItem.object.number));
          setCalling(true);
          history.push("/dashboard/call/phone/all")
          break;
        case "dialConference":
          setValue("810");
          handleDialClick();
          break;
        case "editTitle":
          setTitleChangeItem(rowItem.object);
          setEditTitle(true);
          break;
        case "delete":
          if (tab !== "deleted") {
            handleDelete([rowItem.object]);
          } else {
            setDeleteTarget(rowItem.object);
            setDeleteForever(true);
          }
          break;
        case "deleteCall":
          setDeleteTarget(rowItem.object);
          setDeleteForever(true);
          break;
        case "deleteVM":
          setDeleteTarget(rowItem.object);
          setDeleteForever(true);
          break;
        case "deleteConference":
          setDeleteTarget(rowItem.object);
          setDeleteForever(true);
          break;
        case "forward":
          setForwardItem(rowItem.object);
          setForward(true);
          break;
        case "email":
          setAttachment(rowItem.object)
          setSendEmail(true)
          break;
        case "shareConference":
          const { trunk, userPin, ownerName } = rowItem.object;
          let subject = encodeURI("You've been invited to a conference!");
          let body = encodeURI(`Hi,

          You have been invited by ${ownerName} to participate in a conference call.

          To connect, dial

          Internally: 810
          Externally: ${trunk !== "" ? trunk : "<No Number Provided>"}

          Then enter your PIN:  ${userPin}

          Sincerely,`);

          window.location.href = `mailto:?&subject=${subject}&body=${body}`;
          break;
        case "viewPdf":
          values = {
            ...defaultValues,
            fid: rowItem.object.fid,
            group: rowItem.object.group,
            type: "pdf",
          };
          downloadFax(values);
          values = {};
          break;
        case "viewTif":
          values = {
            ...defaultValues,
            fid: rowItem.object.fid,
            group: rowItem.object.group,
            type: "tif",
          };
          downloadFax(values);
          values = {};
          break;
        case "play":
          values = defaultValues;
          if (rowItem.object.hasOwnProperty("vid")) {
            values.vid = rowItem.object.vid;
            audioURL = await downloadVoicemail(values);
          } else if (rowItem.object.hasOwnProperty("rid")) {
            values.rid = rowItem.object.rid;
            audioURL = await downloadRecording(values);
          }

          setPlayTarget(audioURL);
          setPlayTargetObject(rowItem.object);
          setPlay(true);
          values = {};
          break;
        case "download":
          values = defaultValues;
          if (rowItem.object.hasOwnProperty("vid")) {
            values.vid = rowItem.object.vid;
            audioURL = await downloadVoicemail(values);
          } else if (rowItem.object.hasOwnProperty("rid")) {
            values.rid = rowItem.object.rid;
            audioURL = await downloadRecording(values);
          }
          window.open(audioURL);
          values = {};
          break;
        case "contactEmail":
          if (rowItem.email !== "") {
            setRecipient(rowItem.email)
            setSendEmail(true)
          }
          break;
        case "contactCall":
          if (rowItem.object.type === "internal") {
            setCalling(true);
            setValue(rowItem.object.voicemail.mailbox);
            history.push("/dashboard/call/phone/all")
          }
          break;
        case "contactInfo":
          setContact(rowItem.object)
          setOpenContactInfo(rowItem.object.type)
          break;
        case "contactEdit":
          setContact(rowItem.object)
          setEditContactInfo(true)
          setOpenContactInfo(rowItem.object.type)
          break;
        case "contactDelete":
          setDeleteTarget(rowItem.object);
          setDeleteForever(true);
          break;
        default:
          break;
      }
      
    };

    const getPhonebookEntry = (number) => {
      console.log('number',number)
      
      let result;
      phonebook.forEach((item,index) => {
        if (find(item.preferredDeviceNumbers, (current) => current === number) || item.voicemail.mailbox === number) 
          result = {...item,callNumber:number};
      });
      if(typeof result ==="undefined") result=number;
      return result;
    }

    const mapActionItems = ()=>{
        return actionItems.map((actionItem,index)=>{           
            return (
              <li key={index} className={classes.li}>
                <Tooltip title={actionItem.name}>
                  <IconButton
                    aria-label={actionItem.name}
                    className={classes.icon}
                    size="small"
                    onClick={(e) => handleActionClick(actionItem)}
                  >
                    {actionItem.icon}
                  </IconButton>
                </Tooltip>
              </li>
            );
        })
    };

    const speedDial = () => {
      return (
        <SpeedDial
          ariaLabel="SpeedDial example"
          className={classes.speedDial}
          icon={<SpeedDialIcon />}
          onClose={(e) => toggleState(false)}
          onOpen={(e) => toggleState(true)}
          open={open}
          direction="left"
        >
          {actionItems.map((actionItem) => {
            return (
              <SpeedDialAction
                key={actionItem.name}
                icon={actionItem.icon}
                tooltipTitle={actionItem.name}
                tooltipPlacement="top"
                onClick={(e) => handleActionClick(actionItem)}
                className={classes.speedDialAction}
              />
            );
          })}
        </SpeedDial>
      );
    };

    return (
      <div className={classes.root}>
        {
            actions.length>1
            ? speedDial()
            : <ul className={classes.iconBar}>{mapActionItems()}</ul>
        }        
      </div>
    );
}

ActionsSpeedDial.propTypes = {
  actions: PropTypes.array.isRequired,
  rowItem: PropTypes.object.isRequired,
  handleDialClick: PropTypes.func,
  setValue: PropTypes.func,
  setCalling: PropTypes.func,
  setOpenContactInfo: PropTypes.func,
  setEditContactInfo: PropTypes.func,
  setContact: PropTypes.func,
  setForwardItem: PropTypes.func,
  setForward: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDeleteForever: PropTypes.func,
  setPlayTarget: PropTypes.func,
  setPlayTargetObject: PropTypes.func,
  setPlay: PropTypes.func,
  tab: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    phonebook: state.phonebook,
    faxes: state.faxes
  };
};

export default connect(mapStateToProps,null)(ActionsSpeedDial);