import { createSlice } from "@reduxjs/toolkit";

const TimeFrameSlice = createSlice({
  name: "timeFrame",
  initialState: [],
  reducers: {
    setTimeFrame(state, action) {
      let timeFrame = action.payload.timeFrame;
      return timeFrame;
    },
  },
});

export const { setTimeFrame } = TimeFrameSlice.actions;
export default TimeFrameSlice.reducer;
