export class WebRtcClass_JsSIP {
    // status
    // =======
    // init = 0
    // newcall = 1
    // ringing = 2
    // connected = 3
    // incoming = 4
    // onhold = 5

    constructor() {
        this.JsSIP = window.JsSIP
        this.socket = null
        this.sipStack = null

        this.notifyOnRegister = null
        this.notifyCB = null

        this.audio_remote = window.audio_remote
        this.ringtone = window.ringtone
        this.ringbacktone = window.ringbacktone

        this.callSessions = []
        this.statuses = []
        this.remoteNumbers = []
        this.toTransfer = []
        this.inConference = false

        // this.dtmfSender = null
        this.recordings = []

        this.incomingCallOptions = {
            pcConfig: {
                rtcpMuxPolicy: 'negotiate',
                iceServers:
                    [
                        { urls: ['stun:stun.l.google.com:19302'] }
                    ],
            },
        }

        this.outgoingCallOptions = {
            pcConfig: {
                rtcpMuxPolicy: 'negotiate',
                iceServers:
                    [
                        { urls: ['stun:stun.l.google.com:19302'] }
                    ],
            },
            mediaConstraints:
            {
                audio: true,
                video: false,
            },
            rtcOfferConstraints:
            {
                offerToReceiveAudio: 1,
                offerToReceiveVideo: 0,
            }
        }
    }

    setNotifyCB(cb) {
        this.notifyCB = cb;
    }

    setNotifyOnRegister(cb) {
        this.notifyOnRegister = cb;
    }

    stopSipStack() {
        if (!this.sipStack)
            return

        console.log('WebRtcClass_JsSIP:: sipStack.stop()')
        this.stopRingbackTone()
        this.stopRingTone()
        this.sipStack.terminateSessions()
        this.sipStack.stop()
        this.sipStack = null
        this.callSession = []
        this.statuses = []
        this.remoteNumbers = []
        this.toTransfer = []
        this.recordings = []
        this.inConference = false
        // this.dtmfSender = null
    }

    call(number = '115', errorCb) {
        if (!this.sipStack || this.callSessions.length > 1 || !number)
            return

        console.log('WebRtcClassJsSIP:: call ' + number)
        var newSession = this.sipStack.call(number, this.outgoingCallOptions)

        newSession.connection.addEventListener('addstream', (event) => {
            console.log("WebRtcClassJsSIP::addstream (outgoing)")
            if (this.inConference) {
                this.mixConferenceAudio()
            } else {
                this.mixMyAudio()
            }
        })

        newSession.connection.addEventListener('removestream', (event) => {
            console.log("WebRtcClassJsSIP::removestream (outgoing)")
        })

        newSession.connection.addEventListener('onaddtrack', (event) => {
            console.log("WebRtcClassJsSIP::onaddtrack (outgoing)")
            this.audio_remote.srcObject = event.stream
            this.audio_remote.play()
        })

        this.statuses.push(1)
        this.remoteNumbers.push(number)
        this.toTransfer.push(false)
        this.recordings.push(false)
        this.setOtherCallsOnHold(number)

        if (this.notifyCB) {
            this.notifyCB({
                eventType: 'none',
                data: null,
            })
        }
    }

    sendMessage(number, message) {
        if (!this.sipStack || this.callSessions.length > 1 || !number)
            return

        var eventHandlers = {
            'succeeded': (data) => {
                console.log("Message succeeded")
            },
            'failed': (data) => {
                console.log("Message failed")
            },
        }

        var options = {
            'eventHandlers': eventHandlers
        };

        console.log('WebRtcClassJsSIP:: sendMessage ' + message)
        this.sipStack.sendMessage(number, message, options)
    }

    // Mutes or unmutes all sessions
    // returns true if it mutes and false if it unmutes
    toggleMuteAllSessions() {
        if (!this.callSessions.length)
            return false

        const x = this.callSessions[0].isMuted();
        this.callSessions.forEach((callSession) => {
            if (x.audio === true)
                callSession.unmute()
            else
                callSession.mute()
        })

        if (this.notifyCB) {
            this.notifyCB({
                eventType: 'none',
                data: null,
            })
        }

        return !x.audio;
    }

    // Mute or unmute a session
    // returns true if it mutes and false if it unmutes
    toggleMuteSession(number) {
        if (!this.callSessions.length)
            return false

        if (this.inConference)
            return this.toggleMuteAllSessions()

        const index = this.remoteNumbers.indexOf(number)
        let callSession = this.callSessions[index]
        const x = callSession.isMuted();

        if (x.audio === true)
            callSession.unmute()
        else
            callSession.mute()

        if (this.notifyCB) {
            this.notifyCB({
                eventType: 'none',
                data: null,
            })
        }

        return !x.audio;
    }

    // Unmutes all sessions
    unMuteAllSessions() {
        if (!this.callSessions.length)
            return false

        this.callSessions.forEach((callSession) => {
            const x = callSession.isMuted()
            if (x.audio === true)
                callSession.unmute()
        })

        if (this.notifyCB) {
            this.notifyCB({
                eventType: 'none',
                data: null,
            })
        }
    }

    isMuted(number) {
        if (!this.callSessions.length)
            return false

        if (this.inConference)
            return this.callSessions[0].isMuted()

        const index = this.remoteNumbers.indexOf(number)
        let callSession = this.callSessions[index]

        return callSession ? callSession.isMuted() : false;
    }

    isRecording(number) {
        if (!this.callSessions.length)
            return false

        if (this.inConference)
            return this.recordings[0]

        const index = this.remoteNumbers.indexOf(number)
        let recording = this.recordings[index]

        return recording ? recording : false;
    }

    // resumes all sessions
    resumeAllSessions() {
        if (!this.callSessions.length)
            return false

        this.callSessions.forEach((callSession, index) => {
            let x = callSession.isOnHold()
            if (x.local === true) {
                callSession.unhold()
                this.statuses[index] = 3;
            }
        })

        if (this.notifyCB) {
            this.notifyCB({
                eventType: 'none',
                data: null,
            })
        }
    }

    // holds or resumes all sessions
    // returns true when setting calls is on hold and false when it resumes the calls
    toggleHoldAllSessions() {
        if (!this.callSessions.length)
            return false

        let x = this.callSessions[0].isOnHold()
        this.callSessions.forEach((callSession, index) => {
            if (x.local === true) {
                callSession.unhold()
                this.statuses[index] = 3;
            } else {
                callSession.hold()
                this.statuses[index] = 5;
            }
        })

        if (this.notifyCB) {
            this.notifyCB({
                eventType: 'none',
                data: null,
            })
        }

        return !x.local;
    }

    // holds or resumes the call
    // returns true when setting call is on hold and false when it resumes the call
    toggleHoldSession(number) {
        if (!this.callSessions.length)
            return false

        if (this.inConference)
            return this.toggleHoldAllSessions()

        const index = this.remoteNumbers.indexOf(number)
        let callSession = this.callSessions[index]
        let x = callSession.isOnHold()
        if (x.local === true) {
            if (this.callSessions.length > 1) {
                const toHoldIndex = this.statuses.indexOf(3)
                if (toHoldIndex !== -1) {
                    this.callSessions[toHoldIndex].hold()
                    this.statuses[toHoldIndex] = 5;
                }
            }

            callSession.unhold()
            this.statuses[index] = 3;
        } else {
            callSession.hold()
            this.statuses[index] = 5;
        }

        if (this.notifyCB) {
            this.notifyCB({
                eventType: 'none',
                data: null,
            })
        }

        return !x.local;
    }

    hangupAllSessions() {
        console.log("WebRtcClassJsSIP::hangupAllSessions")
        if (!this.callSessions.length || !this.sipStack)
            return

        var iter = this.callSessions.length - 1
        while (iter >= 0) {
            let callSession = this.callSessions[iter]
            try {
                callSession.terminate();
            } catch (e) {
                console.log("Exception: " + e)
            } finally {
                this.callSessions.splice(iter, 1)
                iter -= 1;
            }
        }

        this.stopRingbackTone()
        this.stopRingTone()
        this.remoteNumbers = []
        this.toTransfer = []
        this.recordings = []
        this.statuses = []
        this.inConference = false
        // this.dtmfSender = null
        if (this.notifyCB) {
            this.notifyCB({
                eventType: 'none',
                data: null,
            })
        }
    }

    isPartOfConference(number) {
        console.log("WebRtcClassJsSIP::isPartOfConference: " + number)
        const index = this.remoteNumbers.indexOf(number)
        if (!this.callSessions.length || !this.sipStack || index < 0 || !this.inConference)
            return false

        const callStatus = this.statuses[index]
        // If we calling hangup in a conference terminate all sessions
        if (callStatus >= 3) {
            return true
        }
        return false
    }

    hangupSession(number, originator) {
        console.log("WebRtcClassJsSIP::hangupSession: " + number)
        const index = this.remoteNumbers.indexOf(number)

        if (!this.callSessions.length || !this.sipStack || index < 0)
            return

        const callStatus = this.statuses[index]
        console.log("WebRtcClassJsSIP::hangupSession - callStatus" + callStatus +" - index: " + index)
        // If we calling hangup in a conference terminate all sessions
        if (callStatus >= 3 && originator !== 'remote' && this.inConference) {
            this.hangupAllSessions()
            return
        }

        let callSession = this.callSessions[index]
        try {
            callSession.terminate();
        } catch (e) {
            console.log("Exception: " + e)
        } finally {
            this.callSessions.splice(index, 1)
            this.remoteNumbers.splice(index, 1)
            this.toTransfer.splice(index, 1)
            this.statuses.splice(index, 1)
            this.recordings.splice(index, 1)
        }

        this.inConference = false
        this.stopRingbackTone()
        this.stopRingTone()
        // this.dtmfSender = null

        if (this.notifyCB) {
            this.notifyCB({
                eventType: 'none',
                data: null,
            })
        }
    }

    answerCall(number) {
        console.log("WebRtcClassJsSIP::answerCall: " + number)
        if (!this.callSessions.length || !this.sipStack)
            return

        const index = this.remoteNumbers.indexOf(number)
        let callSession = this.callSessions[index]

        if (this.statuses[index] === 3)
            return

        try {
            callSession.answer(this.incomingCallOptions);

            callSession.connection.addEventListener('addstream', (event) => {
                console.log("WebRtcClassJsSIP::addstream (incoming)")
                if (this.inConference) {
                    this.mixConferenceAudio()
                } else {
                    this.mixMyAudio()
                }
            })

            callSession.connection.addEventListener('removestream', (event) => {
                console.log("WebRtcClassJsSIP::removestream (incoming)")
            })

            callSession.connection.addEventListener('onaddtrack', (event) => {
                console.log("WebRtcClassJsSIP::onaddtrack (incoming)")
                this.audio_remote.srcObject = event.stream
                this.audio_remote.play()
            })

            this.stopRingbackTone()
            this.stopRingTone()
            this.statuses[index] = 3
        } catch (e) {
            console.log("Exception: " + e)
        }
    }

    recordSession(number) {
        if (!this.callSessions.length /*|| !this.dtmfSender*/)
            return false

        const index = this.remoteNumbers.indexOf(number)
        let callSession = this.callSessions[index]
        callSession.sendDTMF('#1', {
            'duration': 100,
            'interToneGap': 70,
        })
        // this.dtmfSender.insertDTMF('#1', 100, 70);
        this.recordings[index] = !this.recordings[index]

        if (this.notifyCB) {
            this.notifyCB({
                eventType: 'none',
                data: null,
            })
        }

        return this.recordings[index]
    }

    transferCall(number, destination) {
        if (!this.callSessions.length /*|| !this.dtmfSender*/)
            return false

        const index = this.remoteNumbers.indexOf(number)
        let callSession = this.callSessions[index]
        console.log("WebRtcClassJsSIP::transferCall "+number+" to "+destination)
        console.log("WebRtcClassJsSIP::transferCall dtmf '##"+destination+"'")
        callSession.sendDTMF('##'+destination, {
            'duration': 100,
            'interToneGap': 70,
        })

        if (this.notifyCB) {
            this.notifyCB({
                eventType: 'none',
                data: null,
            })
        }

        return
    }

    sendDTMF(number, input) {
        if (!this.callSessions.length /*|| !this.dtmfSender*/)
            return false

        console.log("WebRtcClassJsSIP::sendDTMF " + input + " to " + number)
        const index = this.remoteNumbers.indexOf(number)
        let callSession = this.callSessions[index]
        callSession.sendDTMF(input, {
            'duration': 100,
            'interToneGap': 70,
        })
        // this.dtmfSender.insertDTMF(input, 100, 70);
    }

    conference(number = '108', errorCb) {
        if (!this.sipStack || this.callSessions.length !== 1 || !number)
            return

        console.log('WebRtcClassJsSIP:: conference')
        var newSession = this.sipStack.call(number, this.outgoingCallOptions)

        newSession.connection.addEventListener('addstream', (event) => {
            console.log(event)
            // this.audio_remote.srcObject = event.stream
            // this.audio_remote.play()
            this.mixConferenceAudio()
        })

        newSession.connection.addEventListener('onaddtrack', (event) => {
            console.log(event)
            this.audio_remote.srcObject = event.stream
            this.audio_remote.play()
        })

        this.statuses.push(1);
        this.remoteNumbers.push(number);
        this.recordings.push(false)
        this.inConference = true
    }

    mergeSessions() {
        if (!this.sipStack || this.callSessions.length <= 1)
            return

        console.log('WebRtcClassJsSIP:: mergeSessions')

        this.unMuteAllSessions()
        this.resumeAllSessions()
        this.mixConferenceAudio()
        this.inConference = true
    }

    startSipStack(privateId = '492215671109'
        , publicId = 'sip:492215671109@dev4.my-cloudline.net:55060'
        , contact_uri = 'xxxxx'
        , display_name = 'Anthousis Andreadis'
        , registrar_server = 'xxxxx'
        , wss = 'wss://dev4.my-cloudline.net:55091/ws'
        , passwd) {
        if (/*this.SIPml.isInitialized() ||*/ this.sipStack) {
            return
        }

        this.createSipStack(privateId, publicId, contact_uri, display_name, registrar_server, wss, passwd); // see next section
        console.log('WebRtcClass_JsSIP:: startSipStack');
        this.sipStack.start();
    }

    mixConferenceAudio() {
        console.log('WebRtcClass_JsSIP:: mixConferenceAudio')

        if (!this.sipStack || this.callSessions.length <= 1)
            return

        //take all received tracks from the sessions you want to merge
        let receivedTracks = [];
        this.callSessions.forEach((callSession) => {
            if (callSession !== null && callSession !== undefined) {
                callSession.connection.getReceivers().forEach((receiver) => {
                    receivedTracks.push(receiver.track)
                })
            }
        })

        //use the Web Audio API to mix the received tracks
        let context = new AudioContext();
        let allReceivedMediaStreams = new MediaStream();

        this.callSessions.forEach((callSession) => {
            if (callSession === null || callSession === undefined) {
                return
            }

            let mixedOutput = context.createMediaStreamDestination();
            callSession.connection.getReceivers().forEach((receiver) => {
                receivedTracks.forEach((track) => {
                    allReceivedMediaStreams.addTrack(receiver.track);
                    if (receiver.track.id !== track.id) {
                        let sourceStream = context.createMediaStreamSource(new MediaStream([track]));
                        sourceStream.connect(mixedOutput)
                    }
                })
            })
            //mixing your voice with all the received audio
            callSession.connection.getSenders().forEach((sender) => {
                navigator.getUserMedia = navigator.getUserMedia
                    || navigator.webkitGetUserMedia
                    || navigator.mozGetUserMedia
                navigator.getUserMedia({ video: false, audio: true }, (micStream) => {
                    let sourceStream = context.createMediaStreamSource(micStream)
                    sourceStream.connect(mixedOutput)
                }, console.log)
            })
            callSession.connection.getSenders()[0].replaceTrack(mixedOutput.stream.getTracks()[0])
        })

        //play all received stream to you
        this.audio_remote.srcObject = allReceivedMediaStreams;
        var promiseRemote = this.audio_remote.play();
        if (promiseRemote !== undefined) {
            promiseRemote.then(_ => {
                console.log("playing all received streams to you");
            }).catch(error => {
                console.log(error)
            })
        }
    }

    mixMyAudio() {
        console.log('WebRtcClass_JsSIP:: mixMyAudio');
        //take all received tracks from the sessions you want to merge
        let receivedTracks = [];
        this.callSessions.forEach((callSession) => {
            if (callSession && callSession.connection) {
                callSession.connection.getReceivers().forEach((receiver) => {
                    receivedTracks.push(receiver.track);
                })
            }
        })

        //use the Web Audio API to mix the received tracks
        let context = new AudioContext()
        let allReceivedMediaStreams = new MediaStream()

        this.callSessions.forEach((callSession) => {
            if (!callSession || !callSession.connection) {
                return
            }

            let mixedOutput = context.createMediaStreamDestination();
            callSession.connection.getReceivers().forEach((receiver) => {
                receivedTracks.forEach((track) => {
                    allReceivedMediaStreams.addTrack(receiver.track);
                    if (receiver.track.id !== track.id) {
                        let sourceStream = context.createMediaStreamSource(new MediaStream([track]));
                        sourceStream.connect(mixedOutput)
                    }
                })
            })
        })

        //play all received stream to you
        this.audio_remote.srcObject = allReceivedMediaStreams;
        var promiseRemote = this.audio_remote.play();
        if (promiseRemote !== undefined) {
            promiseRemote.then(_ => {
                console.log("playing all received streams to you");
            }).catch(error => {
                console.log(error)
            });
        }
    }

    noActiveCalls() {
        return !this.statuses.includes(3)
    }

    setOtherCallsOnHold(number) {
        if (number) {
            console.log('WebRtcClass_JsSIP:: setOtherCallsOnHold ' + number);
            this.callSessions.forEach((callSession, index) => {
                if (this.remoteNumbers[index] !== number && this.statuses[index] === 3) {
                    callSession.hold()
                    this.statuses[index] = 5
                }
            })
        }
    }

    createSipStack(privateId, publicId, contact_uri, display_name, registrar_server, wss, passwd) {
        console.log('WebRtcClass_JsSIP:: createSipStack');

        let socket = new this.JsSIP.WebSocketInterface(wss);

        const configuration = {
            uri: publicId,
            password: passwd,
            'display_name': display_name,
            sockets: [socket],
            registrar_server: registrar_server,
            contact_uri: contact_uri,
            authorization_user: privateId,
            instance_id: null,
            session_timers: true,
            use_preloaded_route: false,
            mediaConstraints: { 'audio': true, 'video': false },
            register_expires: 600,
            no_answer_timeout: 60,
            connection_recovery_max_interval: 60,
            connection_recovery_min_interval: 2,
            pcConfig: {
                rtcpMuxPolicy: 'negotiate',
                iceServers:
                    [
                        { urls: ['stun:stun.l.google.com:19302'] }
                    ]
            },
            callstats:
            {
                enabled: false,
                AppID: null,
                AppSecret: null
            }
        }

        this.sipStack = new this.JsSIP.UA(configuration)
        this.sipStack.on('connected', (data) => {
            console.log("WebRtcClass_JsSIP::connected")
        });
        this.sipStack.on('disconnected', (data) => {
            console.log("WebRtcClass_JsSIP::disconnected")
        });
        this.sipStack.on('registered', (data) => {
            console.log("WebRtcClass_JsSIP::registered")
            if (this.notifyOnRegister)
                this.notifyOnRegister()
        });
        this.sipStack.on('unregistered', (data) => {
            console.log("WebRtcClass_JsSIP::unregistered")
            if (this.notifyOnRegister)
                this.notifyOnRegister()
        });
        this.sipStack.on('registrationFailed', (data) => {
            console.log("WebRtcClass_JsSIP::registrationFailed")
        });
        this.sipStack.on('registrationExpiring', (data) => {
            console.log("WebRtcClass_JsSIP::registrationExpiring --> sending register message")
            this.sipStack.register()
        });
        this.sipStack.on('newRTCSession', (data) => {
            console.log("WebRtcClass_JsSIP::newRTCSession")
            let cS = data.session

            // Avoid if busy
            if (data.originator === "remote") {
                if (this.callSessions.length >= 2) {
                    console.log('incoming call replied with 486 "Busy Here"')
                    cS.terminate({
                        'status_code': 486,
                        'reason_phrase': 'Busy Here'
                    })
                    return
                }

                if (!this.statuses.find(status => status === 3))
                    this.startRingTone()
                this.statuses.push(4)
                this.recordings.push(false)

                const sRemoteNumber = (cS.remote_identity.uri.user || 'unknown')
                console.log('WebRtcClass_JsSIP:: incoming call from ' + sRemoteNumber)
                this.remoteNumbers.push(sRemoteNumber);
                if (this.notifyCB) {
                    this.notifyCB({
                        eventType: "new_call",
                        data: sRemoteNumber,
                    })
                }
            }
            this.callSessions.push(cS)

            cS.on('progress', (data) => {
                console.log("WebRtcClass_JsSIP::progress")
                var idx = this.callSessions.indexOf(cS)
                if (cS.direction === 'outgoing') {
                    if (this.noActiveCalls()) {
                        this.startRingbackTone()
                    }
                    this.statuses[idx] = 2

                    if (this.notifyCB) {
                        this.notifyCB({
                            eventType: 'none',
                            data: null,
                        })
                    }
                }
            })
            cS.on('failed', (data) => {
                console.log("WebRtcClass_JsSIP::failed " + data.message)
                var idx = this.callSessions.indexOf(cS)
                // this.stopRingbackTone();
                // this.stopRingTone();
                // this.statuses[idx] = 0;
                if (this.notifyCB) {
                    this.notifyCB({
                        eventType: 'terminated',
                        data: {
                            originator: data.originator,
                            number: this.remoteNumbers[idx],
                        }
                    })
                }
                this.hangupSession(cS.remote_identity.uri.user, data.originator)
            })
            cS.on('confirmed', (data) => {
                console.log("WebRtcClass_JsSIP::confirmed")
                var idx = this.callSessions.indexOf(cS)
                this.stopRingbackTone()
                this.stopRingTone()
                this.setOtherCallsOnHold(data.ack?.from._uri._user)
                this.statuses[idx] = 3

                if (this.notifyCB) {
                    this.notifyCB({
                        eventType: 'connected',
                        data: null,
                    })
                }
            })
            cS.on('ended', (data) => {
                console.log('WebRtcClass_JsSIP::ended ')
                if (this.notifyCB) {
                    this.notifyCB({
                        eventType: 'terminated',
                        data: {
                            originator: data.originator,
                            number: data.message?.from._uri._user,
                        }
                    })
                }

                if (this.notifyCB) {
                    this.notifyCB({
                        eventType: 'none',
                        data: null,
                    })
                }
            })
            cS.on('peerconnection', (data) => {
                console.log("WebRtcClass_JsSIP::peerconnection")
            })
            cS.on('connecting', (data) => {
                console.log("WebRtcClass_JsSIP::connecting")
            })
            cS.on('sending', (data) => {
                console.log("WebRtcClass_JsSIP::sending")
            })
            cS.on('accepted', (data) => {
                console.log("WebRtcClass_JsSIP::accepted")
            })
            cS.on('newDTMF', (data) => {
                console.log("WebRtcClass_JsSIP::newDTMF")
            })
            cS.on('newInfo', (data) => {
                console.log("WebRtcClass_JsSIP::newInfo")
            })
            cS.on('hold', (data) => {
                console.log("WebRtcClass_JsSIP::hold")
            })
            cS.on('unhold', (data) => {
                console.log("WebRtcClass_JsSIP::unhold")
            })
            cS.on('muted', (data) => {
                console.log("WebRtcClass_JsSIP::muted")
            })
            cS.on('unmuted', (data) => {
                console.log("WebRtcClass_JsSIP::unmuted")
            })
            cS.on('reinvite', (data) => {
                console.log("WebRtcClass_JsSIP::reinvite")
            })
            cS.on('update', (data) => {
                console.log("WebRtcClass_JsSIP::update")
            })
            cS.on('refer', (data) => {
                console.log("WebRtcClass_JsSIP::refer")
            })
            cS.on('replaces', (data) => {
                console.log("WebRtcClass_JsSIP::replaces")
            })
            cS.on('sdp', (data) => {
                console.log("WebRtcClass_JsSIP::sdp")
            })
            cS.on('icecandidate', (candidate, ready) => {
                console.log("WebRtcClass_JsSIP::icecandidate")
                if (this.notifyCB) {
                    this.notifyCB({
                        eventType: 'none',
                        data: null,
                    })
                }
            })
            cS.on('getusermediafailed', (data) => {
                console.log("WebRtcClass_JsSIP::getusermediafailed")
            })
            cS.on('peerconnection:createofferfailed', (data) => {
                console.log("WebRtcClass_JsSIP::peerconnection:createofferfailed")
            })
            cS.on('peerconnection:createanswerfailed', (data) => {
                console.log("WebRtcClass_JsSIP::peerconnection:createanswerfailed")
            })
            cS.on('peerconnection:setlocaldescriptionfailed', (data) => {
                console.log("WebRtcClass_JsSIP::peerconnection:setlocaldescriptionfailed")
            })
            cS.on('peerconnection:setremotedescriptionfailed', (data) => {
                console.log("WebRtcClass_JsSIP::peerconnection:setremotedescriptionfailed")
            })
        });
        this.sipStack.on('newMessage', (data) => {
            console.log("WebRtcClass_JsSIP::newMessage")
        });
        this.sipStack.on('newOptions', (data) => {
            console.log("WebRtcClass_JsSIP::newOptions")
        });
        this.sipStack.on('sipEvent', (data) => {
            console.log("WebRtcClass_JsSIP::sipEvent")
        });
    }

    startRingTone() {
        try { this.ringtone.play(); }
        catch (e) { }
    }

    stopRingTone() {
        try { this.ringtone.pause(); }
        catch (e) { }
    }

    startRingbackTone() {
        try { this.ringbacktone.play(); }
        catch (e) { }
    }

    stopRingbackTone() {
        try { this.ringbacktone.pause(); }
        catch (e) { }
    }

    getSessionStatus(number) {
        const index = this.remoteNumbers.indexOf(number)
        if (index !== -1)
            return this.statuses[index]
        return 0;
    }

    getRemoteNumbers() {
        return this.remoteNumbers
    }

    getNumberOfSessions() {
        return this.callSessions.length
    }

    getInConference() {
        return this.inConference
    }

    getSipRegistered() {
        if (this.sipStack)
            return this.sipStack.isRegistered();

        return null
    }
}

export default WebRtcClass_JsSIP;
