var common = {
  black: "#000",
  white: "#fff",
};
var grey = {
  50: "#fafafa",
  100: "#f5f5f5",
  200: "#eeeeee",
  300: "#e0e0e0",
  400: "#bdbdbd",
  500: "#9e9e9e",
  600: "#757575",
  700: "#616161",
  800: "#424242",
  900: "#212121",
  A100: "#d5d5d5",
  A200: "#aaaaaa",
  A400: "#303030",
  A700: "#616161",
};

export const themeObjectDark = {
  palette: {
    type: "dark",
    text: {
      primary: common.white,
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
      hint: "rgba(255, 255, 255, 0.5)",
      icon: "rgba(255, 255, 255, 0.5)",
      softer: "rgba(255,255,255,.85)",
      selected: "#000",
    },
    divider: "rgba(255, 255, 255, 0.12)",
    background: {
      paper: grey[800],
      default: "#303030",
      light: grey[700],
      lighter: grey[600],
    },
    action: {
      active: common.white,
      hover: "rgba(255, 255, 255, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(255, 255, 255, 0.16)",
      selectedOpacity: 0.16,
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(255, 255, 255, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    primary: {
      light: "#7986cb",
      main: "#3f51b5",
      dark: "#303f9f",
      hr: "#A5A7BC",
      success: "#00C853",
      lighter: "#e3e7fc",
      darkerGreen: "114B5F",
      contrastText: "#222",
    },
  },
};

export const themeObjectLight = {
  palette: {
    type: "light",
    primary: {
      lighter: "#ecf8ff",
      light: "#01BAEF",
      main: "#2282AB",
      dark: "#1b6888",
      darkerGreen: "114B5F",
      contrastText: "#fff",
      hr: "#cfd8dc",
      success: "#00C853",
    },
    secondary: {
      light: "#faa222",
      main: "#E91e63",
      dark: "#ff3d00",
      contrastText: "#fff",
    },
    text: {
      primary: "#000",
      secondary: "rgba(0,0,0,.54)",
      disabled: "#cbcbcb",
      hint: "#ff3d00",
      softer: "#222",
      selected: "#FFF",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
      light: grey[300],
      lighter: grey[200],
    },
    action: {
      disabled: "#cbcbcb",
    },
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  }
};