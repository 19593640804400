import { createSlice } from "@reduxjs/toolkit";

const FaxSlice = createSlice({
	name: "faxes",
	initialState: [],
	reducers: {
		setFaxes(state, action) {
			let faxes = action.payload.faxDocuments;
			
			return faxes;
		},
	}
});

export const { setFaxes } = FaxSlice.actions;
export default FaxSlice.reducer;
