import React,{useState} from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import DialerSipIcon from '@material-ui/icons/DialerSip';
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import AutoCompleteSelect from "./autocomplete";
// import DialerStatus from "./dialerStatus";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  dialpad: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),

    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  grid: {
    textAlign: "center",
  },
  row: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0.5),
    },

    "& button": {
      justifyContent: "start",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      width: "100%",
    },
  },
  gridNum: {
    fontSize: "1.6rem",
    color: theme.palette.text.softer,
  },
  gridLetter: {
    fontSize: ".75rem",
    color: theme.palette.text.secondary,
  },
  callButton: {
    width: "3rem",
    height: "3rem",
    borderRadius: "50%",
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.success,
    boxShadow:
      "0px 8.07595px 8.07595px rgba(0, 0, 0, 0.24), 0px 0px 8.07595px rgba(0, 0, 0, 0.12)",
  },
  webRtcCallButton: {
    width: "3rem",
    height: "3rem",
    borderRadius: "50%",
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.success,
    boxShadow:
      "0px 8.07595px 8.07595px rgba(0, 0, 0, 0.24), 0px 0px 8.07595px rgba(0, 0, 0, 0.12)",
  },
  selectDevice: {
    fontSize: "2rem",
  },
  selectDeviceText: {
    fontSize: ".75rem",
    textTransform: "uppercase",
  },
  autoComplete: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
  },
}));

const Dialer = (props) => {
    const {
      selectedIndex,
      setSelectedIndex,
      deviceSelection,
      setDeviceSelection,
      phonebook,
      voip,
      value,
      setValue,
      handleDialClick,
      options,
      dialerInputCb,
    } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [viewValue, setViewValue] = useState("");
    
    const rows = [
      [
        { number: 1, letters: " " },
        { number: 2, letters: "ABC" },
        { number: 3, letters: "DEF" },
      ],
      [
        { number: 4, letters: "GHI" },
        { number: 5, letters: "JKL" },
        { number: 6, letters: "MNO" },
      ],
      [
        { number: 7, letters: "PQRS" },
        { number: 8, letters: "TUV" },
        { number: 9, letters: "WXYZ" },
      ],
      [
        { number: "*", letters: " " },
        { number: 0, letters: "+" },
        { number: "#", letters: " " },
      ],
    ];

    const buildCol = (col) => (
      <Grid item xs={4}>
        <Button disableElevation onClick={(e) => {
            if (dialerInputCb)
              dialerInputCb(col.number)
              
            if (typeof viewValue === 'string') {
              setViewValue(viewValue+String(col.number))
            } else {
              setViewValue(String(col.number))
            }
          }}>
          <Grid container className={clsx(classes.grid, classes.dialerButton)}>
            <Grid item xs={12} className={classes.gridNum}>
              {col.number}
            </Grid>
            <Grid item xs={12} className={classes.gridLetter}>
              {col.letters}
            </Grid>
          </Grid>
        </Button>
      </Grid>
    );

    const buildRows = rows.map((row,index)=>(
      <Grid container className={clsx(classes.grid, classes.row)} key={index}>
        {buildCol(row[0])}
        {buildCol(row[1])}
        {buildCol(row[2])}
      </Grid>
    ));

    const handleClickListItem = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
      setDeviceSelection(options[index]);
      setSelectedIndex(index);
      setAnchorEl(null);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const selectOptions = phonebook.map((option) => {
      const firstLetter = option.firstName[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option
      };
    });

    return (
      <div className={classes.root}>
        {/* {value !== null && calling ? (
          <DialerStatus
            open={calling}
            setOpen={setCalling}
            mode={mode}
            value={value}
            controller={controller}
          />
        ) : null} */}
        <div className={classes.autoComplete}>
          <AutoCompleteSelect
            value={value}
            viewValue={viewValue}
            setValue={setValue}
            setViewValue={setViewValue}
            options={selectOptions}
            phonebook={phonebook}
            onTextFieldKeyPress={(e) => {
              if (value && e.key === 'Enter') {
                handleDialClick()
              }
            }}
            placeholder="Phone"
          />
        </div>
        <div className={classes.dialpad}>{buildRows}</div>

        <Grid container className={classes.grid}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
             <IconButton
                aria-label="dial"
                className={ voip.active === 'yes' ? classes.webRtcCallButton : classes.callButton}
                onClick={handleDialClick}
              >
                { voip.active === 'yes' ? <DialerSipIcon /> : <PhoneIcon /> }
              </IconButton>
          </Grid>
          { voip.active !== 'yes' ?
            <Grid item xs={4}>
              <Tooltip title="Selected Device">
                <IconButton
                  aria-label="selected device"
                  className={classes.selectDevice}
                  onClick={handleClickListItem}
                >
                  <ArrowDropDownCircleOutlinedIcon />
                </IconButton>
              </Tooltip>
              <span className={classes.selectDeviceText}>
                {options[selectedIndex]}
              </span>
              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onChange={setDeviceSelection}
              >
                {options.map((option, index) => (
                  <MenuItem
                    key={index}
                    selected={option === deviceSelection}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Grid> :
            <Grid item xs={4} />
          }
        </Grid>
      </div>
    );
}

const mapStateToProps = (state) => {
  return {
    phonebook: state.phonebook,
    session: state.session,
    voip: state.user.voip,
  }
}
export default connect(mapStateToProps, null)(Dialer);