import React from "react";
import PropTypes from "prop-types";
import {
  InputAdornment,
} from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

export default function PasswordVisibility(props) {
    const {showPassword,togglePasswordVisibility,passRef} = props;

    const toggleVis = () => {
        let el = passRef.current;
      if (el.type === "password") {
        togglePasswordVisibility(true);
        el.type = "text";
      } else {
        togglePasswordVisibility(false);
        el.type = "password";
      }
    };

    if (showPassword) {
        return (
          <InputAdornment position="end">
            <VisibilityIcon color="disabled" onClick={(e) => toggleVis()} />
          </InputAdornment>
        );
    } else {
        return (
          <InputAdornment position="end">
            <VisibilityOffIcon color="disabled" onClick={(e) => toggleVis()} />
          </InputAdornment>
        );
    }
}

PasswordVisibility.propTypes = {
  showPassword: PropTypes.bool.isRequired,
  togglePasswordVisibility: PropTypes.func.isRequired,
  passRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};