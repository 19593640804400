import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    smsContactsList: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'stretch',
        backgroundColor: theme.palette.background.paper,
    },
    smsContactsSearch: {
        padding: "10px 10px 0px 0px",
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        top : 0,
        borderStyle: 'hidden hidden solid hidden',
        borderWidth: 'thin',
        borderBottomColor: theme.palette.action.focus,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1,
    },
    smsContactsSearchInput: {
        padding: '12px 12px',
        border: 'none',

        '&::placeholder': {
            textAlign: 'center',
        },
        '&:focus::placeholder': {
            textAlign: 'left',
        }
    },
    smsContactsButton: {
        margin: '0px',
        padding: '10px 0px 10px 0px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        boxSizing: 'border-box',
        position: 'fixed',
        bottom: 0,
        borderStyle: 'solid hidden hidden hidden',
        borderWidth: 'thin',
        borderTopColor: theme.palette.action.focus,
        backgroundColor: theme.palette.background.paper,
        width: '299px'
    },
    newSmsButton: {
        width: "3rem",
        height: "3rem",
        borderRadius: "50%",
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.success,
        boxShadow: '0px 8.07595px 8.07595px rgba(0, 0, 0, 0.24), 0px 0px 8.07595px rgba(0, 0, 0, 0.12)',
    },
}));

export { useStyles }