import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setError } from "../store/features/error/errorSlice";
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Select,
  Button,
  MenuItem,
  Typography
} from "@material-ui/core";
import { setUser } from "../store/features/user/userSlice";
import { updateUserWebappSettings, getUserProfileData } from "../utils/utils";
import HelpMenu from "../components/helpMenu";

const mapDispatchToProps = {
  setError,
  setUser,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "inherit",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
  gridContainer: {
    height: "inherit",
    padding: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(6),
    },
    fontSize: 60,
    fontWeight: 300,
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "inherit",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 8),
    },
  },  
  updateButton: {
    margin: "auto",
    maxWidth: "410px",
  },
  formControl: {
    margin: theme.spacing(3, 0),
  },
  helper: {
    padding: theme.spacing(3, 0),
  },
  version: {
    textAlign: "right"
  }
}));


const AppSettingsView = (props) => {
  const classes = useStyles();
  const { settings, branding, session, setError, setUser } = props;
  const [language,setLanguage] = useState(settings.language);
  const [notificationSettings, setNotificationSettings] = useState(settings.notifications);
  const [formChanged, setFormChanged] = useState(false);
  const { call, fax, voicemail, recording, conversation } = notificationSettings;

  // eslint-disable-next-line
  useEffect(() => {
    console.log("compare notificationSettings : " + notificationSettings !== settings.notifications)
    console.log("notificationSettings : " + JSON.stringify(notificationSettings))
    console.log("settings.notifications : " + JSON.stringify(settings.notifications))
    if (
      language !== settings.language ||
      notificationSettings.call !== settings.notifications.call ||
      notificationSettings.fax !== settings.notifications.fax ||
      notificationSettings.voicemail !== settings.notifications.voicemail ||
      notificationSettings.recording !== settings.notifications.recording ||
      notificationSettings.conversation !== settings.notifications.conversation 
    ) {
      setFormChanged(true)
    }
    else {
      setFormChanged(false);
    }
  })

  const handleChange = (event) => {
    setNotificationSettings({
      ...notificationSettings,
      [event.target.name]: event.target.checked?"yes":"no",
    });
  };

  const handleSelectChange = (event) => {
    setLanguage(event.target.value);
  }
   
  const submitSettings = async () => {
    let values = {
      token: session.token,
      server: session.lastServer,
      settings: {
        language: language,
        notifications: notificationSettings,
      },
    };
    try {
      await updateUserWebappSettings(values);

      try {
        await getUserProfileData(
          {
            token: session.token,
            server: session.lastServer,
          },setUser
        );
      } catch (error) {
        setError({
          visible: true,
          type: "error",
          message: error,
        });
      }
    } catch (error) {
      setError({
        visible: true,
        type: "error",
        message: error,
      });
    }
  }

  const renderFormControlCheckBox = (param, paramOnChange, name, label) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={param === "yes" ? true : false}
            onChange={paramOnChange}
            name={name}
          />
        }
        label={label}
      />
    )
  }

  return (
    <div className={classes.root}>
      <HelpMenu />
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.gridContainer}
      >
        <Grid item className={classes.gridItem}>
          <Typography
            color="inherit"
            variant="h4"
            component="div"
            className={classes.title}
          >
            Settings
          </Typography>
          <Paper className={classes.paper}>
            <FormControl component="fieldset" className={classes.formControl}>
              <Typography variant="subtitle1">Notifications</Typography>
              <FormGroup>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      {renderFormControlCheckBox(call, handleChange, "call", "Missed Calls")}
                      {renderFormControlCheckBox(voicemail, handleChange, "voicemail", "New Voicemails")}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      {renderFormControlCheckBox(fax, handleChange, "fax", "New Faxes")}
                      {renderFormControlCheckBox(recording, handleChange, "recording", "New Call Recordings")}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      {renderFormControlCheckBox(conversation, handleChange, "conversation", "New SMS Messages")}
                    </FormGroup>
                  </Grid>
                </Grid>
                <FormHelperText className={classes.helper}>
                  Select the Notifications you would like to receive alerts for
                  in the notifications menu.
                </FormHelperText>
              </FormGroup>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <Typography variant="subtitle1">Language Selection</Typography>
              <FormGroup>
                <Select
                  value={language}
                  name="language"
                  fullWidth
                  onChange={(e) => handleSelectChange(e)}
                >
                  <MenuItem value="english">English</MenuItem>
                  <MenuItem value="german" disabled>
                    German
                  </MenuItem>
                  <MenuItem value="greek" disabled>
                    Greek
                  </MenuItem>
                  <MenuItem value="spanish" disabled>
                    Spanish
                  </MenuItem>
                </Select>
              </FormGroup>
            </FormControl>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={!formChanged}
                  onClick={submitSettings}
                  className={classes.updateButton}
                >
                  Update
                </Button>
              </Grid>
              <Grid item xs={12} md={6} className={classes.version}>
                <Typography variant="body1">
                  Version {branding.version}
                </Typography>
                <Typography variant="body1">
                  Latest Patch: {branding.lastUpdate}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    settings: state.user.webapp,
    session: state.session,
    branding: state.branding
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppSettingsView)
);
