import { createSlice } from "@reduxjs/toolkit";

const BrandingSlice = createSlice({
  name: "branding",
  initialState: {
    default: "no",
    companyName: "My-Cloudline",
    version: "",
    lastUpdate: "",
    logoDark: "",
    logoLight: "",
    colors: {
      lightMode: {
        light: "#01BAEF",
        main: "#2282AB",
        dark: "#1b6888",
      },
      darkMode: {
        light: "#01BAEF",
        main: "#121212",
        dark: "#616161",
      },
    },
  },
  reducers: {
    setBranding(state, action) {
      const {
        companyName,
        colors,
        lastUpdate,
        logoDark,
        logoLight,
        version,
      } = action.payload;
      const branding = {
        default: action.payload.default,
        companyName: companyName,
        version: version,
        lastUpdate: lastUpdate,
        logoDark: logoDark,
        logoLight: logoLight,
        colors: colors,
      };

      return (state = branding);
    },
  },
});

export const { setBranding } = BrandingSlice.actions;
export default BrandingSlice.reducer;
