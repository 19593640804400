import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    message: {
        display: 'flex',
        flexDirection: 'column',
    },
    timestamp: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.text.disabled,
        fontWeight: 600,
        fontSize: 14,
        margin: '10px 0px',
        textTransform: 'uppercase',
    },
    bubbleContainer: {
        display: 'flex',
    },
    bubbleContainerMine: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    notDeliveredIcon: {
        alignSelf: 'center',
        height: 18,
        color: theme.palette.secondary.main,
    },
    messageMineNotDelivered: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: theme.palette.secondary.main,
        fontSize: 12,
        fontWeight: 500,
        padding: '0px 12px 12px 12px',
    },
    startBubble: {
        /* margin-top: 10px; */
        borderTopRightRadius: '20px !important'  ,
    },
    endBubble: {
        borderBottomLeftRadius: '20px !important',
        /* margin-bottom: 10px; */
    },
    startBubbleMine: {
        marginTop: 10,
        borderTopRightRadius: '20px !important',
    },
    endBubbleMine: {
        borderBottomRightRadius: '20px !important',
    },
    bubble: {
        margin: '1px 0px',
        background: theme.palette.primary.light,
        padding: '10px 15px',
        borderRadius: 20,
        maxWidth: '75%',
        wordBreak: 'break-word',
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
    },
    bubbleMMS: {
        width: '256px',
        heigh: "auto",
    },
    bubbleTime: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        paddingTop: 2,
    },
    bubbleMine: {
        margin: '1px 0px',
        background: theme.palette.background.lighter,
        padding: '10px 15px',
        borderRadius: 20,
        maxWidth: '75%',
        wordBreak: 'break-word',
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
    },
    newLinesText: {
        whiteSpace: 'pre-wrap',
    },
}));

export { useStyles }