import React,{useState,useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { 
  MenuItem,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import EnhancedTable from "./enhancedTable";
import moment from "moment";
import {
  getCallJournal,
  getFaxes,
  getRecordings,
  getVoicemails,
  getSmsConversations,
  updateFaxes,
  deleteFax,
  forwardFaxes
} from "../utils/utils";
import {
  setNotifications,
  setNotificationsLoading,
} from "../store/features/notification/notificationSlice";
import { setCalls } from "../store/features/calls/callSlice";
import { setVoicemails } from "../store/features/voicemails/voicemailSlice";
import { setSmsConversations } from "../store/features/smsConversations/smsConversationsSlice";
import { setFaxes } from "../store/features/faxes/faxSlice";
import { setRecordings } from "../store/features/recordings/recordingSlice";
import { setError } from "../store/features/error/errorSlice";
import Forward from "./forward";
import ForwardViaEmail from "./forwardViaEmail";
import EditTitle from "./editTitle";
import { resetSession } from "../store/features/session/sessionSlice";

const mapDispatchToProps = {
  setError,
  setCalls,
  setVoicemails,
  setSmsConversations,
  setFaxes,
  setRecordings,
  setNotifications,
  setNotificationsLoading,
  resetSession,
};

const headCells = (groups) => {
  if (groups) {
    return {
      all: [
        { id: "groupName", numeric: false, disablePadding: false, label: "Group Name", },
        { id: "from", numeric: true, disablePadding: false, label: "From", },
        { id: "title", numeric: true, disablePadding: false, label: "Title" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
      new: [
        { id: "groupName", numeric: false, disablePadding: false, label: "Group Name", },
        { id: "from", numeric: true, disablePadding: false, label: "From", },
        { id: "title", numeric: true, disablePadding: false, label: "Title" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
      viewed: [
        { id: "groupName", numeric: false, disablePadding: false, label: "Group Name", },
        { id: "from", numeric: true, disablePadding: false, label: "From", },
        { id: "title", numeric: true, disablePadding: false, label: "Title" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
      urgent: [
        { id: "groupName", numeric: false, disablePadding: false, label: "Group Name", },
        { id: "from", numeric: true, disablePadding: false, label: "From", },
        { id: "title", numeric: true, disablePadding: false, label: "Title" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
      deleted: [
        { id: "groupName", numeric: false, disablePadding: false, label: "Group Name", },
        { id: "from", numeric: true, disablePadding: false, label: "From", },
        { id: "title", numeric: true, disablePadding: false, label: "Title" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
    };
  } else {
    return {
      all: [
        { id: "from", numeric: false, disablePadding: false, label: "From", },
        { id: "title", numeric: true, disablePadding: false, label: "Title" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
      new: [
        { id: "from", numeric: false, disablePadding: false, label: "From", },
        { id: "title", numeric: true, disablePadding: false, label: "Title" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
      viewed: [
        { id: "from", numeric: false, disablePadding: false, label: "From", },
        { id: "title", numeric: true, disablePadding: false, label: "Title" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
      urgent: [
        { id: "from", numeric: false, disablePadding: false, label: "From", },
        { id: "title", numeric: true, disablePadding: false, label: "Title" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
      deleted: [
        { id: "from", numeric: false, disablePadding: false, label: "From", },
        { id: "title", numeric: true, disablePadding: false, label: "Title" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
    };
  }
}

const Faxes = (props) => {
  const {
    title,
    menu,
    menuItems,
    history,
    faxes,
    session,
    resetSession,
    setVoicemails,
    setSmsConversations,
    setFaxes,
    setRecordings,
    setNotifications,
    setNotificationsLoading,
    // handleDialClick,
    notifications,
    setValue,
    match,
    groups,
    setError
  } = props;

  let faxTab = match.params.faxTab;
  const [page,setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [forward, setForward] = useState(false);
  const [forwardItem, setForwardItem] = useState("")
  const [email, setEmail] = useState(false);
  const [attachment, setAttachment] = useState(null)
  const [titleChangeItem, setTitleChangeItem] = useState(null);
  const [titleModal, setTitleModal] = useState(false)
  const [faxFilterGroup, setFaxFilterGroup] = useState("");
  const defaultValues = {
    server: session.lastServer,
    token: session.token,
  };

  const createFaxGroupOptions = () => {
    // Use groupName as name if exists else groupNumber
    const faxGroups = [{key: "", value: "All"}];
    const map = new Map();
    for (const item of faxes) {
        if (item.group.toLowerCase() !== "yes")
          continue;
          
        if(!map.has(item.groupNumber)){
            map.set(item.groupNumber, true);    // set any value to Map
            faxGroups.push({
                key: item.groupNumber,
                value: item.groupName !== "" ? item.groupName : item.groupNumber
            });
        }
    }
    return faxGroups.map((item) => (
      <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
    ));
  }

  const faxesFiltered = faxes.filter((item) => {
    if (!groups) {
      return item.group.toLowerCase() === "no";
    }

    if (faxFilterGroup.toLowerCase() === "")
      return item.group.toLowerCase() === "yes";

    return item.groupNumber === faxFilterGroup && item.group.toLowerCase() === "yes";
  });

  const renderGroupNameCell = (item) =>
    (item.hasOwnProperty("groupNumber") && item.groupName === "") ? (
      <span>item.groupNumber</span>
    ) : (
        <Tooltip title={item.groupNumber} placement="right">
          <span>{item.groupName}</span>
        </Tooltip>
      );

  const faxRowItem = (item) => {
    let obj = {
      from: item.from,
      title: item.title,
      status: item.type,
      received: moment(item.dateTime).format("LLL"),
      object: item,
    };

    if (item.type === "deleted") {
      obj.actions = [{ type: "delete" }];
    } else {
      obj.actions = [
        { type: "delete" },
        { type: "viewTif" },
        { type: "viewPdf" },
        { type: "email" },
        { type: "forward" },
        { type: "editTitle" },
      ];
    }

    if (groups) {
      obj = { groupName: renderGroupNameCell(item), ...obj };
    }

    return obj; 
  }

  const generateRows = () => {
    switch (faxTab) {
      case "new":
        return faxesFiltered
          .filter((item) => item.type.toLowerCase() === "new")
          .map((item) => {
            return faxRowItem(item);
          });
      case "viewed":
        return faxesFiltered
          .filter((item) => item.type.toLowerCase() === "old")
          .map((item) => {
            return faxRowItem(item);
          });
      case "urgent":
        return faxesFiltered
          .filter((item) => item.type.toLowerCase() === "urgent")
          .map((item) => {
            return faxRowItem(item);
          });
      case "deleted":
        return faxesFiltered
          .filter((item) => item.type.toLowerCase() === "deleted")
          .map((item) => {
            return faxRowItem(item);
          });
      case "all":
        return faxesFiltered
          .filter((item) => item.type !== "deleted")
          .map((item) => {
            return faxRowItem(item);
          });
      default:
        break;
    }
  }

  const parseNewItems = (data = []) => {
    return data.filter((item) => item.viewed === "no");
  };

  const parseUnreadMessages = (data = []) => {
    return data.filter((item) => item.unreadMessages !== 0);
  }

  const refetchNotifications = () => {
    const values = defaultValues;

    let fetchCalls = getCallJournal(values, setCalls),
        fetchFaxes = getFaxes(values, setFaxes),
        fetchRecordings = getRecordings(values, setRecordings),
        fetchVoicemails = getVoicemails(values, setVoicemails),
        fetchSmsConversations = getSmsConversations(values, setSmsConversations);

    setNotificationsLoading(true);

    Promise.all([
      fetchCalls,
      fetchFaxes,
      fetchRecordings,
      fetchVoicemails,
      fetchSmsConversations])
      .then((res) => {
        for (var i = 0; i < res.length; i++) {
          if (res[i] instanceof Error && res[i].message === "Unauthorized") {
            resetSession();
            history.push('/login');
            return;
          }
        }

        setNotifications({
          call: parseNewItems(res[0].journalEntries),
          fax: parseNewItems(res[1].faxDocuments),
          recording: parseNewItems(res[2].callRecordings),
          voicemail: parseNewItems(res[3].voicemailRecordings),
          conversation: parseUnreadMessages(res[4].Conversations),
        });
        setNotificationsLoading(false);
      })
      .catch((err) => {
        setNotificationsLoading(false);
        console.error(err);
      });
  };

  const clearCurrentNotifications = () =>{
    let values = defaultValues;

    values.fax = faxes
      .filter(
        (fax) => 
        (fax.viewed!=="yes" && fax.type.toLowerCase()===faxTab.toLowerCase()) ||
        (fax.viewed!=="yes" && faxTab.toLowerCase()==="viewed" && fax.type.toLowerCase()==="old")
      )
      .map((fax) => {
        return {
          fid: fax.fid,
          viewed: "yes",
          type: fax.type,
          group: fax.group,
          title: fax.title
        };
      })
    try {
      updateFaxes(values);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    clearCurrentNotifications();
    // eslint-disable-next-line
  }, [faxTab, title]);

  const handleStatusChange = async (type,item) => {
    let values = defaultValues;

    values.fax = [
      {
        fid: item.fid,
        title: item.title,
        viewed: type.toLowerCase()==="deleted" ? "yes" : item.viewed,
        group: item.group,
        type: type.toLowerCase()==="viewed" ? "old" : type.toLowerCase()
      }
    ];

    try {
      await updateFaxes(values);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  const handleDelete = (items) => {
    let values = defaultValues;
    console.log(items)
    values.fax = items.map(item=>
      item.hasOwnProperty("object")
        ? {
            fid: item.object.fid,
            title: item.object.title,
            viewed: "yes",
            group: item.object.group,
            type: "deleted",
          }
        : {
            fid: item.fid,
            title: item.title,
            viewed: "yes",
            group: item.group,
            type: "deleted",
          }
    );
    console.log("values", values);
    try {
      updateFaxes(values);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  const handleDeleteForever = (item) => {
    let values = defaultValues;
    values.id = item.fid
    values.group = item.group
    console.log("delete", values);
    try {
      deleteFax(values);
      setError({
        visible: true,
        type: "success",
        message: "Successfully deleted Item",
      });
      refetchNotifications();
    } catch (error) {
      setError({
        visible: true,
        type: "error",
        message: error,
      });
      console.error(error);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <ForwardViaEmail
          open={email}
          setOpen={setEmail}
          file={attachment}
          type="Fax"
          defaultValues={defaultValues}
        />
        <Forward
          open={forward}
          setOpen={setForward}
          value={forwardItem}
          type="Fax"
          forwardFunction={forwardFaxes}
          idKey="fid"
          groupKey="group"
          defaultValues={defaultValues}
        />
        {titleModal && (
          <EditTitle
            open={titleModal}
            setOpen={setTitleModal}
            value={titleChangeItem}
            type="Fax"
            defaultValues={defaultValues}
            setTitleChangeItem={setTitleChangeItem}
            reloadNotifications={refetchNotifications}
          />
        )}
        <EnhancedTable
          setError={setError}
          type="fax"
          headerData={headCells(groups)}
          rowData={generateRows()}
          dense={false}
          hasActions={true}
          selectable={true}
          menu={menu ? menuItems : false}
          title={title}
          tab={faxTab}
          history={history}
          hasClear={false}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          // handleDialClick={handleDialClick}
          setValue={setValue}
          setForwardItem={setForwardItem}
          setForward={setForward}
          loading={notifications.loading}
          statusChangeFunction={handleStatusChange}
          handleDelete={handleDelete}
          handleDeleteForever={handleDeleteForever}
          setAttachment={setAttachment}
          setSendEmail={setEmail}
          setTitleChangeItem={setTitleChangeItem}
          setEditTitle={setTitleModal}
          useGroupFilter={groups}
          createGroupOptions={createFaxGroupOptions}
          filterGroup={faxFilterGroup}
          setFilterGroup={setFaxFilterGroup}
          refreshDataOnSameTab={clearCurrentNotifications}
        />
      </Grid>
    </Grid>
  );
};

Faxes.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      linkId: PropTypes.string,
    })
  ),
  tab: PropTypes.string.isRequired,
  // handleDialClick: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  groups: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    faxes: state.faxes,
    notifications: state.notifications,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Faxes));