import { createSlice } from "@reduxjs/toolkit";

const CallSlice = createSlice({
  name: "calls",
  initialState: [],
  reducers: {
    setCalls(state, action) {
      let { journalEntries } = action.payload;

      return (state = journalEntries);
    },
  },
});

export const { setCalls } = CallSlice.actions;
export default CallSlice.reducer;
