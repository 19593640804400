import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsTable from "../components/notificationsTable";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

const menu = [
  {
    title: "Calls",
    linkId: "call",
  },
  {
    title: "Voicemails",
    linkId: "voicemail",
  },
  {
    title: "Faxes",
    linkId: "fax",
  },
  {
    title: "Call Recordings",
    linkId: "recording",
  },
  {
    title: "Sms Messages",
    linkId: "conversation",
  },
];

const Notifications = (props) => {
    const classes = useStyles();
    const {
      match,
      history,
    } = props;
    const tab = match.params.type;

    return (
      <NotificationsTable
        title="Notifications"
        menuItems={menu}
        menu={true}
        tab={tab}
        history={history}
        className={classes.root}
      />
    );
};


export default withRouter(Notifications);
