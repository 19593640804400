import React, { useState } from "react";
import { makeStyles, darken } from "@material-ui/core/styles";
import {
    Modal,
    Fade,
    Backdrop,
    TextField,
    Tabs,
    Tab,
} from "@material-ui/core";
import TabPanel from "../components/tabPanel";
import { Grid, Paper } from "@material-ui/core";
import { setError } from "../store/features/error/errorSlice";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const mapDispatchToProps = {
    setError,
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& p": {
            fontSize: "1.125rem",
            marginTop: 0,
            fontWeight: 200,
        },
        maxWidth: "100vw",
        maxHeight: "100vh",
    },
    heading: {
        marginTop: 0,
    },
    grid: {
        flexGrow: 1,
        marginBottom: theme.spacing(3),
    },
    primary: {
        background: theme.palette.primary.light,
        color: "white",
        marginLeft: theme.spacing(4),
        "&:hover": {
            background: darken(theme.palette.primary.light, 0.25),
        },
    },
    paper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        borderRadius: "4px",

        [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
            maxHeight: "75vh",
            maxWidth: "80vw",
            overflow: 'auto',
        },
        [theme.breakpoints.up("md")]: {
            minWidth: "500px",
            minHeight: "250px",
            maxHeight: "90vh",
            maxWidth: "60vw",
        },
    },
    close: {
        position: "absolute",
        right: theme.spacing(3),
        top: theme.spacing(3),
        background: "black",
        color: "white",
        "&:hover": {
            background: "black",
        },
        [theme.breakpoints.up("md")]: {
            right: "-.65em",
            top: "-.65em",
        },
    },
}));

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        "aria-controls": `nav-tabpanel-${index}`,
    };
}

const ContactInfoExternal = (props) => {
    const classes = useStyles();
    const {
        open,
        setOpen,
        contact,
    } = props;

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // let contact = props;
    // contact = {
    //     "pid": 2,
    //     "firstName": "Greg",
    //     "lastName": "Dedes",
    //     "trunkAccess": "",
    //     "number": "49221567101",
    //     "speedDial": {
    //         "code": "200",
    //         "active": "no"
    //     }
    // }

    if (!contact || !open)
        return null;

    return (
        <Modal
            aria-labelledby="external-contact-info"
            aria-describedby="External Contact Info"
            className={classes.modal}
            open={open}
            onClose={(e) => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Paper className={classes.paper}>
                    <IconButton
                        aria-label="close"
                        className={classes.close}
                        size="small"
                        onClick={(e) => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Grid item xs={12} >
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            className={classes.tabs}
                        >
                            <Tab label="Details" {...a11yProps(0)} />
                        </Tabs>
                    </Grid>

                    <TabPanel value={tabValue} index={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="First Name"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    disabled={contact.firstName === ""}
                                    value={contact.firstName}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    label="Last Name"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    disabled={contact.lastName === ""}
                                    value={contact.lastName}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    disabled={!contact?.email || contact?.email === ""}
                                    value={contact?.email}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    label="Business Number"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    disabled={contact.numbers.office === ""}
                                    value={contact.numbers.office}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    label="Mobile Number"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    disabled={contact.numbers.mobile === ""}
                                    value={contact.numbers.mobile}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Company"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    disabled={contact.company === ""}
                                    value={contact.company}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    label="Speed Dial Code"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    disabled={contact.speedDial.code === ""}
                                    value={contact.speedDial.code}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    label="Speed Dial Number"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    disabled={contact.speedDial.number === ""}
                                    value={contact.speedDial.number}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    label="Fax Number"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    disabled={contact.numbers.fax === ""}
                                    value={contact.numbers.fax}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    label="SMS Number"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    disabled={contact.numbers.sms === ""}
                                    value={contact.numbers.sms}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Paper>
            </Fade>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoExternal);