import React,{useState,useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Grid, Tooltip
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import EnhancedTable from "./enhancedTable";
import {
  getCallJournal,
  getFaxes,
  getRecordings,
  getVoicemails,
  getSmsConversations,
  deleteConference,
  getConferences,
  updateConference,
} from "../utils/utils";
import {
  setNotifications,
  setNotificationsLoading,
} from "../store/features/notification/notificationSlice";
import { setCalls } from "../store/features/calls/callSlice";
import { setVoicemails } from "../store/features/voicemails/voicemailSlice";
import { setSmsConversations } from "../store/features/smsConversations/smsConversationsSlice";
import { setFaxes } from "../store/features/faxes/faxSlice";
import { setRecordings } from "../store/features/recordings/recordingSlice";
import {
  setConferences,
} from "../store/features/conferences/conferenceSlice";
import { setError } from "../store/features/error/errorSlice";
import CreateConference from "./createConference";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { resetSession } from "../store/features/session/sessionSlice";
import EditTitle from "./editTitle";

const mapDispatchToProps = {
  setError,
  setCalls,
  setVoicemails,
  setSmsConversations,
  setFaxes,
  setRecordings,
  setNotifications,
  setNotificationsLoading,
  setConferences,
  resetSession,
};

const useStyles = makeStyles((theme) => ({
  locked: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  unlocked: {
    color: theme.palette.primary.success,
    cursor: "pointer",
  },
  noPointer: {
    cursor: "auto",
  },
}));

const headCells = {
  personal: [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    { id: "adminPin", numeric: true, disablePadding: false, label: "Admin Pin" },
    { id: "guestPin", numeric: true, disablePadding: false, label: "Guest Pin" },
    { id: "type", numeric: true, disablePadding: false, label: "Type" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ],
  public: [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    { id: "guestPin", numeric: true, disablePadding: false, label: "Guest Pin" },
    { id: "owner", numeric: true, disablePadding: false, label: "Owner" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ],
};


const ConferenceTable = (props) => {
  const {
    tab,
    title,
    menu,
    menuItems,
    history,
    conferences,
    session,
    resetSession,
    setVoicemails,
    setSmsConversations,
    setFaxes,
    setRecordings,
    setNotifications,
    setNotificationsLoading,
    notifications,
    setConferences,
    setValue,
    setError,
    handleDialClick,
  } = props;

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [conferenceModal, setConferenceModal] = useState(false);
  const [titleChangeItem, setTitleChangeItem] = useState(null);
  const [titleModal, setTitleModal] = useState(false)

  const createConferenceCb = () => {
    setConferenceModal(true)
  }

  const defaultValues = {
    server: session.lastServer,
    token: session.token,
  };

  const getLockStatus = (item) =>{
    let icon;

    if(item.owner.toLowerCase() === "yes"){
      if (item.type.toLowerCase() === "private") {
        icon = (
          <Tooltip title="Private Conference">
            <LockIcon
              className={classes.locked}
              onClick={() =>handleStatusChange("public", item.cid)}
            />
          </Tooltip>
        );
      } else {
        icon = (
          <Tooltip title="Public Conference">
            <LockOpenIcon
              className={classes.unlocked}
              onClick={() => handleStatusChange("private", item.cid)}
            />
          </Tooltip>
        );
      }
    }else{
      if(item.type.toLowerCase() === "private") {
        icon = (
          <Tooltip title="Private Conference">
            <LockIcon className={clsx(classes.locked, classes.noPointer)} />
          </Tooltip>
        );
      }else{
        icon = (
          <Tooltip title="Public Conference">
            <LockOpenIcon className={clsx(classes.unlocked, classes.noPointer)} />
          </Tooltip>
        );
      }
    }
    return icon;
  }

  const generateRows = () => {
    switch (tab) {
      case "personal":
        return conferences
          .filter((item) => item.owner.toLowerCase() === "yes")
          .map((item) => {
            return {
              name: item.name,
              adminPin: item.adminPin,
              guestPin: item.userPin,
              type: getLockStatus(item),
              actions: [
                { type: "editTitle" },
                { type: "shareConference" },
                { type: "dialConference" },
                { type: "deleteConference" },
              ],
              object: item,
            };
          });
      case "public":
        return conferences
          .filter((item) => item.owner.toLowerCase() === "no")
          .map((item) => {
            return {
              name: item.name,
              guestPin: item.userPin,
              owner: item.ownerName,
              actions: [{ type: "dialConference" }],
              object: item,
            };
          });
      default:
        break;
    }
  };

  const parseNewItems = (data = []) => {
    return data.filter((item) => item.viewed === "no");
  };

  const parseUnreadMessages = (data = []) => {
    return data.filter((item) => item.unreadMessages !== 0);
  }

  const refetchNotifications = () => {
    const values = defaultValues;

    let fetchCalls = getCallJournal(values, setCalls),
      fetchFaxes = getFaxes(values, setFaxes),
      fetchRecordings = getRecordings(values, setRecordings),
      fetchVoicemails = getVoicemails(values, setVoicemails),
      fetchSmsConversations = getSmsConversations(values, setSmsConversations),
      fetchConferences = getConferences(values, setConferences);
  
    setNotificationsLoading(true);

    Promise.all([
      fetchCalls,
      fetchFaxes,
      fetchRecordings,
      fetchVoicemails,
      fetchSmsConversations,
      fetchConferences,
    ])
      .then((res) => {
        for (var i = 0; i < res.length; i++) {
          if (res[i] instanceof Error && res[i].message === "Unauthorized") {
            resetSession();
            history.push('/login');
            return;
          }
        }

        setNotifications({
          call: parseNewItems(res[0].journalEntries),
          fax: parseNewItems(res[1].faxDocuments),
          recording: parseNewItems(res[2].callRecordings),
          voicemail: parseNewItems(res[3].voicemailRecordings),
          conversation: parseUnreadMessages(res[4].Conversations),
        });
        setNotificationsLoading(false);
      })
      .catch((err) => {
        setNotificationsLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    refetchNotifications();
    // eslint-disable-next-line
  }, [tab]);

  const handleStatusChange = async (type,id) => {
    let conference = conferences.find(item=>item.cid===id);
    console.log('conferences handle status',conferences,conference)
    let values = defaultValues
    values.conference ={
      name: conference.name,
      type: type
    }
    try {
      await updateConference(
        values,
        id
      );
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = (items) => {
    let values = defaultValues;

    values.conferences = items.map((item) =>
      item.hasOwnProperty("object")
        ? {
            cid: item.object.cid,
            title: item.object.title,
            viewed: "yes",
            type: "deleted",
          }
        : {
            cid: item.cid,
            title: item.title,
            viewed: "yes",
            type: "deleted",
          }
    );

    try {
      updateConference(values);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteForever = (item) => {
    let values = defaultValues;
    values.id = item.cid;

    try {
      deleteConference(values);
      setError({
        visible: true,
        type: "success",
        message: "Successfully deleted Item",
      });
      refetchNotifications();
    } catch (error) {
      setError({
        visible: true,
        type: "error",
        message: error,
      });
      console.error(error);
    }
  };

  return (
    <Grid container>
      {conferenceModal && (
        <CreateConference
          open={conferenceModal}
          setOpen={setConferenceModal}
          defaultValues={defaultValues}
          reloadNotifications={refetchNotifications}
        />
      )}

      {titleModal && (
        <EditTitle
          open={titleModal}
          setOpen={setTitleModal}
          value={titleChangeItem}
          type="Conference"
          defaultValues={defaultValues}
          setTitleChangeItem={setTitleChangeItem}
          reloadNotifications={refetchNotifications}
        />
      )}

      <Grid item xs={12}>
        <EnhancedTable
          headerData={headCells}
          rowData={generateRows()}
          hasActions={true}
          selectable={false}
          menu={menu ? menuItems : false}
          title={title}
          tab={tab}
          setError={setError}
          type="conference"
          dense={false}
          history={history}
          hasClear={false}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          setValue={setValue}
          loading={notifications.loading}
          statusChangeFunction={handleStatusChange}
          handleDelete={handleDelete}
          handleDeleteForever={handleDeleteForever}
          setTitleChangeItem={setTitleChangeItem}
          setEditTitle={setTitleModal}
          createItemCallback={createConferenceCb}
          createItemTooltip={"Create Conference"}
          handleDialClick={handleDialClick}
          useGroupFilter={false}
        />
      </Grid>
    </Grid>
  );
};

ConferenceTable.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      linkId: PropTypes.string,
    })
  ),
  tab: PropTypes.string.isRequired,
  handleDialClick: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    conferences: state.conferences,
    notifications: state.notifications,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConferenceTable)
);