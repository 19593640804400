import { createSlice } from "@reduxjs/toolkit";

const IssueFormSlice = createSlice({
  name: "issueForm",
  initialState: {
    enabled: false,
  },
  reducers: {
    setIssueForm(state, action) {
      const {
        enabled,
      } = action.payload;
      const issueForm = {
        enabled: enabled,
      };

      return (state = issueForm);
    },
  },
});

export const { setIssueForm } = IssueFormSlice.actions;
export default IssueFormSlice.reducer;
