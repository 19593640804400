import { createSlice } from "@reduxjs/toolkit";

const ExternalPhonebookSlice = createSlice({
  name: "externalPhonebook",
  initialState: [],
  reducers: {
    setExternalPhonebook(state, action) {
      const { externaldirectory } = action.payload;
      return (state = externaldirectory);
    },
  },
});

export const { setExternalPhonebook, getExternalPhonebookEntry } = ExternalPhonebookSlice.actions;
export default ExternalPhonebookSlice.reducer;
