import React,{useState,useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      width: "615px",
    },
  },

  textField: {
    margin: theme.spacing(2, 0),
  },
  errorText: {
    color: theme.palette.secondary.main,
  },
}));

const EmailAutocomplete = (props) => {
  const classes = useStyles();
    const {
      placeholder,
      setValue,
      value,
      hasError,
      setHasError
    } = props;
    const [inputValue, setInputValue] = useState("");
    
    
    const isEmailValid = (email) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    
    const handleChange = (event, emails) => {
      setValue(emails);
      event.persist();
    };

    const handleInputChange = (event,newInputValue) => {
      const options = newInputValue.split(/[ ,]+/);
      const fieldValue = value
        .concat(options)
        .map((x) => x.trim())
        .filter((x) => x);
      if (options.length > 1) {
        handleChange(event, fieldValue);
      } else {
        setInputValue(newInputValue);
      }
    };

    const handleKeyDown = (event) => {
      switch (event.key) {
        case "Tab": {
          if(inputValue!=="") event.preventDefault();
          if (inputValue.length > 0) {
            handleChange(event, value.concat([inputValue]));
          }
          break;
        }
        default:
        }
    };

    const checkValidity = ()=>{
      let test = value.map(email => {
        if (!isEmailValid(email)) return true;
        return false;
      });
      
      if(test.indexOf(true)!==-1){
        setHasError(true)
      }else setHasError(false);
    }

    useEffect(()=>{
      checkValidity();
    })
    
    return (
      <React.Fragment>
        <Autocomplete
          className={classes.root}
          multiple={true}
          id="email-autocomplete"
          options={[]}
          limitTags={3}
          groupBy={(option) => option.firstLetter}
          value={value}
          freeSolo
          renderTags={(emails, getTagProps) => {
            return emails.map((email, index) => (
              <Chip
                deleteIcon={<CancelIcon onClick={checkValidity} />}
                variant="default"
                label={email}
                color={isEmailValid(email) ? "primary" : "secondary"}
                {...getTagProps({ index })}
              />
            ));
          }}
          inputValue={inputValue}
          onChange={handleChange}
          onInputChange={handleInputChange}
          renderInput={(params) => {
            params.inputProps.onKeyDown = handleKeyDown;
            return (
              <TextField
                { ...params }
                label={placeholder}
                variant="outlined"
                fullWidth
                error={hasError}
                className={classes.textField}
                style={{
                  fontSize: "16px",
                }}
              />
            );
          }}
        />
        <FormHelperText>
          Tip: Don’t forget to press “Enter”, “Tab”, or “,” after each entry to
          store the value.
        </FormHelperText>
        <FormHelperText className={classes.errorText}>
          {hasError ? "Please ensure that you use a valid email format." : ""}
        </FormHelperText>
      </React.Fragment>
    );
}

export default EmailAutocomplete;