import { createSlice } from "@reduxjs/toolkit";

const SmsConversationsSlice = createSlice({
  name: "smsConversations",
  initialState: [],
  reducers: {
    setSmsConversations(state, action) {
      let { Conversations } = action.payload;

      return (state = Conversations);
    },
  },
});

export const { setSmsConversations } = SmsConversationsSlice.actions;
export default SmsConversationsSlice.reducer;
