import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  withRouter,
  useRouteMatch,
} from "react-router-dom";
import { makeStyles, darken } from "@material-ui/core/styles";
import {
  Toolbar,
  Typography,
  Tooltip,
  Button,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Grid,
  Tabs,
  Tab,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import GroupAddIcon from "@material-ui/icons/GroupAdd";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "100%",
    height: "auto",
    minWidth: "360px",
    minHeight: "60px",
    borderRadius: "0 0 10px 10px",
    [theme.breakpoints.up("md")]: {
      minHeight: "90px",
    },
  },
  buttons: {
    textAlign: "center",
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
  colorDefault: {
    backgroundColor: theme.palette.primary.light,
  },
  conferenceButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: darken(theme.palette.primary.light, 0.25),
    },
  },
  title: {
    flex: "auto",
    color: "white",
    fontWeight: 300,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  groupFilterTitle: {
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
    "&.Mui-focused": {
      color: theme.palette.primary.contrastText
    }
  },
  groupFilterItem: {
    color: theme.palette.primary.contrastText,
    fontWeight: 300,
    textAlign: "left",
  },
  titleConference: {
    flex: "1 1 100%",
    fontWeight: 300,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  menu: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  searchBar: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  searchField: {
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  clearButton: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
  },
  delete: {
    "& svg": {
      color: theme.palette.primary.contrastText,
    },
  },
  formControlSelectGroup: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    setSelected,
    numSelected,
    items,
    menu,
    searchFunc,
    title,
    tab,
    history,
    hasClear,
    clearFunction,
    setPage,
    handleDelete,
    createItemCallback,
    createItemTooltip,
    useGroupFilter,
    createGroupOptions,
    setFilterGroup,
    filterGroup,
    refreshDataOnSameTab,
  } = props;
  const [value, setValue] = useState(tab);
  const [searchTerm, setSearchTerm] = useState('');
  let { path } = useRouteMatch();

  useEffect(() => {
    setValue(tab);
  }, [tab])

  const handleChange = (event, newValue) => {
    if (newValue !== "") {
      if (refreshDataOnSameTab && newValue === value) {
        refreshDataOnSameTab()
      }
      setValue(newValue);
      setPage(0);
      history.push(`${path.split(":")[0]}${newValue}`);
    }
  };

  const deleteAction = () => {
    handleDelete(typeof items === "object" && items.length > 0 ? items : [items]);
    setSelected([]);
  }

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    searchFunc(searchTerm)
  };

  return (
    <div>
      <Toolbar
        className={
          clsx(classes.root, classes.colorDefault)
        }
      >
        <Grid container alignItems="center">
          <Grid item xs={12} sm={10}>
            {(
              numSelected > 0 ? (
                <Typography
                  className={classes.title}
                  color="inherit"
                  variant="h6"
                  component="div"
                >
                  {title} ({numSelected} selected)
                </Typography>
              ) : (
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  {title}
                </Typography>
              )
            )}
          </Grid>
          <Grid item xs={12} sm={2} className={classes.buttons}>
            {numSelected > 0 ? (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  className={classes.delete}
                  onClick={deleteAction}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : null}

            {numSelected === 0 && hasClear ? (
              <Tooltip title="Clear Current Notifications">
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<ClearIcon />}
                  disableElevation
                  className={classes.clearButton}
                  onClick={clearFunction}
                >
                  Clear
                </Button>
              </Tooltip>
            ) : null}
            {createItemCallback ? (
              <Tooltip title={createItemTooltip}>
                <Button
                  variant="contained"
                  startIcon={<GroupAddIcon />}
                  className={classes.conferenceButton}
                  onClick={createItemCallback}
                >
                  Create
                </Button>
              </Tooltip>
            ) :
              useGroupFilter ? (
                <FormControl className={classes.formControlSelectGroup}>
                  <InputLabel id="preferred-device-label" className={classes.groupFilterTitle} >
                    Select Group
                  </InputLabel>
                  <Select
                    autoWidth={true}
                    labelId="selectGroup-label"
                    id="selectGroupSelect"
                    value={filterGroup}
                    onChange={(e) => setFilterGroup(e.target.value)}
                    label="Select Group"
                    className={classes.groupFilterItem}
                  >
                    {createGroupOptions()}
                  </Select>
                </FormControl>
              ) : (
                null
              )}
          </Grid>
        </Grid>
      </Toolbar>
      {menu ? (
        <Grid item xs={12} className={classes.menu}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabs}
          >
            {menu.map((item, index) => (
              <Tab
                disabled={item.linkId !== "" ? false : true}
                key={index}
                value={item.linkId}
                label={item.title}
                {...a11yProps(0)}
              />
            ))}
          </Tabs>
        </Grid>
      ) : null}
      {searchFunc ? (<Grid item xs={12} className={classes.searchBar}>
        <TextField
          className={classes.searchField}
          label="Search Field"
          // variant="outlined"
          margin="normal"
          fullWidth
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </Grid>)
        : null}
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  menu: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  title: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  searchFunc: PropTypes.func,
  hasClear: PropTypes.bool.isRequired,
  clearFunction: PropTypes.func,
  createItemCallback: PropTypes.func,
  createItemTooltip: PropTypes.string,
  useGroupFilter: PropTypes.bool.isRequired,
  createGroupOptions: PropTypes.func,
  setFilterGroup: PropTypes.func,
  filterGroup: PropTypes.string,
  refreshDataOnSameTab: PropTypes.func,
};

export default withRouter(EnhancedTableToolbar);