import React,{useState,useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
  Tooltip,
  MenuItem,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import EnhancedTable from "./enhancedTable";
import moment from "moment";
import {
  getCallJournal,
  getFaxes,
  getRecordings,
  getVoicemails,
  getSmsConversations,
  updateVoicemails,
  updateVoicemailStatus,
  deleteVoicemail,
  forwardVoicemail,
} from "../utils/utils";
import {
  setNotifications,
  setNotificationsLoading,
} from "../store/features/notification/notificationSlice";
import { setCalls } from "../store/features/calls/callSlice";
import { setVoicemails } from "../store/features/voicemails/voicemailSlice";
import { setSmsConversations } from "../store/features/smsConversations/smsConversationsSlice"; 
import { setFaxes } from "../store/features/faxes/faxSlice";
import { setRecordings } from "../store/features/recordings/recordingSlice";
import { setError } from "../store/features/error/errorSlice";
import Forward from "./forward";
import ForwardViaEmail from "./forwardViaEmail";
import { resetSession } from "../store/features/session/sessionSlice";

const mapDispatchToProps = {
  setError,
  setCalls,
  setVoicemails,
  setSmsConversations,
  setFaxes,
  setRecordings,
  setNotifications,
  setNotificationsLoading,
  resetSession,
};

const headCells = (groups) => {
  if (groups) {
    return {
      all: [
        { id: "groupName", numeric: false, disablePadding: false, label: "Group Name", },
        { id: "from", numeric: true, disablePadding: false, label: "From", },
        { id: "duration", numeric: true, disablePadding: false, label: "Duration", },
        { id: "status", numeric: true, disablePadding: false, label: "Status", },
        { id: "received", numeric: true, disablePadding: false, label: "Received", },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions", },
      ],
      new: [
        { id: "groupName", numeric: false, disablePadding: false, label: "Group Name", },
        { id: "from", numeric: true, disablePadding: false, label: "From", },
        { id: "duration", numeric: true, disablePadding: false, label: "Duration", },
        { id: "status", numeric: true, disablePadding: false, label: "Status", },
        { id: "received", numeric: true, disablePadding: false, label: "Received", },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions", },
      ],
      viewed: [
        { id: "groupName", numeric: false, disablePadding: false, label: "Group Name", },
        { id: "from", numeric: true, disablePadding: false, label: "From", },
        { id: "duration", numeric: true, disablePadding: false, label: "Duration" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
      urgent: [
        { id: "groupName", numeric: false, disablePadding: false, label: "Group Name", },
        { id: "from", numeric: true, disablePadding: false, label: "From", },
        { id: "duration", numeric: true, disablePadding: false, label: "Duration" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
    };
  }
  else {
    return {
      all: [
        { id: "from", numeric: false, disablePadding: false, label: "From", },
        { id: "duration", numeric: true, disablePadding: false, label: "Duration" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
      new: [
        { id: "from", numeric: false, disablePadding: false, label: "From", },
        { id: "duration", numeric: true, disablePadding: false, label: "Duration" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
      viewed: [
        { id: "from", numeric: false, disablePadding: false, label: "From", },
        { id: "duration", numeric: true, disablePadding: false, label: "Duration" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
      urgent: [
        { id: "from", numeric: false, disablePadding: false, label: "From", },
        { id: "duration", numeric: true, disablePadding: false, label: "Duration" },
        { id: "status", numeric: true, disablePadding: false, label: "Status" },
        { id: "received", numeric: true, disablePadding: false, label: "Received" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ],
    };
  }
}

const Voicemail = (props) => {
  const {
    title,
    menu,
    menuItems,
    history,
    voicemails,
    session,
    resetSession,
    setVoicemails,
    setSmsConversations,
    setFaxes,
    setRecordings,
    setNotifications,
    setNotificationsLoading,
    setCalling,
    notifications,
    setValue,
    match,
    setError,
    groups,
  } = props;

  let voicemailTab = match.params.voicemailTab;
  const [page,setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [forward, setForward] = useState(false);
  const [forwardItem, setForwardItem] = useState(null)
  const [email, setEmail] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [voiceMailFilterGroup, setVoiceMailFilterGroup] = useState("");
  const defaultValues = {
    server: session.lastServer,
    token: session.token,
  };

  const formatTime = (time) => {
    if (time > 0 && time < 60) {
      return moment.unix(time).utc().format("s [sec]");
    } else if (time >= 60 && time < 3600) {
      return moment.unix(time).utc().format("m [min and] s [sec]");
    } else if (time >= 3600) {
      return moment.unix(time).utc().format("H [hrs,] m [min, and] s [sec]");
    } else {
      return "-";
    }
  };

  const createVoiceMailGroupOptions = () => {
    // Use groupName as name if exists else mailbox
    const voiceMailGroups = [{ key: "", value: "All" }];
    const map = new Map();
    for (const item of voicemails) {
      if (item.group.toLowerCase() !== "yes")
        continue;

      if (!map.has(item.mailbox)) {
        map.set(item.mailbox, true);    // set any value to Map
        voiceMailGroups.push({
          key: item.mailbox,
          value: item.groupName !== "" ? item.groupName : item.mailbox
        });
      }
    }
    return voiceMailGroups.map((item) => (
      <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
    ));
  }

  const voicemailsFiltered = voicemails.filter((item) => {
    if (!groups) {
      return item.group.toLowerCase() === "no";
    }

    if (voiceMailFilterGroup.toLowerCase() === "")
      return item.group.toLowerCase() === "yes";

    return item.mailbox === voiceMailFilterGroup && item.group.toLowerCase() === "yes";
  })

  const renderGroupNameCell = (item) =>
    (item.hasOwnProperty("name") && item.groupName === "") ? (
      <span>item.name</span>
    ) : (
        <Tooltip title={item.mailbox} placement="right">
          <span>{item.groupName}</span>
        </Tooltip>
      );

  const renderFromCell = (item) =>
    !item.hasOwnProperty("name") ||
      (item.hasOwnProperty("name") && item.name === "") ? (
        item.number
      ) : (
        <Tooltip title={item.number} placement="right">
          <span>{item.name}</span>
        </Tooltip>
      );

  const createRowData = (item) => {
    let obj = {
      from: renderFromCell(item),
      duration: formatTime(item.duration),
      status: item.type,
      received: moment(item.dateTime).format("LLL"),
      actions: [
        { type: "deleteVM" },
        { type: "email" },
        { type: "forward" },
        { type: "download" },
        { type: "call" },
        { type: "play" },
      ],
      object: item,
    };

    if (groups) {
      obj = { groupName: renderGroupNameCell(item), ...obj };
      obj.from = item.name !== "" ? item.name : item.number;
    }

    return obj; 
  }

  const generateRows = ()=>{
    switch (voicemailTab) {
      case "new":
        return voicemailsFiltered
          .filter((item) => item.type.toLowerCase() === "new")
          .map((item) => {
            return createRowData(item);
          });
      case "viewed":
        return voicemailsFiltered
          .filter((item) => item.type.toLowerCase() === "old")
          .map((item) => {
            return createRowData(item);
          });
      case "urgent":
        return voicemailsFiltered
          .filter((item) => item.type.toLowerCase() === "urgent")
          .map((item) => {
            return createRowData(item);
          });
      case "all":
        return voicemailsFiltered
          .filter((item) => item.type !== "deleted")
          .map((item) => {
            return createRowData(item);
          });
      default:
        break;
    }
  }

  const parseNewItems = (data = []) => {
    return data.filter((item) => item.viewed === "no");
  };

  const parseUnreadMessages = (data = []) => {
    return data.filter((item) => item.unreadMessages !== 0);
  }

  const refetchNotifications = () => {
    const values = defaultValues;

    let fetchCalls = getCallJournal(values, setCalls),
        fetchFaxes = getFaxes(values, setFaxes),
        fetchRecordings = getRecordings(values, setRecordings),
        fetchVoicemails = getVoicemails(values, setVoicemails),
        fetchSmsConversations = getSmsConversations(values, setSmsConversations);

    setNotificationsLoading(true);

    Promise.all([fetchCalls, fetchFaxes, fetchRecordings, fetchVoicemails, fetchSmsConversations])
      .then((res) => {
        for (var i = 0; i < res.length; i++) {
          if (res[i] instanceof Error && res[i].message === "Unauthorized") {
            resetSession();
            history.push('/login');
            return;
          }
        }

        setNotifications({
          call: parseNewItems(res[0].journalEntries),
          fax: parseNewItems(res[1].faxDocuments),
          recording: parseNewItems(res[2].callRecordings),
          voicemail: parseNewItems(res[3].voicemailRecordings),
          conversation: parseUnreadMessages(res[4].Conversations),
        });
        setNotificationsLoading(false);
      })
      .catch((err) => {
        setNotificationsLoading(false);
        console.error(err);
      });
  };

  const clearCurrentNotifications = () =>{
    let values = defaultValues;

    values.voicemail = voicemails
      .filter(
        (voicemail) =>
          ( voicemail.viewed !== "yes" && 
            voicemail.type.toLowerCase() === voicemailTab.toLowerCase() ) ||
          ( voicemail.viewed !== "yes" && 
            voicemailTab.toLowerCase() === "viewed" &&
            voicemail.type.toLowerCase() === "old" )
      )
      .map((voicemail) => {
        return {
          vid: voicemail.vid,
          viewed: "yes",
          type: voicemail.type,
        };
      });
    try {
      updateVoicemails(values);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    clearCurrentNotifications();
    // eslint-disable-next-line
  }, [voicemailTab, title]);

  const handleStatusChange = async (type,item) => {
    let voicemailType = type.toLowerCase() === "viewed" ? "old" : type.toLowerCase();
    try {
      await updateVoicemailStatus(defaultValues, item.vid, voicemailType);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  const handleDelete = (items) => {
    let values = defaultValues;

    values.voicemail = items.map((item) =>
      item.hasOwnProperty("object")
        ? {
            vid: item.object.vid,
            title: item.object.title,
            viewed: "yes",
            type: "deleted",
          }
        : {
            vid: item.vid,
            title: item.title,
            viewed: "yes",
            type: "deleted",
          }
    );

    try {
      updateVoicemails(values);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  const handleDeleteForever = (item) => {
    let values = defaultValues;
    values.id = item.vid

    try {
      deleteVoicemail(values);
      setError({
        visible: true,
        type: "success",
        message: "Successfully deleted Item",
      });
      refetchNotifications();
    } catch (error) {
      setError({
        visible: true,
        type: "error",
        message: error,
      });
      console.error(error);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <ForwardViaEmail
          open={email}
          setOpen={setEmail}
          file={attachment}
          type="Voicemail"
          defaultValues={defaultValues}
        />
        <Forward
          open={forward}
          setOpen={setForward}
          value={forwardItem}
          type="Voicemail"
          forwardFunction={forwardVoicemail}
          idKey="vid"
          defaultValues={defaultValues}
        />
        <EnhancedTable
          setError={setError}
          type="voicemail"
          headerData={headCells(groups)}
          rowData={generateRows()}
          dense={false}
          hasActions={true}
          selectable={false}
          menu={menu ? menuItems : false}
          title={title}
          tab={voicemailTab}
          history={history}
          hasClear={false}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          handleDialClick={null}
          setCalling={setCalling}
          setValue={setValue}
          setForwardItem={setForwardItem}
          setForward={setForward}
          loading={notifications.loading}
          statusChangeFunction={handleStatusChange}
          handleDelete={handleDelete}
          handleDeleteForever={handleDeleteForever}
          setAttachment={setAttachment}
          setSendEmail={setEmail}
          useGroupFilter={groups}
          createGroupOptions={createVoiceMailGroupOptions}
          filterGroup={voiceMailFilterGroup}
          setFilterGroup={setVoiceMailFilterGroup}
          statusOptions={["New", "Viewed", "Urgent"]}
          refreshDataOnSameTab={clearCurrentNotifications}
        />
      </Grid>
    </Grid>
  );
};

Voicemail.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      linkId: PropTypes.string,
    })
  ),
  tab: PropTypes.string.isRequired,
  handleDialClick: PropTypes.func.isRequired,
  setCalling: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  groups: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    voicemails: state.voicemails,
    notifications: state.notifications,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Voicemail)
);