import React, { useRef, useEffect } from "react";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref,onClickOutside) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
                //alert("You clicked outside of me!");
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClickOutside]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function EmojiPicker(props) {
    const {
        render,
        onClickOutside,
        selectEvent,
      } = props;

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef,onClickOutside);
    return <span ref={wrapperRef}>
        {
            render ? 
                <Picker
                    emojiSize={16}
                    native={true}
                    style={{ 
                        position: 'absolute',
                        bottom: '60px',
                        left:'0px',
                        zIndex: '993'
                    }}
                    onSelect={(e) => selectEvent(e)}
                /> : null
        }
    </span>;
}