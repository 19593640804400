import React, { useState, useEffect } from "react"
import { makeStyles, darken } from "@material-ui/core/styles"
import {
    Modal,
    Fade,
    Backdrop,
    TextField,
    Tabs,
    Tab,
    Button,
    Typography,
} from "@material-ui/core"
import { Grid, Paper } from "@material-ui/core"
import { setError } from "../store/features/error/errorSlice"
import { connect } from "react-redux"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import TabPanel from "../components/tabPanel"
import { 
    updatePersonalContact,
    createPersonalContact, 
    getPersonalPhonebook,
} from "../utils/utils"
import { areObjectsEqual } from "../utils/common"
import { setPersonalPhonebook } from "../store/features/phonebook/personalPhonebookSlice"

const mapDispatchToProps = {
    setError,
    setPersonalPhonebook,
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& p": {
            fontSize: "1.125rem",
            marginTop: 0,
            fontWeight: 200,
        },
        maxWidth: "100vw",
        maxHeight: "100vh",
    },
    heading: {
        marginTop: 0,
    },
    grid: {
        flexGrow: 1,
        marginBottom: theme.spacing(3),
    },
    primary: {
        background: theme.palette.primary.light,
        color: "white",
        marginLeft: theme.spacing(4),
        "&:hover": {
            background: darken(theme.palette.primary.light, 0.25),
        },
    },
    paper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        borderRadius: "4px",

        [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
            maxHeight: "75vh",
            maxWidth: "80vw",
            overflow: 'auto',
        },
        [theme.breakpoints.up("md")]: {
            minWidth: "60vw",
            minHeight: "72vh",
            maxWidth: "60vw",
        },
    },
    tabPanel: {
        flex: "auto",
    },
    close: {
        position: "absolute",
        right: theme.spacing(3),
        top: theme.spacing(3),
        background: "black",
        color: "white",
        "&:hover": {
            background: "black",
        },
        [theme.breakpoints.up("md")]: {
            right: "-.65em",
            top: "-.65em",
        },
    },
    reset: {
        background: theme.palette.text.secondary,
        color: "white",
        "&:hover": {
            background: darken(theme.palette.text.secondary, .25),
        },
    },
    buttonFooter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
        marginTop: "-16px",
        marginLeft: "-24px",
    },
    errorMessage: {
        color: "rgba(255,82,65)",
        flex: "auto",
        marginLeft: "64px",
        marginTop: "8px",
    }
}));

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        "aria-controls": `nav-tabpanel-${index}`,
    };
}

const e164_regex = new RegExp(/^[0-9]+$/);

const ContactInfoPersonal = (props) => {
    const classes = useStyles();
    const {
        open,
        setOpen,
        edit,
        setEdit,
        create,
        setCreate,
        contact,
        setContact,
        session,
        setPersonalPhonebook,
        setError,
    } = props;

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // let contact = props;
    // contact = {
    //     "pid": 5,
    //     "firstName": "Gregory",
    //     "lastName": "Dedes",
    //     "trunkAccess": "",
    //     "email": "g.dedes@gmail.com",
    //     "company": "",
    //     "address": {
    //         "streetOne": "Street One",
    //         "streetTwo": "Back Street",
    //         "city": "Athens",
    //         "state": "Attika",
    //         "zip": "17342",
    //         "county": "Greece",
    //         "country": ""
    //     },
    //     "numbers": {
    //         "office": "1111111111",
    //         "mobile": "2222222222",
    //         "fax": "3333333333",
    //         "sms": "4444444444"
    //     },
    //     "info": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It"
    // }
    const [currentContact, setCurrentContact] = useState(contact)
    const [initContact, setInitContact] = useState(contact)
    const [submitting, setSubmitting] = useState(false)

    const clearState = () => {
        setOpen(false)
        setEdit(false)
        setCreate(false)
        setContact(null)
        setCurrentContact(null)
        setInitContact(null)
    }

    if (create && !currentContact) {
        const emptyContact = {
            "firstName": "",
            "lastName": "",
            "trunkAccess": "",
            "email": "",
            "company": "",
            "address": {
                "streetOne": "",
                "streetTwo": "",
                "city": "",
                "state": "",
                "zip": "",
                "county": "",
                "country": ""
            },
            "numbers": {
                "office": "",
                "mobile": "",
                "fax": "",
                "sms": ""
            },
            "info": "",
            "type": "personal",
        };
        setCurrentContact(emptyContact)
        setInitContact(emptyContact)
    }

    useEffect(() => {
        setCurrentContact(contact)
        setInitContact(contact)
    }, [contact]);

    const handlePropertyChange = (field, value) => {
        setCurrentContact({
            ...currentContact,
            [field]: value,
        });
    };

    const handleSubPropertyChange = (category, field, value) => {
        setCurrentContact({
            ...currentContact,
            [category]: {
                ...currentContact[category],
                [field]: value,
            },
        });
    };

    const handleSubmit = async () => {
        try {
            let data = {
                contactDetails: {
                    ...currentContact
                },
                token: session.token,
                server: session.lastServer,
            }
            if (edit) {
                await updatePersonalContact(data);
            } else if (create) {
                await createPersonalContact(data);
            }
            setSubmitting(false);

            try {
                await getPersonalPhonebook(
                    {
                        token: session.token,
                        server: session.lastServer,
                    },
                    setPersonalPhonebook
                );
                setOpen(false)
                setError({
                    visible: true,
                    type: "success",
                    message: "Contact " + edit ? "updated." : "created.",
                });
            } catch (error) {
                setSubmitting(false);
                setError({
                    visible: true,
                    type: "error",
                    message: "Failed to refetch contacts. " + error,
                })
                setOpen(false)
            }
        } catch (error) {
            setSubmitting(false);
            setError({
                visible: true,
                type: "error",
                message: "Failed to " + (edit ? "update" : "created")  + " contect. " + error,
            })
        }
    };

    const handleReset = () => {
        setCurrentContact(initContact);
    };

    if (!open || !currentContact || currentContact.type !== "personal")
        return null;

    const readOnly = !edit && !create;
    const errorMessage = "Required fields"

    const isModified = !areObjectsEqual(currentContact, initContact);
    const missingRequiredFields =
        currentContact.firstName === '' ||
        currentContact.lastName === '' ||
        !e164_regex.test(currentContact.numbers.office);

    return (
        <Modal
            aria-labelledby="internal-contact-info"
            aria-describedby="Internal Contact Info"
            className={classes.modal}
            open={open}
            onClose={clearState}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Paper className={classes.paper}>
                    <IconButton
                        aria-label="close"
                        className={classes.close}
                        size="small"
                        onClick={clearState}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Grid item xs={12} >
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            className={classes.tabs}
                        >
                            <Tab label="Details" {...a11yProps(0)} />
                            <Tab label="Address" {...a11yProps(1)} />
                        </Tabs>
                    </Grid>

                    <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="First Name"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required={edit || create}
                                    value={currentContact.firstName}
                                    onChange={(e) => handlePropertyChange('firstName', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                    error={currentContact.firstName === ''}
                                />
                                <TextField
                                    label="Last Name"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required={edit || create}
                                    value={currentContact.lastName}
                                    onChange={(e) => handlePropertyChange('lastName', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                    error={currentContact.lastName === ''}
                                />
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentContact.email}
                                    onChange={(e) => handlePropertyChange('email', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                                <TextField
                                    label="Business Number"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required={edit || create}
                                    value={currentContact.numbers.office}
                                    onChange={(e) => handleSubPropertyChange('numbers', 'office', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                    error={!e164_regex.test(currentContact.numbers.office)}
                                />
                                <TextField
                                    label="Mobile Number"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentContact.numbers.mobile}
                                    onChange={(e) => handleSubPropertyChange('numbers', 'mobile', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Company"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentContact.company}
                                    onChange={(e) => handlePropertyChange('company', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                                <TextField
                                    label="Fax Number"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentContact.numbers.fax}
                                    onChange={(e) => handleSubPropertyChange('numbers', 'fax', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                                <TextField
                                    label="Sms Number"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentContact.numbers.sms}
                                    onChange={(e) => handleSubPropertyChange('numbers', 'sms', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                                <TextField
                                    label="Info"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    value={currentContact.info}
                                    onChange={(e) => handlePropertyChange('info', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                        style: {
                                            padding: "17px"
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>

                    <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Street One"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentContact.address.streetOne}
                                    onChange={(e) => handleSubPropertyChange('address', 'streetOne', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                                <TextField
                                    label="Street Two"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentContact.address.streetTwo}
                                    onChange={(e) => handleSubPropertyChange('address', 'streetTwo', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                                <TextField
                                    label="City"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentContact.address.city}
                                    onChange={(e) => handleSubPropertyChange('address', 'city', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                                <TextField
                                    label="State"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentContact.address.state}
                                    onChange={(e) => handleSubPropertyChange('address', 'state', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Zip"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentContact.address.zip}
                                    onChange={(e) => handleSubPropertyChange('address', 'zip', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                                <TextField
                                    label="County"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentContact.address.county}
                                    onChange={(e) => handleSubPropertyChange('address', 'county', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                                <TextField
                                    label="Country"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentContact.address.country}
                                    onChange={(e) => handleSubPropertyChange('address', 'country', e.target.value)}
                                    InputProps={{
                                        readOnly: readOnly,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {readOnly ? null :
                        <div className={classes.buttonFooter}>
                            { missingRequiredFields
                                ?
                                <Typography className={classes.errorMessage} variant="h7" noWrap>
                                    {errorMessage}
                                </Typography>
                                : null }
                            { edit ? <Button
                                variant="contained"
                                size="medium"
                                onClick={handleReset}
                                className={classes.reset}
                            >
                                Reset
                            </Button> 
                            : null }
                            <Button
                                variant="contained"
                                size="medium"
                                className={classes.primary}
                                disabled={submitting || !isModified || missingRequiredFields}
                                onClick={handleSubmit}
                            >
                                { edit ? "Update" : "Save" }
                            </Button>
                        </div>}
                </Paper>
            </Fade>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        session: state.session,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoPersonal);