import React,{useState,useEffect} from "react";
import { connect } from "react-redux";
import { makeStyles,darken } from "@material-ui/core/styles";
import {
  Modal,
  Fade,
  Backdrop,
  TextField,
  Button,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { setError } from "../store/features/error/errorSlice";
import { updateRecordings, updateConference, updateFaxes } from "../utils/utils";

const mapDispatchToProps = {
  setError,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      fontSize: "1.125rem",
      marginTop: 0,
      fontWeight: 200,
    },
  },
  heading: {
    marginTop: 0,
  },
  grid: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
  },
  primary: {
    background: theme.palette.primary.light,
    color: theme.palette.text.selected,
    marginLeft: theme.spacing(4),
    "&:hover": {
      background: darken(theme.palette.primary.light, 0.25),
    },
  },
  paper: {
    position: "relative",
    minWidth: "500px",
    minHeight: "250px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    border: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4),
    borderRadius: "4px",
  },
  buttonFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  close: {
    position: "absolute",
    right: "-.65em",
    top: "-.65em",
    background: "black",
    color: "white",
    "&:hover": {
      background: "black",
    },
  },
  cancel: {
    background: theme.palette.text.secondary,
    color: "white",
    "&:hover": {
      background: darken(theme.palette.text.secondary,.25),
    },
  },
}));

const EditTitle = (props) => {
    const classes = useStyles();
    const {
      open,
      setOpen,
      value,
      setError,
      type,
      defaultValues,
      reloadNotifications,
      setTitleChangeItem,
    } = props;
    const [title, setTitle] = useState(
      value !== null ? (type.toLowerCase() === "recording" || type.toLowerCase() === "fax")
                        ? value.title
                        : value.name
                     : ""
    );
    const [dirty,setDirty]= useState(false);
    
    useEffect(()=>{
      if(value!==null && title !== value.title){
        setDirty(true)
      }else setDirty(false);
      // eslint-disable-next-line
    },[title])

    const handleTitleChange = async () => {
      let values = defaultValues;
      let res;
      try {
        if(type.toLowerCase()==="recording"){
          values.recordings = [{
            rid: value.rid,
            title: title,
            viewed: value.viewed,
            type: value.type
          }];
          res = await updateRecordings(values);
        } else if(type.toLowerCase()==="conference") {
          values.conference = {
              name: title,
              type: value.type
            };
            console.log(values)
          res = await updateConference(values, value.cid);
        } else if(type.toLowerCase()==="fax") {
          values.fax = [{
            fid: value.fid,
            title: title,
            viewed: value.viewed,
            group: value.group,
            type: value.type
          }]
          res = await updateFaxes(values);
        }

        if (res instanceof Error) throw res.message;

        setError({
          visible: true,
          type: "success",
          message: `${type} title successfully updated.`,
        });
        reloadNotifications();
        handleClose()
      } catch (error) {
        setError({
          visible: true,
          type: "error",
          message: `Error: ${error}`,
        });
      }
      
    }

    const handleClose = ()=>{
      setTitle("")
      setTitleChangeItem(null);
      setOpen(false)
      setDirty(false)
    }

    return (
      <Modal
        aria-labelledby="title-change-modal"
        aria-describedby={`modal to update title of ${type}`}
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <IconButton
              aria-label="close"
              className={classes.close}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <h2 className={classes.heading}>Update {type} Title</h2>
            <p>Please enter the new title for your {type}.</p>
            <div className={classes.grid}>
              <TextField
                required
                id="title-field"
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                variant="outlined"
                fullWidth
              />
            </div>

            <div className={classes.buttonFooter}>
              <Button
                variant="contained"
                size="medium"
                onClick={(e) => setOpen(false)}
                className={classes.cancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="medium"
                disabled={!dirty}
                className={classes.primary}
                onClick={handleTitleChange}
              >
                Update
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    );
}

export default connect(null, mapDispatchToProps)(EditTitle);