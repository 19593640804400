import React,{useState,useEffect} from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Menu, MenuItem, Grid, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import VoicemailIcon from "@material-ui/icons/Voicemail";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import SmsIcon from '@material-ui/icons/Sms';
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import PhoneMissedIcon from "@material-ui/icons/PhoneMissed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFax } from "@fortawesome/free-solid-svg-icons";
import {
  updateVoicemailViewed,
  updateCallJournal,
  updateRecordingsViewed,
  updateFaxes,
  getCallJournal,
  getFaxes,
  getRecordings,
  getVoicemails,
  getSmsConversations,
} from "../utils/utils";
import {
  setNotifications,
  setNotificationsLoading,
} from "../store/features/notification/notificationSlice";
import { setCalls } from "../store/features/calls/callSlice";
import { setVoicemails } from "../store/features/voicemails/voicemailSlice";
import { setSmsConversations } from "../store/features/smsConversations/smsConversationsSlice";
import { setFaxes } from "../store/features/faxes/faxSlice";
import { setRecordings } from "../store/features/recordings/recordingSlice";
import { resetSession } from "../store/features/session/sessionSlice";

const mapDispatchToProps = {
  setCalls,
  setVoicemails,
  setSmsConversations,
  setFaxes,
  setRecordings,
  setNotifications,
  setNotificationsLoading,
  resetSession,
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& ul": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& li": {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      width: "400px",
      maxWidth: "100%",
      "&:hover": {
        background: theme.palette.primary.light,
        "& a, a > svg , div > svg": {
          color: theme.palette.text.selected,
          "&:hover": {
            color: theme.palette.text.selected,
          },
        },
      },
      "& a, a > svg , div > svg": {
        color: theme.palette.text.primary,
        textDecoration: "none",
      },
      "&:last-of-type": {
        "&:hover": {
          backgroundColor: "unset",
        },
      },
    },
  },
  menuItem: {
    borderBottom: "1px solid " + theme.palette.primary.hr,
  },
  verticalPadding: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  capital: {
    textTransform: "capitalize",
  },
  h100: {
    height: "100%",
  },
  notificationIcon: {
    alignItems: "right",
    justifyContent: "center",
    display: "flex",
  },
  bottomBar: {
    textAlign: "right",
    "& button": {
      marginLeft: "auto",
      marginRight: theme.spacing(1),
      paddingTop: ".2em",
      paddingBottom: ".2em",
      color: theme.palette.primary.light,
      "&:hover": {
        background: "none",
        color: theme.palette.text.selected,
      },
    },
  },
  caughtUp: {
    maxWidth: "100%",
    width: "100vw",
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      color: theme.palette.primary.success,
    },
    "& p": {
      fontSize: "1.25em",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "400px",
      minHeight: "150px",
      width: "100%",
      margin: "unset",
      padding: "unset",
    },
  },
  text: {
    paddingLeft: theme.spacing(2),
  },
}));


const NotificationMenu = (props)=>{
    const {
      isOpen,
      setNotifications,
      onCloseFunction,
      menuAnchorElement,
      notifications,
      badgeSetter,
      session,
      resetSession,
      isLoading,
      settings,
      history,
    } = props;
    const classes = useStyles();
    const [count,setCount] = useState(0);
    
    const getTotalNotifications = ()=>{
      let total =
        getCount("voicemail") +
        getCount("call") +
        getCount("fax") +
        getCount("recording") + 
        getCount("conversation")
      return total;
    }

    useEffect(() => {
      recount();
      // eslint-disable-next-line
    }, [notifications,isLoading]);

    const recount = () => {
      let total = getTotalNotifications();

      setCount(total);
      badgeSetter(total);
    }

    const getCount = (type)=>{
      if(settings.notifications[type]==="no") {
        return 0;
      }
      if (type === "conversation") {
        return notifications[type].reduce( function(total, record) {
          return total + record.unreadMessages;
        },0);
      }
      return notifications[type].length;
    };

    const notificationTypes = [
      {
        icon: <PhoneMissedIcon fontSize="small" />,
        text: "Missed Call",
        type: "call",
        count: getCount("call"),
      },
      {
        icon: <VoicemailIcon fontSize="small" />,
        text: "New Voicemail",
        type: "voicemail",
        count: getCount("voicemail"),
      },
      {
        icon: <FontAwesomeIcon icon={faFax} className={classes.faResize} />,
        text: "New Fax",
        type: "fax",
        count: getCount("fax"),
      },
      {
        icon: <RecordVoiceOverIcon fontSize="small" />,
        text: "New Call Recording",
        type: "recording",
        count: getCount("recording"),
      },
      {
        icon: <SmsIcon fontSize="small" />,
        text: "New SMS Messages",
        type: "conversation",
        count: getCount("conversation"),
      },
    ];

    const parseNewItems = (data = []) => {
      return data.filter((item) => item.viewed === "no");
    };

    const parseUnreadMessages = (data = []) => {
      return data.filter((item) => item.unreadMessages !== 0);
    }

    const refetchNotifications = ()=>{
      const values = {
        server: session.lastServer,
        token: session.token,
      };

      let fetchCalls = getCallJournal(values, setCalls),
          fetchFaxes = getFaxes(values, setFaxes),
          fetchRecordings = getRecordings(values, setRecordings),
          fetchVoicemails = getVoicemails(values, setVoicemails),
          fetchSmsConversatons = getSmsConversations(values, setSmsConversations);
      
      setNotificationsLoading(true);

      Promise.all([fetchCalls,fetchFaxes,fetchRecordings,fetchVoicemails,fetchSmsConversatons])
        .then((res)=>{
          for (var i = 0; i < res.length; i++) {
            if (res[i] instanceof Error && res[i].message === "Unauthorized") {
              resetSession();
              history.push('/login');
              return;
            }
          }

          setNotifications({
            call: parseNewItems(res[0].journalEntries),
            fax: parseNewItems(res[1].faxDocuments),
            recording: parseNewItems(res[2].callRecordings),
            voicemail: parseNewItems(res[3].voicemailRecordings),
            conversation: parseUnreadMessages(res[4].Conversations),
          });
          setNotificationsLoading(false);
        }).catch((err)=>{
          setNotificationsLoading(false);
          console.error(err);
        })
    }

    const handleClearIndividual = (itemType) => {
      let values = {
        server: session.lastServer,
        token: session.token,
      };
      if(notifications[itemType].length>0){
        switch(itemType){
          case "call":
            values.call = notifications.call.map(item=>{
              return {
                jid: item.jid,
                viewed: "yes",
                type: item.type,
                direction: item.direction,
              };
            });

            try{
              updateCallJournal(values);
              refetchNotifications();
              onCloseFunction("notifications");
            } catch(error){
              console.error(error);
            }
            
            break;
          case "voicemail":
            values.voicemail = notifications.voicemail.map(item=>{
              return {
                vid: item.vid,
                viewed: "yes"
              }
            });

            try{
              updateVoicemailViewed(values);
              refetchNotifications();
              onCloseFunction("notifications");
            } catch(error){
              console.error(error);
            }
            
            break;
          case "recording":
            values.recording = notifications.recording.map((item) => {
              return {
                rid: item.rid,
                viewed: "yes",
                type: "new",
                title: item.title,
              };
            });
            try {
              updateRecordingsViewed(values);
              refetchNotifications();
              onCloseFunction("notifications");
            } catch (error) {
              console.error(error);
            }
            break;
          case "fax":
            values.fax = notifications.fax.map((item) => {
              return {
                fid: item.fid,
                viewed: "yes",
                type: "new",
                group: item.group,
                title: item.title,
              };
            });
            try {
              updateFaxes(values);
              refetchNotifications();
              onCloseFunction("notifications");
            } catch (error) {
              console.error(error);
            }
            break;
          case "conversation":
              values.conversation = notifications.conversation.map(item=>{
                return {
                  conversation_id: item.conversation_id,
                  viewed: "yes",
                  type: item.type,
                  direction: item.direction,
                };
              });
  
              try{
                // updateCallJournal(values);
                refetchNotifications();
                onCloseFunction("notifications");
              } catch(error){
                console.error(error);
              }
              
              break;
          default:
            break;
        }
      }
    }

    const handleClearAll = () => {
      let values = {
        server: session.lastServer,
        token: session.token,
      };
      let callValues,vmValues,fValues,rValues;
      
      if (getTotalNotifications() > 0) {
        setNotificationsLoading(true);
        let vm,fax,rec,call;

        if(settings.notifications.call){
          callValues = { ...values };
          callValues.call = notifications.call.map((item) => {
              return {
                jid: item.jid,
                viewed: "yes",
                type: item.type,
                direction: item.direction,
              };
            });
          call = updateCallJournal(callValues);
        }

        if(settings.notifications.voicemail){
          vmValues = { ...values };
          vmValues.voicemail = notifications.voicemail.map((item) => {
            return {
              vid: item.vid,
              viewed: "yes",
            };
          });
          vm = updateVoicemailViewed(vmValues);
        }
        
        if(settings.notifications.fax){
          fValues = { ...values };
          fValues.fax = notifications.fax.map((item) => {
            return {
              fid: item.fid,
              title: item.title,
              viewed: "yes",
              group: item.group,
              type: "new",
            };
          });
          fax = updateFaxes(fValues);
        }

        if(settings.notifications.recording){
          rValues = { ...values };
          rValues.recording = notifications.recording.map((item) => {
            return {
              rid: item.rid,
              title: item.title,
              viewed: "yes",
              type: "new",
            };
          });
          rec = updateRecordingsViewed(rValues);
        }

        Promise.all([call,vm, fax, rec])
          .then((res) => {
            for (var i = 0; i < res.length; i++) {
              if (res[i] instanceof Error && res[i].message === "Unauthorized") {
                resetSession();
                history.push('/login');
                return;
              }
            }

            console.log(res);
            refetchNotifications();
            setNotificationsLoading(false);
          })
          .catch((err) => {
            setNotificationsLoading(false);
            console.error(err);
          });
        
      }
    };

    const renderNotifications = ()=>{
      // eslint-disable-next-line
        return notificationTypes.map((item, index) => {
          if(settings.notifications[item.type]==="yes"){
            return (
              <MenuItem
                key={index}
                disableGutters
                className={clsx(classes.menuItem, classes.verticalPadding)}
              >
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={0}
                >
                  <Grid
                    item
                    xs={1}
                    className={classes.gridItem}
                    component={Link}
                    to={`/dashboard/notifications/${item.type}`}
                    onClick={(e) => onCloseFunction("notifications")}
                  >
                    {item.icon}
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    component={Link}
                    to={`/dashboard/notifications/${item.type}`}
                    onClick={(e) => onCloseFunction("notifications")}
                    className={clsx(classes.h100,classes.text)}
                  >
                    {`${item.count > 0 ? item.count : "No"} ${item.text}${
                      item.type === "fax" && item.count !== 1 ? "e" : ""
                    }${item.count !== 1 ? "s" : ""}`}
                  </Grid>
                  <Grid item xs={1} className={classes.notificationIcon}>
                    {
                      item.count>0
                      ? <NotificationsOffIcon
                          fontSize="small"
                          onClick={(e) => handleClearIndividual(item.type)}
                        />
                      : null
                    }
                  </Grid>
                </Grid>
              </MenuItem>
            )
          }
      });
    };
    
    return (
      <Menu
        getContentAnchorEl={null}
        anchorEl={menuAnchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="notification-menu"
        keepMounted
        open={isOpen}
        onClose={(e) => onCloseFunction("notifications")}
        className={classes.root}
      >
        {count > 0 ? (
          renderNotifications()
        ) : (
          <div
            className={clsx(
              classes.caughtUp,
              classes.verticalPadding
            )}
          >
            <CheckCircleOutlinedIcon
              style={{ fontSize: "60px", marginTop: ".3em" }}
            />
            <p>Congrats, You're all caught up!</p>
          </div>
        )}

        {count > 0 ? (
          <MenuItem disableGutters className={classes.bottomBar} onClick={handleClearAll}>
            <Button>Clear All</Button>
          </MenuItem>
        ) : null}
      </Menu>
    );
};

NotificationMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseFunction: PropTypes.func.isRequired,
  menuAnchorElement: PropTypes.object,
  badgeSetter: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    settings: state.user.webapp,
    session: state.session,
    notifications: state.notifications,
    isLoading: state.notifications.loading,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationMenu)
);