import React, { useState } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import clsx from "clsx";
import { connect } from "react-redux";
import { setError } from "../store/features/error/errorSlice";
import Drawer from '@material-ui/core/Drawer';
import { 
  setActiveCall,
  setSipSessions,
  setCallingNumber,
} from "../store/features/user/userSlice";
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Faxes from "../components/faxes";
import { 
  renderDrawerMenu,
  // renderDialer,
} from "../utils/common";
import { useStyles } from "./styles/viewStyles";
import HelpMenu from "../components/helpMenu";

const mapDispatchToProps = {
  setError,
  setActiveCall,
  setSipSessions,
  setCallingNumber,
};

const FaxView = (props) => {
  const classes = useStyles();
  const { 
    match,
    history,
    window,
    callingNumber,
    setCallingNumber,
  } = props;
  const tab = match.params.type;
  const isCurrentTab = (item) => tab.toLowerCase() === item.type.toLowerCase();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const drawerMenuItems = [
    {
      text: "Fax",
      type: "Fax",
      to: "fax/all"
    },
    {
      text: "Fax Groups",
      type: "faxGroup",
      to: "faxgroup/all"
    },
  ];

  const drawerMenu = renderDrawerMenu(
    classes,
    drawerMenuItems,
    isCurrentTab,
    handleDrawerToggle,
    '/dashboard/fax/'
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  const faxMenu = [
    {
      title: "All",
      linkId: "all",
    },
    {
      title: "New",
      linkId: "new",
    },
    {
      title: "Viewed",
      linkId: "viewed",
    },
    {
      title: "Urgent",
      linkId: "urgent",
    },
    {
      title: "Deleted",
      linkId: "deleted",
    },
  ];

  return (
    <div className={classes.root}>
      <HelpMenu />
      <nav className={classes.drawer} aria-label="call bar">
        <Hidden mdUp>
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div
              className={
                mobileOpen
                  ? clsx(classes.menuOpen, classes.menuButton)
                  : clsx(classes.menuClosed, classes.menuButton)
              }
            >
              <IconButton
                aria-label="open menu"
                onClick={handleDrawerToggle}
                className={classes.menuButtonIcon}
              >
                {mobileOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            {drawerMenu()}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawerMenu()}
          </Drawer>
        </Hidden>
      </nav>
      <Switch history={history} className={classes.content}>
        <Route path={`/dashboard/fax/fax/:faxTab`}>
          <Faxes
            title="Fax"
            menu={true}
            tab={tab}
            menuItems={faxMenu}
            value={callingNumber}
            setValue={setCallingNumber}
            groups={false}
          />
        </Route>
        <Route path={`/dashboard/fax/faxGroup/:faxTab`}>
          <Faxes
            title="Fax Groups"
            menu={true}
            tab={tab}
            menuItems={faxMenu}
            value={callingNumber}
            setValue={setCallingNumber}
            groups={true}
          />
        </Route>
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    phonebook: state.phonebook,
    voip: state.user.voip,
    remoteNumber: state.user.remoteNumber,
    callingNumber: state.user.callingNumber,
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FaxView)
);

