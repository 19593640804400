import React,{useState,useEffect} from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
  Grid,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import EnhancedTable from "./enhancedTable"
import {
  getCallJournal,
  getFaxes,
  getRecordings,
  getVoicemails,
  getSmsConversations,
  deletePersonalContact,
  getPersonalPhonebook,
} from "../utils/utils"
import {
  setNotifications,
  setNotificationsLoading,
} from "../store/features/notification/notificationSlice"
import { setCalls } from "../store/features/calls/callSlice"
import { setVoicemails } from "../store/features/voicemails/voicemailSlice"
import { setSmsConversations } from "../store/features/smsConversations/smsConversationsSlice"
import { setFaxes } from "../store/features/faxes/faxSlice"
import { setRecordings } from "../store/features/recordings/recordingSlice"
import { setError } from "../store/features/error/errorSlice"
import { setPersonalPhonebook } from "../store/features/phonebook/personalPhonebookSlice"
import ContactEmail from "./contactEmail"
import { resetSession } from "../store/features/session/sessionSlice"
import ContactInfoInternal from "./contactInfoInternal"
import ContactInfoExternal from "./contactInfoExternal"
import ContactInfoPersonal from "./contactInfoPersonal"

const mapDispatchToProps = {
  setError,
  setCalls,
  setVoicemails,
  setSmsConversations,
  setFaxes,
  setRecordings,
  setNotifications,
  setNotificationsLoading,
  setPersonalPhonebook,
  resetSession,
};

const headCells = () => {
  return {
    all: [
      { id: "name", numeric: false, disablePadding: false, label: "Name", },
      { id: "phone", numeric: true, disablePadding: false, label: "Phone Number" },
      { id: "email", numeric: true, disablePadding: false, label: "Email" },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ],
    internal: [
      { id: "name", numeric: false, disablePadding: false, label: "Name", },
      { id: "phone", numeric: true, disablePadding: false, label: "Phone Number" },
      { id: "email", numeric: true, disablePadding: false, label: "Email" },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ],
    external: [
      { id: "name", numeric: false, disablePadding: false, label: "Name", },
      { id: "phone", numeric: true, disablePadding: false, label: "Phone Number" },
      { id: "email", numeric: true, disablePadding: false, label: "Email" },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ],
    personal: [
      { id: "name", numeric: false, disablePadding: false, label: "Name", },
      { id: "phone", numeric: true, disablePadding: false, label: "Phone Number" },
      { id: "email", numeric: true, disablePadding: false, label: "Email" },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ],
  }
}

const Contacts = (props) => {
  const {
    title,
    menu,
    menuItems,
    history,
    phonebook,
    externalPhonebook,
    personalPhonebook,
    session,
    resetSession,
    setVoicemails,
    setSmsConversations,
    setFaxes,
    setRecordings,
    setNotifications,
    setNotificationsLoading,
    setCalling,
    notifications,
    setValue,
    match,
    setPersonalPhonebook,
    setError,
  } = props;

  let contactsTab = match.params.tab;
  const [page,setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [icInfoOpen, setIcInfoOpen] = useState(false)
  const [ecInfoOpen, setEcInfoOpen] = useState(false)
  const [pcInfoOpen, setPcInfoOpen] = useState(false)
  const [pcInfoEdit, setPcInfoEdit] = useState(false)
  const [contactItem, setContactItem] = useState(null)
  const [createPersonalContact, setCreatePersonalContact] = useState(false);
  const [email, setEmail] = useState(false);
  const [recipient, setRecipient] = useState('');
  const [filterTerm, setFilterTerm] = useState('');
  const defaultValues = {
    server: session.lastServer,
    token: session.token,
  };

  const createPersonalContactCb = () => {
    setCreatePersonalContact(true)
    setPcInfoOpen(true)
  }

  const setOpenContactInfo = (type) => {
    if (type === "internal") {
      setIcInfoOpen(true)
    } else if (type === "external") {
      setEcInfoOpen(true)
    }
    else if (type === "personal") {
      setPcInfoOpen(true)
    }
  }

  const searchContacts = (value) => {
    setFilterTerm(value.toLowerCase())
  }

  const phonebookFiltered = phonebook.filter((item) => {
    if (filterTerm.length >= 2) {
      return item.firstName.toLowerCase().includes(filterTerm) ||
        item.lastName.toLowerCase().includes(filterTerm) ||
        item.email.toLowerCase().includes(filterTerm) ||
        item.voicemail.mailbox.includes(filterTerm) ||
        item.numbers.office.includes(filterTerm) ||
        item.numbers.mobile.includes(filterTerm) ||
        item.incomingProviderNumber.number.includes(filterTerm) ||
        item.fax.number.includes(filterTerm) ||
        item.conversation.number.includes(filterTerm)
    }
    else {
      return item
    }
  })

  const externalPhonebookFiltered = externalPhonebook.filter((item) => {
    if (filterTerm.length >= 2) {
      return item.firstName.toLowerCase().includes(filterTerm) ||
        item.lastName.toLowerCase().includes(filterTerm) || 
        item.numbers.office.includes(filterTerm) ||
        item.numbers.mobile.includes(filterTerm) ||
        item.numbers.fax.includes(filterTerm) ||
        item.numbers.sms.includes(filterTerm) ||
        item.speedDial.code.includes(filterTerm) ||
        item.speedDial.number.includes(filterTerm)
    }
    else {
      return item
    }
  })

  const personalPhonebookFiltered = personalPhonebook.filter((item) => {
    if (filterTerm.length >= 2) {
      return item.firstName.toLowerCase().includes(filterTerm) ||
        item.lastName.toLowerCase().includes(filterTerm) ||
        item.email.toLowerCase().includes(filterTerm) ||
        item.numbers.office.includes(filterTerm) ||
        item.numbers.mobile.includes(filterTerm) ||
        item.numbers.fax.includes(filterTerm) ||
        item.numbers.sms.includes(filterTerm)
    }
    else {
      return item
    }
  })

  const createRowDataInternal = (item) => {
    let obj = {
      name: item.firstName + ' ' + item.lastName,
      phone: item.voicemail.mailbox,
      email: item.email,
      actions: [
        { type: "contactInfo" },
        { type: "contactCall" },
        { type: "contactEmail" },
      ],
      object: {
        type: "internal",
        ...item
      },
    };

    return obj;
  }

  const createRowDataExternal = (item) => {
    let obj = {
      name: item.firstName + ' ' + item.lastName,
      phone: item.number,
      email: '',
      actions: [
        { type: "contactInfo" },
        { type: "contactCall" },
        { type: "contactEmail" },
      ],
      object: {
        type: "external",
        ...item
      },
    };

    return obj; 
  }

  const createRowDataPersonal = (item) => {
    let obj = {
      name: item.firstName + ' ' + item.lastName,
      phone: item.numbers.office,
      email: item.email,
      actions: [
        // { type: "contactInfo" },
        { type: "contactCall" },
        { type: "contactEmail" },
        { type: "contactEdit" },
        { type: "contactDelete" },
      ],
      object: {
        type: "personal",
        ...item
      },
    };

    return obj; 
  }

  const generateRows = ()=>{
    switch (contactsTab) {
      case "all":
        return [
          ...personalPhonebookFiltered
            .map((item) => {
              return createRowDataPersonal(item);
            }),
          ...phonebookFiltered
            .map((item) => {
              return createRowDataInternal(item);
            }),
          ...externalPhonebookFiltered
            .map((item) => {
              return createRowDataExternal(item);
            })
        ];
      case "internal":
        return phonebookFiltered
          .map((item) => {
            return createRowDataInternal(item);
          });
      case "external":
        return externalPhonebookFiltered
          .map((item) => {
            return createRowDataExternal(item);
          });
      case "personal":
        return personalPhonebookFiltered
          .map((item) => {
            return createRowDataPersonal(item);
          });
      default:
        break;
    }
  }

  const parseNewItems = (data = []) => {
    return data.filter((item) => item.viewed === "no");
  };

  const parseUnreadMessages = (data = []) => {
    return data.filter((item) => item.unreadMessages !== 0);
  }

  const refetchNotifications = () => {
    const values = defaultValues;

    let fetchCalls = getCallJournal(values, setCalls),
        fetchFaxes = getFaxes(values, setFaxes),
        fetchRecordings = getRecordings(values, setRecordings),
        fetchVoicemails = getVoicemails(values, setVoicemails),
        fetchSmsConversations = getSmsConversations(values, setSmsConversations);

    setNotificationsLoading(true);

    Promise.all([fetchCalls, fetchFaxes, fetchRecordings, fetchVoicemails, fetchSmsConversations])
      .then((res) => {
        for (var i = 0; i < res.length; i++) {
          if (res[i] instanceof Error && res[i].message === "Unauthorized") {
            resetSession();
            history.push('/login');
            return;
          }
        }

        setNotifications({
          call: parseNewItems(res[0].journalEntries),
          fax: parseNewItems(res[1].faxDocuments),
          recording: parseNewItems(res[2].callRecordings),
          voicemail: parseNewItems(res[3].voicemailRecordings),
          conversation: parseUnreadMessages(res[4].Conversations),
        });
        setNotificationsLoading(false);
      })
      .catch((err) => {
        setNotificationsLoading(false);
        console.error(err);
      });
  };

  const clearCurrentNotifications = () =>{
    // let values = defaultValues;

    try {
      // updateVoicemails(values);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    clearCurrentNotifications();
    // eslint-disable-next-line
  }, [contactsTab, title]);

  const handleDeleteForever = async (item) => {
    let values = defaultValues;
    values.id = item.pid

    try {
      await deletePersonalContact(values);
      try {
        await getPersonalPhonebook(
            {
                token: session.token,
                server: session.lastServer,
            },
            setPersonalPhonebook
        );
        setError({
          visible: true,
          type: "success",
          message: "Successfully deleted Item",
        });
    } catch (error) {
        setError({
            visible: true,
            type: "error",
            message: "Failed to refetch contacts. " + error,
        })
    }
    } catch (error) {
        setError({
          visible: true,
          type: "error",
          message: "Failed to delete contact. " + error,
      })
      console.error(error);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <ContactEmail
          open={email}
          setOpen={setEmail}
          to={recipient}
        />
        <ContactInfoInternal
          open={icInfoOpen}
          setOpen={setIcInfoOpen}
          contact={contactItem}
        />
        <ContactInfoExternal
          open={ecInfoOpen}
          setOpen={setEcInfoOpen}
          contact={contactItem}
        />
        <ContactInfoPersonal
          open={pcInfoOpen}
          setOpen={setPcInfoOpen}
          edit={pcInfoEdit}
          setEdit={setPcInfoEdit}
          create={createPersonalContact}
          setCreate={setCreatePersonalContact}
          contact={contactItem}
          setContact={setContactItem}
        />
        <EnhancedTable
          setError={setError}
          type="contact"
          headerData={headCells()}
          rowData={generateRows()}
          dense={false}
          hasActions={true}
          selectable={false}
          menu={menu ? menuItems : false}
          searchFunc={searchContacts}
          title={title}
          tab={contactsTab}
          history={history}
          hasClear={false}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          handleDialClick={null}
          setCalling={setCalling}
          setValue={setValue}
          setOpenContactInfo={setOpenContactInfo}
          setEditContactInfo={setPcInfoEdit}
          setContact={setContactItem}
          loading={notifications.loading}
          handleDeleteForever={handleDeleteForever}
          setRecipient={setRecipient}
          setSendEmail={setEmail}
          useGroupFilter={false}
          createItemCallback={contactsTab === "personal" ? createPersonalContactCb : null}
          createItemTooltip={"Create Personal Contact"}
          refreshDataOnSameTab={clearCurrentNotifications}
        />
      </Grid>
    </Grid>
  );
};

Contacts.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      linkId: PropTypes.string,
    })
  ),
  tab: PropTypes.string.isRequired,
  handleDialClick: PropTypes.func.isRequired,
  setCalling: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    phonebook: state.phonebook,
    externalPhonebook: state.externalPhonebook,
    personalPhonebook: state.personalPhonebook,
    notifications: state.notifications,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contacts)
);