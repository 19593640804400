import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { setError } from "../store/features/error/errorSlice";
const mapDispatchToProps = { setError };

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexBasis: "inherit",
    "& button": {
        color: theme.palette.text.primary
    }
  },
  input: {
    display: "none",
  },
}));


const UploadPhotoButton = (props)=>{
    const classes = useStyles();
    const { handlePhoto,setError } = props;
    const fileRef = React.createRef();
    
    const processFile = (file)=>{
      var size = file.size;

      if(size<1000000){
        handlePhoto(file);
      }else{
        setError({
          visible: true,
          type: "error",
          message: "Error: File size must be less than 1mb.",
        }); 
        fileRef.current.value="";
      }
    }

    return (
      <div className={classes.root}>
        <Button startIcon={<CloudUploadIcon aria-label="upload photo" />}>
          <label htmlFor="upload-photo-button">Upload Photo</label>
        </Button>
        <input
          className={classes.input}
          id="upload-photo-button"
          type="file"
          accept="image/png"
          ref={fileRef}
          onChange={(e) => processFile(e.target.files[0])}
        />
      </div>
    );
}

export default connect(null, mapDispatchToProps)(UploadPhotoButton);