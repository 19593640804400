import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import LockIcon from "@material-ui/icons/Lock";
import {
  Grid,
  Button,
  InputAdornment,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "&>div:first-of-type": {
      marginBottom: theme.spacing(4),
    },
    "& button": {
      padding: theme.spacing(1.5),
    },
    "& .MuiInputBase-root": {
      display: "flex",
      alignItems: "start",
      "& .MuiInputAdornment-root": {
        alignItems: "start",
      },
    },
  },
}));

const EmergencySettings = (props) => {
    const classes = useStyles();
    const { user } = props;

    const handleValidation = (values) => {
      const errors = {};

      return errors;
    };

    const handleSubmit = (values, { setSubmitting }) => {
        
    };

    return (
      <Formik
        initialValues={{
          location: user.emergency.location,
        }}
        validate={handleValidation}
        onSubmit={(values, setSubmitting) =>
          handleSubmit(values, setSubmitting)
        }
      >
        {({ submitForm, isSubmitting }) => (
          <Form onSubmit={(e) => e.preventDefault()}>
            <Grid container className={classes.root} spacing={0}>
              <Grid item xs={12}>
                <Field
                  variant="outlined"
                  component={TextField}
                  fullWidth
                  disabled
                  multiline
                  rows={8}
                  name="location"
                  type="textArea"
                  label="Location Notes"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled
                  onClick={submitForm}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
};

EmergencySettings.propTypes = {
  user: PropTypes.object.isRequired,
};

export default EmergencySettings;
