import { createSlice } from "@reduxjs/toolkit";

const NotificationSlice = createSlice({
  name: "notification",
  initialState: {
    loading: false,
    voicemail: [],
    fax: [],
    call: [],
    recording: [],
    conversation: [],
  },
  reducers: {
    setNotifications(state, action) {
      const {call,voicemail,fax,recording,conversation} = action.payload;
      
      return (state = {
        ...state,
        voicemail: voicemail,
        fax: fax,
        call: call,
        recording: recording,
        conversation: conversation,
      });
    },
    setNotificationsLoading(state, action) {
      return {
        ...state,
        loading: action.payload
      }
    },
  },
});

export const { setNotifications,setNotificationsLoading } = NotificationSlice.actions;
export default NotificationSlice.reducer;
