import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CallDialer from '../callDialer'
import {
    Avatar,
    IconButton,
    Toolbar,
    Typography,
    Paper,
} from "@material-ui/core"
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback'
// import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded'
// import VoicemailIcon from '@mui/icons-material/Voicemail'
import PersonIcon from '@mui/icons-material/Person'
import VoicemailIcon from '@mui/icons-material/Voicemail';
import DialpadIcon from '@mui/icons-material/Dialpad';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import AddIcCallIcon from '@mui/icons-material/AddIcCall'
import CallMergeIcon from '@mui/icons-material/CallMerge';
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import CloseIcon from '@mui/icons-material/Close';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import MicIcon from '@mui/icons-material/Mic'
import MicOffIcon from '@mui/icons-material/MicOff'
import CallIcon from '@mui/icons-material/Call'
import CallEndIcon from '@mui/icons-material/CallEnd'
import DotIcon from '@mui/icons-material/FiberManualRecord';
import SquareIcon from '@mui/icons-material/StopSharp';
import { useStyles } from "./styles"
import clsx from "clsx"
import { WebRtcContextJsSIP } from "../../index"
import {
    setStartConference,
    setStartTransfer,
} from "../../store/features/user/userSlice";


const mapDispatchToProps = {
    setStartConference,
    setStartTransfer,
};

const Phone = (props) => {
    const {
        title,
        voip,
        sipCalling,
        calling,
        setSipCalling,
        setCalling,
        sipMode,
        options,
        value,
        setValue,
        viewValue,
        setViewValue,
        controller,
        handleSipDialClick,
        handleDialClick,
        deviceSelection,
        setDeviceSelection,
        selectedIndex,
        setSelectedIndex,
        sipSessions,
        startConference,
        setStartConference,
        startTransfer,
        setStartTransfer,
        history,
    } = props;

    const classes = useStyles();
    const [forceDialPad, setForceDialpad] = useState(false);

    const ringStatus = {
        1: classes.callingRing,
        2: classes.ringingRing,
        3: classes.activeRing,
        4: classes.incomingRing,
        5: classes.onHoldRing,
    }

    const webRtcContextJsSIP = React.useContext(WebRtcContextJsSIP)
    const inConference = webRtcContextJsSIP.getInConference()
    // const inConference = true;

    // let sipSessions = [];
    // let c = phonebook.find((e) => e.voicemail.mailbox === "102")
    // let cN = (c) ? c.firstName + ' ' + c.lastName : "102"
    // sipSessions.push({
    //     remoteNumber: "102",
    //     contact: c ? c : { firstName: "", lastName: "" },
    //     contactName: cN,
    //     sessionStatus: 3
    // })

    // c = phonebook.find((e) => e.voicemail.mailbox === "108")
    // cN = (c) ? c.firstName + ' ' + c.lastName : "108"
    // sipSessions.push({
    //     remoteNumber: "108",
    //     contact: c ? c : { firstName: "", lastName: "" },
    //     contactName: cN,
    //     sessionStatus: 3
    // })

    let activeSession = sipSessions.find((session) => session.sessionStatus <= 3)
    let inactiveSessions = []
    let conferenceSessions = []

    // find one session to set as active
    sipSessions.forEach((session) => {
        if (inConference && (session.sessionStatus === 3 || session.sessionStatus === 5)) {
            conferenceSessions.push(session);
            return
        }

        if (!activeSession) {
            activeSession = session
        }

        if (session !== activeSession || startConference || startTransfer || forceDialPad) {
            inactiveSessions.push(session)
        }
    })

    const dialerInputCb = (input) => {
        if (inConference) {
            conferenceSessions.forEach((session) => webRtcContextJsSIP.sendDTMF(session.remoteNumber, input));
        } else if (activeSession?.sessionStatus === 3) {
            webRtcContextJsSIP.sendDTMF(activeSession.remoteNumber, input)
        }
    }

    const dtmfDialer = activeSession && activeSession.sessionStatus === 3

    console.log("activeSession: " + (activeSession ? 1 : 0))
    console.log("inactiveSessions: " + inactiveSessions.length)
    console.log("conferenceSessions: " + conferenceSessions.length)

    useEffect(() => {
        // eslint-disable-next-line
    }, [])

    const handleHangUp = (number) => {
        if (startConference === number) {
            setStartConference(null)
        }
        if (startTransfer === number) {
            setStartTransfer(null)
        }

        webRtcContextJsSIP.hangupSession(number)
    }

    const handleAcceptCall = (number) => {
        webRtcContextJsSIP.answerCall(number)
    }

    const toggleHoldCall = (number) => {
        webRtcContextJsSIP.toggleHoldSession(number)
    }

    const toggleRecordCall = (number) => {
        webRtcContextJsSIP.recordSession(number)
    }

    const toggleMuteCall = (number) => {
        webRtcContextJsSIP.toggleMuteSession(number)
    }

    const mergeAllCalls = () => {
        webRtcContextJsSIP.mergeSessions()
    }

    const enableForceDialPad = () => {
        setForceDialpad(true);
    }

    const disableForceDialPad = () => {
        setForceDialpad(false);
    }

    const renderInactive = (item) => {
        console.log("renderInactive")
        return (
            <div key={item.remoteNumber} className={classes.inactiveSession}>
                <div className={classes.verticalAvatar}>
                    {activeSession.contact
                        ? activeSession.contact.profileImage
                            ? <img className={[classes.contactPhoto, ringStatus[item.sessionStatus]]} src={activeSession.contact.profileImage} alt="sms" />
                            : <Avatar className={[classes.avatar, ringStatus[item.sessionStatus]]} src={null}> {`${activeSession.contact.firstName.charAt(0)}${activeSession.contact.lastName.charAt(0)}`} </Avatar>
                        : item.remoteNumber === 800 || item.remoteNumber === 801
                            ? <PersonIcon className={[classes.noContactPhoto, ringStatus[item.sessionStatus]]} />
                            : <VoicemailIcon className={[classes.noContactPhoto, ringStatus[item.sessionStatus]]} />
                    }
                </div>
                <div className={classes.verticalInfo}>
                    <div className={classes.verticalSessionStatus}>
                        {item.sessionStatus <= 2 ? <CallIcon className={classes.sessionStatusIcon} /> :
                            item.sessionStatus === 3 ? <CallIcon className={classes.sessionStatusIcon} /> :
                                item.sessionStatus === 4 ? <PhoneCallbackIcon className={classes.sessionStatusIcon} /> :
                                    item.sessionStatus === 5 ? <PauseIcon className={classes.sessionStatusIcon} /> : ""}
                        {item.sessionStatus <= 1 ? "Calling" :
                            item.sessionStatus === 2 ? "Ringing" :
                                item.sessionStatus === 3 ? "Active Call" :
                                    item.sessionStatus === 4 ? "Incoming Call" :
                                        item.sessionStatus === 5 ? "On Hold" : ""}
                    </div>
                    <div className={classes.verticalName}>
                        {item.contactName}
                    </div>
                    <div className={classes.verticalNumber}>
                        {item.remoteNumber}
                    </div>
                </div>
                <div className={classes.verticalActions}>
                    {startConference &&
                        <IconButton
                            aria-label="cancelMerge"
                            className={classes.iconButton}
                            onClick={() => { cancelMergeCall() }}
                        >
                            <CloseIcon />
                        </IconButton>
                    }
                    {startTransfer &&
                        <IconButton
                            aria-label="cancelTransfer"
                            className={classes.iconButton}
                            onClick={() => { cancelTransferCall() }}
                        >
                            <CloseIcon />
                        </IconButton>
                    }
                    {forceDialPad && item.sessionStatus === 3 &&
                        <IconButton
                            aria-label="forceDialPadOff"
                            className={classes.iconButton}
                            onClick={() => { disableForceDialPad() }}
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                    }
                    {/* <IconButton
                        aria-label="transfer"
                        className={classes.iconButton}
                        onClick={() => { }}
                    >
                        <PhoneForwardedIcon />
                    </IconButton>
                    <IconButton
                        aria-label="voicemail"
                        className={classes.iconButton}
                        onClick={() => { }}
                    >
                        <VoicemailIcon />
                    </IconButton>
                    <IconButton
                        aria-label="more"
                        className={classes.iconButton}
                        onClick={() => { }}
                    >
                        <MoreHorizIcon />
                    </IconButton> */}
                    {
                        (item.sessionStatus === 3 || item.sessionStatus === 5) &&
                        <IconButton
                            aria-label="hold"
                            className={classes.iconButton}
                            onClick={() => { toggleHoldCall(item.remoteNumber) }}
                        >
                            {item.sessionStatus === 5
                                ? <PlayArrowIcon />
                                : <PauseIcon />}
                        </IconButton>
                    }
                    {
                        !startTransfer && !startConference && item.sessionStatus === 5 && sipSessions.length > 1 &&
                        <IconButton
                            aria-label="mergeCall"
                            className={classes.iconButton}
                            onClick={() => { mergeAllCalls() }}
                        >
                            <CallMergeIcon />
                        </IconButton>
                    }
                </div>
                <div className={classes.verticalCallButtons}>
                    {item.sessionStatus === 4 && <IconButton
                        aria-label="answer"
                        className={classes.callIcon}
                        onClick={() => { handleAcceptCall(item.remoteNumber) }}
                    >
                        <CallIcon />
                    </IconButton>}
                    <IconButton
                        aria-label="hangup"
                        className={classes.callEndIcon}
                        onClick={() => handleHangUp(item.remoteNumber)}
                    >
                        <CallEndIcon />
                    </IconButton>
                </div>
            </div>
        )
    }

    const renderActive = () => {
        console.log("renderActive")
        return (<div key={activeSession.remoteNumber} className={classes.activeSession}>
            <div className={classes.centralAvatar}>
                {activeSession.contact
                    ? activeSession.contact.profileImage
                        ? <img className={[classes.centralContactPhoto, ringStatus[activeSession.sessionStatus]]} src={activeSession.contact.profileImage} alt="sms" />
                        : <Avatar className={[classes.centralAvatar, ringStatus[activeSession.sessionStatus]]} src={null}> {`${activeSession.contact.firstName.charAt(0)}${activeSession.contact.lastName.charAt(0)}`} </Avatar>
                    : activeSession.remoteNumber === 800 || activeSession.remoteNumber === 801
                        ? <PersonIcon className={[classes.centralNoContactPhoto, ringStatus[activeSession.sessionStatus]]} />
                        : <VoicemailIcon className={[classes.centralNoContactPhoto, ringStatus[activeSession.sessionStatus]]} />
                }

            </div>
            <div className={classes.centralInfo}>
                <div className={classes.centralSessionStatus}>
                    {activeSession.sessionStatus <= 2 ? <CallIcon className={classes.sessionStatusIcon} /> :
                        activeSession.sessionStatus === 3 ? <CallIcon className={classes.sessionStatusIcon} /> :
                            activeSession.sessionStatus === 4 ? <PhoneCallbackIcon className={classes.sessionStatusIcon} /> :
                                activeSession.sessionStatus === 5 ? <PauseIcon className={classes.sessionStatusIcon} /> : ""}
                    {activeSession.sessionStatus <= 1 ? "Calling" :
                        activeSession.sessionStatus === 2 ? "Ringing" :
                            activeSession.sessionStatus === 3 ? "Active Call" :
                                activeSession.sessionStatus === 4 ? "Incoming Call" :
                                    activeSession.sessionStatus === 5 ? "On Hold" : ""}
                </div>
                <div className={classes.centralName}>
                    {activeSession.contactName}
                </div>
                <div className={classes.centralNumber}>
                    {activeSession.remoteNumber}
                </div>
            </div>
            <div className={classes.centralActions}>
                {activeSession.sessionStatus === 3 &&
                    <IconButton
                        aria-label="add"
                        className={classes.iconButton}
                        disabled={activeSession.recording || sipSessions.length >= 2}
                        onClick={() => { initiateMergeCall(activeSession.remoteNumber) }}
                    >
                        <AddIcCallIcon />
                    </IconButton>}
                {activeSession.sessionStatus === 3 &&
                    <IconButton
                        aria-label="tranfer"
                        className={classes.iconButton}
                        disabled={activeSession.recording || sipSessions.length >= 2}
                        onClick={() => { initiateTransferCall(activeSession.remoteNumber) }}
                    >
                        <PhoneForwardedIcon />
                    </IconButton>}
                {(activeSession.sessionStatus === 3 || activeSession.sessionStatus === 5) &&
                    <IconButton
                        aria-label="hold"
                        className={classes.iconButton}
                        disabled={activeSession.recording}
                        onClick={() => { toggleHoldCall(activeSession.remoteNumber) }}
                    >
                        {activeSession.sessionStatus === 5
                            ? <PlayArrowIcon />
                            : <PauseIcon />}
                    </IconButton>}
                {(activeSession.sessionStatus === 3 || activeSession.sessionStatus === 5) &&
                    <IconButton
                        aria-label="dialpad"
                        className={classes.iconButton}
                        onClick={() => { enableForceDialPad() }}
                    >
                        <DialpadIcon />
                    </IconButton>}
                {(activeSession.sessionStatus === 3 || activeSession.sessionStatus === 5) &&
                    <IconButton
                        aria-label="mute"
                        className={classes.iconButton}
                        onClick={() => { toggleMuteCall(activeSession.remoteNumber) }}
                        disabled={activeSession.sessionStatus !== 3}
                    >
                        {!activeSession.muted
                            ? <MicIcon />
                            : <MicOffIcon />}
                    </IconButton>}
                {(activeSession.sessionStatus === 3) &&
                    <IconButton
                        aria-label="record"
                        className={activeSession.recording
                            ? classes.iconButtonRed
                            : classes.iconButtonMedium}
                        onClick={() => { toggleRecordCall(activeSession.remoteNumber) }}
                    >
                        {activeSession.recording
                            ? <SquareIcon />
                            : <DotIcon />}
                    </IconButton>}
                {/* <IconButton
                aria-label="transfer"
                className={classes.iconButton}
                onClick={() => { }}
            >
                <PhoneForwardedIcon />
            </IconButton>
            <IconButton
                aria-label="more"
                className={classes.iconButton}
                onClick={() => { }}
            >
                <MoreHorizIcon />
            </IconButton> */}
            </div>
            <div className={classes.centralCallButtons}>
                {activeSession.sessionStatus === 4 && <IconButton
                    aria-label="accept"
                    className={classes.callIconLarge}
                    onClick={() => { handleAcceptCall(activeSession.remoteNumber) }}
                >
                    <CallIcon />
                </IconButton>}
                <IconButton
                    aria-label="end"
                    className={classes.callEndIconLarge}
                    onClick={() => handleHangUp(activeSession.remoteNumber)}
                >
                    <CallEndIcon />
                </IconButton>
            </div>
        </div>
        )
    }

    const renderConference = () => {
        console.log("renderConference")
        return (<div className={classes.activeSession}>
            <div className={classes.centralAvatar}>
                {conferenceSessions.map((item) => {
                    return item.contact
                        ? item.contact.profileImage
                            ? <img key={item} className={[classes.centralContactPhoto, classes.marginRight, ringStatus[conferenceSessions[0].sessionStatus]]} src={item.contact.profileImage} alt="sms" />
                            : <Avatar key={item} className={[classes.centralAvatar, classes.marginRight, ringStatus[conferenceSessions[0].sessionStatus]]} src={null}> {`${item.contact.firstName.charAt(0)}${item.contact.lastName.charAt(0)}`} </Avatar>
                        : <PersonIcon key={item} className={[classes.centralNoContactPhoto, classes.marginRight, ringStatus[conferenceSessions[0].sessionStatus]]} />
                })}
            </div>
            <div className={classes.centralInfo}>
                <div className={classes.centralSessionStatus}>
                    {conferenceSessions[0].sessionStatus <= 2 ? <CallIcon className={classes.sessionStatusIcon} /> :
                        conferenceSessions[0].sessionStatus === 3 ? <CallIcon className={classes.sessionStatusIcon} /> :
                            conferenceSessions[0].sessionStatus === 4 ? <PhoneCallbackIcon className={classes.sessionStatusIcon} /> :
                                conferenceSessions[0].sessionStatus === 5 ? <PauseIcon className={classes.sessionStatusIcon} /> : ""}
                    {conferenceSessions[0].sessionStatus <= 1 ? "Calling" :
                        conferenceSessions[0].sessionStatus === 2 ? "Ringing" :
                            conferenceSessions[0].sessionStatus === 3 ? "Active Call" :
                                conferenceSessions[0].sessionStatus === 4 ? "Incoming Call" :
                                    conferenceSessions[0].sessionStatus === 5 ? "On Hold" : ""}
                </div>
                <div className={classes.centralName}>
                    {conferenceSessions.map((item) => {
                        return <div key={item} className={classes.marginRight}>{`${item.contact.firstName.charAt(0)}. ${item.contact.lastName}`}</div>
                    })}
                </div>
                <div className={classes.centralNumber}>
                    {conferenceSessions.map((item) => {
                        return <div key={item} className={classes.marginRight}>{item.remoteNumber}</div>
                    })}
                </div>
            </div>
            <div className={classes.centralActions}>
                {/* {conferenceSessions[0].sessionStatus === 3 &&
                    <IconButton
                        aria-label="add"
                        className={classes.iconButton}
                        onClick={() => { initiateMergeCall(conferenceSessions[0].remoteNumber) } }
                    >
                        <AddIcCallIcon />
                    </IconButton>} */}
                {(conferenceSessions[0].sessionStatus === 3 || conferenceSessions[0].sessionStatus === 5) &&
                    <IconButton
                        aria-label="hold"
                        className={classes.iconButton}
                        onClick={() => { toggleHoldCall(conferenceSessions[0].remoteNumber) }}
                    >
                        {conferenceSessions[0].sessionStatus === 5
                            ? <PlayArrowIcon />
                            : <PauseIcon />}
                    </IconButton>}
                {(conferenceSessions[0].sessionStatus === 3 || conferenceSessions[0].sessionStatus === 5) &&
                    <IconButton
                        aria-label="mute"
                        className={classes.iconButton}
                        onClick={() => { toggleMuteCall(conferenceSessions[0].remoteNumber) }}
                        disabled={conferenceSessions[0].sessionStatus !== 3}
                    >
                        {!conferenceSessions[0].muted
                            ? <MicIcon />
                            : <MicOffIcon />}
                    </IconButton>}
                {/* <IconButton
                aria-label="transfer"
                className={classes.iconButton}
                onClick={() => { }}
            >
                <PhoneForwardedIcon />
            </IconButton>
            <IconButton
                aria-label="more"
                className={classes.iconButton}
                onClick={() => { }}
            >
                <MoreHorizIcon />
            </IconButton> */}
            </div>
            <div className={classes.centralCallButtons}>
                {conferenceSessions[0].sessionStatus === 4 && <IconButton
                    aria-label="accept"
                    className={classes.callIcon}
                    onClick={() => { handleAcceptCall(conferenceSessions[0].remoteNumber) }}
                >
                    <CallIcon />
                </IconButton>}
                <IconButton
                    aria-label="end"
                    className={classes.callEndIcon}
                    onClick={() => handleHangUp(conferenceSessions[0].remoteNumber)}
                >
                    <CallEndIcon />
                </IconButton>
            </div>
        </div>
        )
    }

    const initiateMergeCall = (number) => {
        toggleHoldCall(activeSession.remoteNumber)
        setStartConference(number)
    }

    const cancelMergeCall = () => {
        toggleHoldCall(activeSession.remoteNumber)
        setStartConference(null)
    }

    const initiateTransferCall = (number) => {
        toggleHoldCall(activeSession.remoteNumber)
        setStartTransfer(number)
    }

    const cancelTransferCall = () => {
        toggleHoldCall(activeSession.remoteNumber)
        setStartTransfer(null)
    }

    const renderCallSession = () => {
        return (
            <div className={((activeSession && !startConference && !startTransfer && !forceDialPad) ||
                inConference)
                ? classes.sessionContainerMax
                : classes.sessionContainer}>
                {
                    inactiveSessions.map((item) => (
                        renderInactive(item)
                    ))
                }
                {
                    ((activeSession && !startConference && !startTransfer && !inConference && !forceDialPad) &&
                        renderActive()
                    )
                }
                {
                    ((!startConference && !startTransfer && inConference) &&
                        renderConference()
                    )
                }
            </div>
        )
    }

    const handleCallerCallClick = () => {
        disableForceDialPad()
        if (voip.active === 'yes') {
            handleSipDialClick()
        } else {
            handleDialClick()
        }
    }

    const renderCallDialer = (
        voip,
        sipCalling,
        calling,
        setSipCalling,
        setCalling,
        sipMode,
        options,
        value,
        setValue,
        viewValue,
        setViewValue,
        controller,
        deviceSelection,
        setDeviceSelection,
        selectedIndex,
        setSelectedIndex,
        history,
    ) => {
        return <CallDialer
            calling={voip.active === 'yes' ? sipCalling : calling}
            setCalling={voip.active === 'yes' ? setSipCalling : setCalling}
            mode={voip.active === 'yes' ? sipMode : "newCall"}
            options={options}
            value={value}
            setValue={setValue}
            viewValue={viewValue}
            setViewValue={setViewValue}
            controller={controller}
            handleDialClick={handleCallerCallClick}
            deviceSelection={deviceSelection}
            setDeviceSelection={setDeviceSelection}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            dialerInputCb={dialerInputCb}
            dtmfDialer={dtmfDialer}
            history={history}
            transferCall={startTransfer}
        />;
    }

    return (
        <Paper className={classes.paper}>
            <Toolbar
                className={clsx(classes.toolbarRoot, classes.colorDefault)}
            >
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {title}
                </Typography>
            </Toolbar>
            <div className={classes.main}>
                {renderCallSession()}
                {/* {!activeSession && inactiveSessions.length !== 0 && renderCallSession()} */}
                {((!activeSession && conferenceSessions.length === 0) || startConference || startTransfer || forceDialPad) &&
                    renderCallDialer(voip, sipCalling, calling, setSipCalling, setCalling,
                        sipMode, options, value, setValue, viewValue, setViewValue,
                        controller, handleSipDialClick, handleDialClick,
                        deviceSelection, setDeviceSelection,
                        selectedIndex, setSelectedIndex, history)}
            </div>
        </Paper>
    );
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
        user: state.user,
        phonebook: state.phonebook,
        startConference: state.user.startConference,
        startTransfer: state.user.startTransfer,
        sipSessions: state.user.sipSessions,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Phone);
