import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 990,
  },
  button: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      position: "fixed",
      width: "3rem",
      height: "3rem",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      backgroundColor: "white",
    },
  },
  icon: {
    fontSize: "3rem",
    width: "3rem",
    height: "3rem",
    color: theme.palette.primary.light,
  },
}));

export default function HelpMenu() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fab aria-label="support" className={classes.button}>
        <HelpIcon className={classes.icon} />
      </Fab>
    </div>
  );
}