import React,{useEffect,useState,useRef} from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import {
  withRouter,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom"
import {
  resetSession,
  createSession,
  checkLocalSession,
} from "../store/features/session/sessionSlice"
import {
  setNotifications,
  setNotificationsLoading,
} from "../store/features/notification/notificationSlice"
import { setPhonebook } from "../store/features/phonebook/phonebookSlice"
import { setExternalPhonebook } from "../store/features/phonebook/externalPhonebookSlice"
import { setPersonalPhonebook } from "../store/features/phonebook/personalPhonebookSlice"
import { setCalls } from "../store/features/calls/callSlice"
import { setVoicemails } from "../store/features/voicemails/voicemailSlice"
import { setSmsConversations } from "../store/features/smsConversations/smsConversationsSlice"
import { setFaxes } from "../store/features/faxes/faxSlice"
import { setRecordings } from "../store/features/recordings/recordingSlice"
import { setConferences } from "../store/features/conferences/conferenceSlice"
import MCLAppBar from "../components/appbar"
import UserProfile from "./profile"
import Notifications from "./notifications"
import {
  getCallJournal,
  getFaxes,
  getRecordings,
  getVoicemails,
  getSmsConversations,
  getPhonebook,
  getExternalPhonebook,
  getPersonalPhonebook,
  getConferences,
} from "../utils/utils"
import CallView from "./callView"
// import JournalView from "./journalView"
import VoicemailView from "./voicemailView"
import ContactsView from "./contactsView"
import ConferenceView from "./conferenceView"
import AppSettingsView from "./appSettingsView"
import AdminView from "./adminView"
import FaxView from "./faxView"
import SmsView from "./smsView"
// import CloudView from "./cloudView"
import { WebRtcContextJsSIP } from "../index"
// import DialerStatusGlobal from "../components/dialerStatusGlobal"

const mapDispatchToProps = {
  setPhonebook,
  setExternalPhonebook,
  setPersonalPhonebook,
  resetSession,
  checkLocalSession,
  createSession,
  setCalls,
  setVoicemails,
  setSmsConversations,
  setFaxes,
  setRecordings,
  setConferences,
  setNotifications,
  setNotificationsLoading,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontSize: "1rem",
  },
  main: {
    flexGrow: 1,
    maxWidth: "100vw",
    maxHeight: "100vh",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
      height: "100vh",
    },
  },
  hidden: {
    overflow: "hidden",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));

// Catch the window unload to handle properly page refresh
const useWindowUnloadEffect = (handler, callOnCleanup) => {
  const cb = useRef()
  cb.current = handler

  useEffect(() => {
    const handler = () => cb.current()
    window.addEventListener('beforeunload', handler)

    return () => {
      if (callOnCleanup) {
        handler()
      }
      window.removeEventListener('beforeunload', handler)
    }
  }, [callOnCleanup])
}

const Dashboard = (props)=> {
  const classes = useStyles();
  let { path } = useRouteMatch();
  let count=0;
  const {
    setPhonebook,
    setExternalPhonebook,
    setPersonalPhonebook,
    session,
    lastServer,
    setCalls,
    setVoicemails,
    setSmsConversations,
    setFaxes,
    setRecordings,
    setConferences,
    resetSession,
    history,
    setNotifications,
    setNotificationsLoading,
    profileImage,
    voip,
    voipX,
    location,
    services,
  } = props;
  // eslint-disable-next-line
  const [imageHash, setImageHash] = useState(Date.now());
  const [photo, setPhoto] = useState("");

  const parseNewItems = (data = []) => {
    return data.filter((item) => item.viewed === "no");
  };

  const parseUnreadMessages = (data = []) => {
    return data.filter((item) => item.unreadMessages !== 0);
  }

  const webRtcContextJsSIP = React.useContext(WebRtcContextJsSIP);
  if (voip.active === 'yes' && voipX ) {
    webRtcContextJsSIP.startSipStack( voip.private_id, 
                                      voip.public_id, 
                                      voip.contact_uri, 
                                      voip.display_name, 
                                      voip.registrar_server,
                                      voip.wss, 
                                      voipX );
  }

  useWindowUnloadEffect(() => webRtcContextJsSIP.stopSipStack(), true)

  useEffect(()=>{
    if(session.loggedIn)
      setPhoto(
        `https://${session.lastServer}3.my-cloudline.net:9003/${profileImage}?${imageHash}`
      );
    // eslint-disable-next-line
  },[session,profileImage]);

  useEffect(() => {
    if(session.lastServer!==""  && count===0){
      const values = {
        server: lastServer,
        token: session.token,
      };

      let fetchCalls = getCallJournal(values, setCalls);
      let fetchFaxes = getFaxes(values, setFaxes);
      let fetchRecordings = getRecordings(values, setRecordings);
      let fetchVoicemails = getVoicemails(values, setVoicemails);
      let fetchSmsConversations = getSmsConversations(values, setSmsConversations);
      let fetchConferences = getConferences(values, setConferences);
      let fetchPhonebook = getPhonebook(values, setPhonebook);
      let fetchExternalPhonebook = getExternalPhonebook(values, setExternalPhonebook);
      let fetchPersonalPhonebook = getPersonalPhonebook(values, setPersonalPhonebook);

      setNotificationsLoading(true);

      Promise.all([
        fetchCalls,
        fetchFaxes,
        fetchRecordings,
        fetchVoicemails,
        fetchSmsConversations,
        fetchConferences,
        fetchPhonebook,
        fetchExternalPhonebook,
        fetchPersonalPhonebook,
      ])
        .then((res) => {
          for (var i = 0; i < res.length; i++) {
            if (res[i] instanceof Error && res[i].message === "Unauthorized") {
              webRtcContextJsSIP.stopSipStack();
              resetSession();
              history.push('/login');
              return;
            }
          }

          setNotifications({
            call: parseNewItems(res[0].journalEntries),
            fax: parseNewItems(res[1].faxDocuments),
            recording: parseNewItems(res[2].callRecordings),
            voicemail: parseNewItems(res[3].voicemailRecordings),
            conversation: parseUnreadMessages(res[4].Conversations),
          });

          setNotificationsLoading(false);
        })
        .catch((err) => {
          setNotificationsLoading(false);
          console.error(err);
        });
    }
    // eslint-disable-next-line
  },[]);

  const handleLogout = ()=>{
    webRtcContextJsSIP.stopSipStack();
    resetSession();
    history.push('/login');
  }

  const pathSegment = location.pathname.split("/")[2];
  const menuItems = [
    "call", "voice", "fax", "contacts", "notifications", "conference"
  ];

  return (
    <div className={classes.root}>
      {/* <DialerStatusGlobal /> */}
      <MCLAppBar logoutFn={handleLogout} userPhoto={photo} />
      <main
        className={
          menuItems.some(item => pathSegment && pathSegment.includes(item))
          ? clsx(classes.main, classes.hidden)
          : classes.main
        }
      >
        <div className={classes.toolbar}></div>
        <Switch history={history}>
          <Route path={`${path}/user/profile`}>
            <UserProfile />
          </Route>
          <Route path={`${path}/notifications/:type`}>
            <Notifications />
          </Route>
          {services.call && services.call === "yes" && (
            <Route path={`${path}/call/:type`}>
              <CallView />
            </Route>
          )}
          {services.voicemail && services.voicemail === "yes" && (
            <Route path={`${path}/voice/:type`}>
              <VoicemailView />
            </Route>
          )}
          {services.fax && services.fax === "yes" && (
            <Route path={`${path}/fax/:type`}>
              <FaxView />
            </Route>  
          )}
          {services.contacts && services.contacts === "yes" && (
            <Route path={`${path}/contacts/:type`}>
              <ContactsView />
            </Route>
          )}
          {services.conference && services.conference === "yes" && (
            <Route path={`${path}/conference/:type`}>
              <ConferenceView />
            </Route>
          )}
          {services.sms && services.sms === "yes" && (
            <Route path={`${path}/sms/:type`}>
              <SmsView />
            </Route>
          )}
          {services.admin && services.admin === "no" && (
            <Route path={`${path}/admin/:type`}>
              <AdminView />
            </Route>
          )}
          {/* <Route path={`${path}/cloud`}>
            <CloudView />
          </Route> */}
          <Route path={`${path}/settings`}>
            <AppSettingsView />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profileImage: state.user.profileImage,
    calls: state.calls,
    voicemails: state.voicemails,
    faxes: state.faxes,
    recordings: state.recordings,
    conferences: state.conferences,
    session: state.session,
    lastServer: state.session.lastServer,
    notifications: state.notifications,
    voip: state.user.voip,
    voipX: state.session.voipX,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    services: state.user.webapp.services,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
