import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
const drawerSmsWidth = 300;

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      height: `100%`,
      maxHeight: `calc(100vh - 56px)`,
      [theme.breakpoints.up("xs")]: {
        maxHeight: `calc(100vh - 48px)`,
      },
      [theme.breakpoints.up("sm")]: {
        maxHeight: `calc(100vh - 64px)`,
      },
      "& ul": {
        paddingTop: 0,
      },
      "& .MuiTypography-body1": {
        color: theme.palette.text.primary,
      },
    },
    drawer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      height: "100%",
      "&>div": {
        height: "100%",
      },
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        maxWidth: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerSms: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      height: "100%",
      "&>div": {
        height: "100%",
      },
      [theme.breakpoints.up("md")]: {
        width: drawerSmsWidth,
        maxWidth: drawerSmsWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      top: "50%",
      position: "absolute",
      zIndex: 992,
    },
    menuClosed: {
      left: "15rem",
      visibility: "visible",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    menuSmsClosed: {
      left: "18.5rem",
      visibility: "visible",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    listItems: {
      fontSize: "18px",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.016em"
    },
    menuOpen: {
      visibility: "visible",
      marginLeft: drawerWidth,
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    menuSmsOpen: {
      visibility: "visible",
      marginLeft: drawerSmsWidth,
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    menuButtonIcon: {
      border: `1px solid ${theme.palette.text.secondary}`,
      marginLeft: "-1px",
      color: theme.palette.text.secondary,
      zIndex: 992,
      width: "20px",
      background: theme.palette.primary.main,
      borderRadius: "0 50% 50% 0",
    },
    h100: {
      height: "100%",
    },
    listRoot: {
      paddingTop: 0,
      paddingBottom: 0,
  
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      display: "grid",
      gridTemplateRows: "2fr",
      height: "100%",
      position: "relative",
      overflow: "visible",
      width: drawerWidth,
      [theme.breakpoints.up("md")]: {
        overflow: "hidden",
      },
    },
    drawerSmsPaper: {
      display: "grid",
      gridTemplateRows: "2fr",
      height: "100%",
      position: "relative",
      overflow: "visible",
      width: drawerSmsWidth,
      [theme.breakpoints.up("md")]: {
        overflow: "hidden",
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    showChevron: {
      display: "block",
    },
    hideChevron: {
      display: "none",
    },
    selected: {
      color: "white !important",
      background: theme.palette.primary.light,
      "&:hover": {
        background: theme.palette.primary.light,
      },
      "& span": {
        color: "inherit",
      },
    },
    callContactButton: {
      width: "3rem",
      height: "3rem",
      borderRadius: "50%",
      // color: theme.palette.primary.contrastText,
      background: '#f3f3f3',
    },
    grow: {
      flexGrow: 1,
    },
    sectionDesktop: {
      display: "flex",
      "&>button:first-of-type": {
        marginRight: theme.spacing(1),
      },
    },
    smsMessagesContainer: {
        position: 'relative',
        overflowY: 'auto',
        height: 'calc(100% - 70px)',
        WebkitOverflowScrolling: 'touch',
        background: theme.palette.background.paper,
        display: 'flex',
        flex: '1 1 auto',
    }
  }));

  export {useStyles}