import React,{useState} from "react";
import { connect } from "react-redux";
import { makeStyles,darken } from "@material-ui/core/styles";
import {
  Modal,
  Fade,
  Backdrop,
  TextField,
  Button,
  Grid,
  Switch,
  FormControlLabel
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { setError } from "../store/features/error/errorSlice";
import { reserveConference } from "../utils/utils";

const mapDispatchToProps = {
  setError,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      fontSize: "1.125rem",
      marginTop: 0,
      fontWeight: 200,
    },
    "& .MuiSwitch-track, & .MuiSwitch-colorPrimary": {
      color: theme.palette.primary.light
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.primary.light
    }
  },
  heading: {
    marginTop: 0,
  },
  grid: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
  },
  primary: {
    background: theme.palette.primary.light,
    color: "white",
    marginLeft: theme.spacing(4),
    "&:hover": {
      background: darken(theme.palette.primary.light, 0.25),
    },
  },
  paper: {
    position: "relative",
    minWidth: "500px",
    minHeight: "250px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    border: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4),
    borderRadius: "4px",
  },
  buttonFooter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  rightColumn: {
    justifyContent: "flex-end",
  },
  leftColumn: {
    color: theme.palette.text.softer,
    flexGrow: 1,
    justifyContent: "flex-start",
  },
  close: {
    position: "absolute",
    right: "-.65em",
    top: "-.65em",
    background: "black",
    color: "white",
    "&:hover": {
      background: "black",
    },
  },
  cancel: {
    background: theme.palette.text.secondary,
    color: "white",
    "&:hover": {
      background: darken(theme.palette.text.secondary,.25),
    },
  },
}));

const CreateConference = (props) => {
    const classes = useStyles();
    const { open, setOpen, setError, defaultValues,reloadNotifications } = props;
    const [conferenceName, setConferenceName] = useState("");
    const [statusPrivate, setStatusPrivate] = useState(false);

    const handleCreateConference = async () => {
      let values = defaultValues;
      try {
        values.conferenceData = {
          name: conferenceName,
          type: statusPrivate?"private":"public",
        };

        let res = await reserveConference(values);

        if (res instanceof Error) throw res;

        setError({
          visible: true,
          type: "success",
          message: `Conference successfully created.`,
        });
        reloadNotifications();
        setOpen(false);
      } catch (error) {
        const {name,message} = error;
        setError({
          visible: true,
          type: "error",
          message:
            message.toLowerCase() === "not found"
              ? "Error: Couldn't Reserve Conference"
              : `${name}: ${message}`,
        });
      }
      
    }

    return (
      <Modal
        aria-labelledby="create-conference-modal"
        aria-describedby={"modal to create a conference"}
        className={classes.modal}
        open={open}
        onClose={(e) => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <IconButton
              aria-label="close"
              className={classes.close}
              size="small"
              onClick={(e) => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <h2 className={classes.heading}>Create a Conference</h2>
            <p>Please tell us what you would like to call this conference.</p>
            <div className={classes.grid}>
              <TextField
                required
                id="conference-name"
                label="Conference Name"
                value={conferenceName}
                onChange={(e) => setConferenceName(e.target.value)}
                variant="outlined"
                fullWidth
              />
            </div>

            <Grid container className={classes.buttonFooter}>
              <Grid item className={classes.leftColumn}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={statusPrivate}
                      onChange={()=>setStatusPrivate(!statusPrivate)}
                      name="conference-type"
                    />
                  }
                  label={statusPrivate?"Private":"Public"}
                />
              </Grid>
              <Grid item className={classes.rightColumn}>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={(e) => setOpen(false)}
                  className={classes.cancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  disabled={conferenceName.length === 0}
                  className={classes.primary}
                  onClick={handleCreateConference}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    );
}

export default connect(null, mapDispatchToProps)(CreateConference);