import React,{useState} from "react";
import { makeStyles,darken } from "@material-ui/core/styles";
import { Modal,Fade,Backdrop } from "@material-ui/core";
import {Button,Grid} from "@material-ui/core";
import { setError } from "../store/features/error/errorSlice";
import { connect } from "react-redux";
import AutoCompleteSelect from "./autocomplete";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const mapDispatchToProps = {
  setError,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      fontSize: "1.125rem",
      marginTop: 0,
      fontWeight: 200,
    },
  },
  autocomplete: {
    width: "100%",
  },
  heading: {
    marginTop: 0,
  },
  grid: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
  },
  primary: {
    background: theme.palette.primary.light,
    color: "white",
    marginLeft: theme.spacing(4),
    "&:hover": {
      background: darken(theme.palette.primary.light, 0.25),
    },
  },
  paper: {
    position: "relative",
    minWidth: "500px",
    minHeight: "250px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    border: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4),
    borderRadius: "4px",
  },
  buttonFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  close: {
    position: "absolute",
    right: "-.65em",
    top: "-.65em",
    background: "black",
    color: "white",
    "&:hover": {
      background: "black",
    },
  },
  cancel: {
    background: theme.palette.text.secondary,
    color: "white",
    "&:hover": {
      background: darken(theme.palette.text.secondary,.25),
    },
  },
}));

const Forward = (props) => {
    const classes = useStyles();
    const { open, setOpen, value, setError, type, phonebook, forwardFunction, idKey, groupKey, defaultValues } = props;
    const [ recipient, setRecipient ] = useState([]);
    
    const selectOptions = phonebook.map((option) => {
      const firstLetter = option.firstName[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    });

    const clearRecipients = () => setRecipient([])

    const handleForward = async()=>{
      if(recipient!==null){
        let queue = defaultValues;
        queue[type.toLowerCase()] = recipient.map((item) => ({
          username: item.preferredDeviceNumbers.office,
        }));

        try{
          let resp = groupKey
          ? await forwardFunction(queue,value[idKey],value[groupKey])
          : await forwardFunction(queue,value[idKey])
          
          if (resp instanceof Error) throw resp.message;

          setError({
            visible: true,
            type: "success",
            message: `${type} successfully forwarded.`,
          });
          setOpen(false)
        } catch(error){
          setError({
            visible: true,
            type: "error",
            message: `Error: ${error}`,
          });
        } finally {
          clearRecipients()
        }
      }
    }

    return (
      <Modal
        aria-labelledby="forward-modal"
        aria-describedby="modal to forward"
        className={classes.modal}
        open={open}
        onClose={(e) => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <IconButton
              aria-label="close"
              className={classes.close}
              size="small"
              onClick={(e) => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <h2 className={classes.heading}>Forward {type}</h2>
            <p>Please select who you would like to forward the {type} to.</p>
            <Grid container className={classes.grid}>
              <Grid item xs={12}>
                <AutoCompleteSelect
                  value={recipient}
                  setValue={setRecipient}
                  options={selectOptions}
                  placeholder="Recipient(s)"
                  className={classes.autocomplete}
                  multiple
                />
              </Grid>
            </Grid>

            <div className={classes.buttonFooter}>
              <Button
                variant="contained"
                size="medium"
                onClick={(e) => {
                  setOpen(false); 
                  clearRecipients();
                }}
                className={classes.cancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="medium"
                className={classes.primary}
                onClick={handleForward}
              >
                Forward
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    );
}

const mapStateToProps = (state) => {
  return {
    phonebook: state.phonebook,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Forward);