import { makeStyles } from '@material-ui/core/styles';
import { StyleSheet } from 'react-native';

const useNativeStyles = (theme) => {
    return StyleSheet.create({
    trimmedMessage: {
        fontSize: 14,
        color: theme.palette.text.secondary,
        margin: 0,
        textTransform: 'none',
        textAlign: 'left',
        wordBreak: 'break-all',
        fontWeight: 400,
    }
})};

const useStyles = makeStyles((theme) => ({
    smsContactsListItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4 0 4 4',
        '&:hover': {
            background: theme.palette.action.focus,
            cursor: 'pointer',
        },
    },
    smsContactsListItemSelected: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4 0 4 4',
        background: theme.palette.action.selected, 
        '&:hover': {
             background: theme.palette.action.focus,
             cursor: 'pointer',
        },
    },
    smsContactsPhoto: {
        width: 45,
        height: 45,
        borderRadius: '50%',
        objectFit: 'cover',
        marginRight: 10,
    },
    smsNoContactsPhoto: {
        width: 45,
        height: 45,
        borderRadius: '50%',
        objectFit: 'cover',
        marginRight: 10,
        color: 'white',
        background: theme.palette.primary.dark,
    },
    discardNewMessageIcon: {
        width: 20,
        height: 20,
        marginRight: 30,
        color: 'transparent',
        transition: 'color 0.25s ease-in',
    },
    discardNewMessageIconHover: {
        width: 20,
        height: 20,
        marginRight: 30,
        color: theme.palette.secondary.main,
        transition: 'color 0.25s step-start',
    },
    smsContactsTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        margin: 0,
        textAlign: 'left',
        maxHeight: '20px',
    },
    smsContactsInfo: {
        flex: 1,
    },
    contactNameDate: {
        display: 'inline-flex',
        minWidth: '100%',
        justifyContent: 'space-between',
    },
    smsContactsDate: {
        fontSize: 14,
        color: theme.palette.text.disabled,
        margin: 0,
        textTransform: 'none',
        textAlign: 'left',
        fontWeight: 400,
    },
    notDeliveredIcon: {
        width: '18px',
        height: '18px',
        color: theme.palette.secondary.main,
        alignSelf: 'center',
        lineHeight: 1.5,
    },
    unreadMessages: {
        minWidth: '18px',
        maxWidth: '42px',
        height: '18px',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 600,
        alignSelf: 'center',
        lineHeight: 1.5,
        color: 'white',
        backgroundColor: theme.palette.primary.dark,
    },
}));

export { useStyles, useNativeStyles }