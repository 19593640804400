import { createSlice } from "@reduxjs/toolkit";

const ConferenceSlice = createSlice({
  name: "conferences",
  initialState: [],
  reducers: {
    setConferences(state, action) {
      let { conferenceRooms } = action.payload;
      let aux,index;
      
      if (action.payload.hasOwnProperty("conference")) {
        aux = action.payload.conference;
        index = conferenceRooms.findIndex(item=>item.name===aux.name)
        state[index].type = aux.type;
        state[index].name = aux.name;
      }else{
        state=conferenceRooms;
      }
      
      return state;
    },
  },
});

export const { setConferences, updateConferenceType } = ConferenceSlice.actions;
export default ConferenceSlice.reducer;
