import React,{useState,createRef,useEffect} from "react";
import { useHistory } from "react-router-dom";
import FlagIcon from "../utils/FlagIcon.js";
import clsx from "clsx";
import { connect } from "react-redux";
import {
  setLoading,
  createSession,
  updateLocalSession
} from "../store/features/session/sessionSlice";
import { setError } from "../store/features/error/errorSlice";
import {
  setUser,
  setVoipDeactive,
} from "../store/features/user/userSlice";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router , Link} from "react-router-dom";
import { makeStyles, createTheme, lighten,darken } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { themeObjectDark, themeObjectLight } from "../theme";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Formik, Form, Field } from "formik";
import {
  Grid,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  Tooltip,
  FormHelperText,
  CircularProgress
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { attemptLogin } from '../utils/utils';
import CodeIcon from '@material-ui/icons/Code';
import StorageIcon from "@material-ui/icons/Storage";
import Image from "material-ui-image";
import alkemyLogo from "../assets/images/alkemy_dev_icon.png"
import Logo from "../assets/images/mcl-logo.svg";
const crypto = require('crypto');

const mapDispatchToProps = {
  setLoading,
  createSession,
  updateLocalSession,
  setUser,
  setVoipDeactive,
  setError,
};

const Login = (props)=> {
  let history = useHistory();
  const passRef = createRef();
  const {
    setLoading,
    createSession,
    updateLocalSession,
    setUser,
    // setVoipDeactive,
    lastServer,
    session,
    setError,
    branding,
  } = props;
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  let theme = prefersDarkMode ? themeObjectDark : themeObjectLight;

  const typography = {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    htmlFontSize: 16,
    fontSize: 16,
    body1: {
      fontFamily: "Roboto",
      color: theme.palette.text.softer,
      fontSize: "16px",
      lineHeight: "1.8",
      "& a": {
        color: prefersDarkMode
          ? branding.colors.darkMode.light
          : branding.colors.lightMode.light,
        textDecoration: "none",
      },
    },
  };

  const themeConfig = React.useMemo(
    () =>
      createTheme({
        typography,
        props: {},
        overrides: {
          MuiTableRow: {
            root: {
              "&$selected, &$selected:hover": {
                backgroundColor: lighten(theme.palette.primary.light, 0.9),
              },
              "&$hover:hover": {
                backgroundColor: lighten(theme.palette.primary.light, 0.9),
              },
            },
          },
          MuiCheckbox: {
            root: {
              "&:hover": {
                backgroundColor: lighten(theme.palette.primary.light, 0.75),
              },
              "&$checked, &$checked:hover": {
                "& svg": {
                  color: theme.palette.primary.light,
                },
              },
            },
            colorSecondary: {
              "&$checked": {
                "&:hover": {
                  backgroundColor: lighten(theme.palette.primary.light, 0.75),
                },
              },
            },
          },
          MuiToggleButton: {
            root: {
              padding: ".2em .8em",
              border: "2px solid white",
              color: "white",
              backgroundColor: `rgba(theme.palette.primary.main,.5)`,
              "&$selected": {
                backgroundColor: theme.palette.primary.light,
                color: "white",
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                  color: "white",
                },
              },
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
                color: "white",
              },
            },
          },
          MuiTabs: {
            root: {
              color: theme.palette.text.secondary,
            },
            indicator: {
              backgroundColor: theme.palette.primary.light,
            },
          },
          MuiTab: {
            root: {
              "&$selected": {
                color: theme.palette.primary.light,
              },
              "&$disabled": {
                color: "rgba(0,0,0,.35)",
              },
            },
          },
          MuiOutlinedInput: {
            root: {
              "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                borderColor: theme.palette.primary.light,
              },
              "&$focused $notchedOutline": {
                borderColor: theme.palette.primary.light,
              },
            },
          },
        },
        palette: {
          ...theme.palette,
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            ...theme.palette.primary,
            light: prefersDarkMode
              ? branding.colors.darkMode.light
              : branding.colors.lightMode.light,
            main: prefersDarkMode
              ? branding.colors.darkMode.main
              : branding.colors.lightMode.main,
            dark: prefersDarkMode
              ? branding.colors.darkMode.dark
              : branding.colors.lightMode.dark,
          },
          background: {
            ...theme.palette.background,
            // paper: prefersDarkMode
            //   ? branding.colors.darkMode.light
            //   : branding.colors.lightMode.light,
            default: prefersDarkMode
              ? branding.colors.darkMode.dark
              : branding.colors.lightMode.dark,
          },
        },
      }),
    // eslint-disable-next-line
    [prefersDarkMode]
  );

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "100vh",
      "& input:hover + fieldset, & input:hover + div + fieldset": {
        borderColor: theme.palette.primary.light + "!important",
      },
      "& input:invalid + fieldset": {
        borderColor: "red",
        borderWidth: 2,
      },
      "& input:valid:focus + fieldset, & input:valid:focus + div + fieldset": {
        borderColor: theme.palette.primary.light,
        borderLeftWidth: 6,
      },
    },
    icon: {
      color: theme.palette.primary.light,
    },
    loginButton: {
      background: prefersDarkMode
        ? theme.palette.primary.light
        : theme.palette.primary.main,
      color: "white",
      "&:hover": {
        background: prefersDarkMode
          ? darken(theme.palette.primary.light, 0.25)
          : darken(theme.palette.primary.main, 0.25),
      },
    },
    top: {
      zIndex: 995,
      color: theme.palette.primary.light,
      animationDuration: "550ms",
    },
    circle: {
      strokeLinecap: "round",
    },
    overlay: {
      position: "absolute",
      top: "0",
      left: "0",
      display: "flex",
      width: "100vw",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 991,
    },
    formControl: {
      display: "inline-block",
      visibility: "collapse",
      width: "0px",
    },
    paper: {
      flexDirection: "column",
      justifyContent: "flex-start",
      overflowY: "scroll",
      height: "100vh",
      width: "100vw",
      padding: theme.spacing(4, 2),
      [theme.breakpoints.up("sm")]: {
        overflowY: "hidden",
        height: "unset",
        width: "unset",
        maxWidth: "600px"
      },
    },
    form: {
      width: "100%",
      "&>div": {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up("sm")]: {
          marginBottom: theme.spacing(5),
        },
      },
      "& button": {
        padding: theme.spacing(1.5),
      },
    },
    devBy: {
      position: "absolute",
      width: "35px",
      height: "auto",
      background: "white",
      border: "1px solid #dedede",
      padding: "3px",
      borderRadius: "50%",
      bottom: theme.spacing(1),
      right: theme.spacing(3),
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    imageContainer: {
      "&>div": {
        backgroundColor: "transparent !important",
        display: "flex",
        paddingTop: `${theme.spacing(10)}px !important`,
        [theme.breakpoints.up("sm")]: {
          paddingTop: "unset !important",
          paddingBottom: theme.spacing(2),
          minWidth: "600px",
          minHeight: "265px",
        },
      },
    },
    logo: {
      textAlign: "center",
      marginBottom: theme.spacing(2),
      width: "100%",
      margin: "0 auto",
      position: "relative !important",
      [`@media (min-width: 360px) and (max-width: 414px) and (min-height: 640px) and (max-height: 736px) and (orientation: portrait)`]: {
        marginTop: "10rem",
      },
      [`@media (width: 360px) and (height: 740px) and (orientation: portrait)`]: {
        marginTop: "4rem",
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "60%",
        minWidth: "371px",
      },
    },
    centerText: {
      fontSize: "18px",
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
    fontWeightLight: {
      fontWeight: "300",
    },
    margin: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    formContainer: {
      display: "flex",
      marginTop: theme.spacing(2),
      [`@media (width: 360px) and (min-height: 640px) and (max-height: 740px) and (orientation: portrait)`]: {
        paddingBottom: theme.spacing(2),
      },
      [`@media (min-width: 360px) and (max-width: 414px) and (min-height: 640px) and (max-height: 736px) and (orientation: portrait)`]: {
        paddingBottom: theme.spacing(2),
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
    loginHelp: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        marginBottom: "unset",
      },
    },
    selectedServer: {
      border: "1px solid " + theme.palette.primary.hr,
      borderRadius: "4px",
      "&:hover": {
        borderColor: theme.palette.primary.light,
      },
      "&.hasServerSelectionError": {
        marginBottom: theme.spacing(2),
      },
      "& div": {
        display: "flex",
      },
    },
    serverIcon: {
      border: "1px solid " + theme.palette.primary.hr,
      width: "1.5em",
      height: "1.5em",
      backgroundSize: "cover",

      "& .flag-icon": {
        borderRadius: "50%",
      },
    },
    error: {
      zIndex: 100,
      margin: "auto",
      marginLeft: "14px",
      color: "red",
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "10px",
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: "4rem",
      },
    },
    loginError: {
      position: "fixed",
      top: 0,
    },
  }));
  const classes = useStyles();
  const [showPassword,setShowPassword] = useState(false);
  const [server, setServer] = useState("");
  const [openServerSelect,setOpen] = useState(false);
  const [hasServerSelectionError,setServerSelectionError] = useState(false);
  const [photo, setPhoto] = useState("");

  useEffect(() => {
    
    if (prefersDarkMode && branding.logoDark!=="") {
      setPhoto(
        `https://${session.lastServer}3.my-cloudline.net:9003/${branding.logoDark}`
      );
    }else if (!prefersDarkMode && branding.logoLight !== "") {
      setPhoto(
        `https://${session.lastServer}3.my-cloudline.net:9003/${branding.logoLight}`
      );
    }else{
      setPhoto(Logo);
    }
    // eslint-disable-next-line
  }, [branding]);

  useEffect(()=>{
    if(session && server==="" && lastServer!=="") setServer(lastServer);
    // eslint-disable-next-line
  },[server,session]);

  const toggleOpenServerSelect = ()=>{
    setOpen(!openServerSelect);
  }

  const handleValidation = (values) => {
    const errors = {};
    
    if (!values.username) {
      errors.username = "User ID Required";
    } 

    if (!values.password) {
      errors.password = "Password Required";
    }

    if(server.length===0){
      setServerSelectionError(true);
    }

    return errors;
  }

  const handleServerSelect = (e)=>{
    setServer(e.target.value);
    setServerSelectionError(false);
  }

  const handleSubmitLogin = (values, {setSubmitting}) => {
    setLoading(true);
    if(server.length>0){
      setServerSelectionError(false);
      values.server=server;

      let response = attemptLogin(values);
  
      Promise.all([response])
        .then((response) => {
          let data = response[0];
          data.server = server;
          if (data.hasOwnProperty("userProfile")){
            data.voipX = crypto.createHash('sha256').update(values.password).digest('hex');
            createSession(data);
            updateLocalSession(data);
            setUser(data);
            setSubmitting(false);
            setLoading(false);
            // setVoipDeactive();
          } else{
            throw data;
          }
        })
        .then((e) => history.push("/dashboard/call/phone/all"))
        .catch((e) =>{
          setError({
            visible: true,
            type: "error",
            message: `${e}`,
          })
          setSubmitting(false);
          setLoading(false);
        });
    }else{
      setServerSelectionError(true);
      setSubmitting(false);
      setLoading(false);
    }
  };

  const getInputAdornment = ()=>{
    if(showPassword){
      return (
        <InputAdornment position="end">
          <VisibilityIcon
            color="disabled"
            onClick={(e) => togglePasswordVisibility()}
          />
        </InputAdornment>
      );
    }else{
      return (
        <InputAdornment position="end">
          <VisibilityOffIcon
            color="disabled"
            onClick={(e) => togglePasswordVisibility()}
          />
        </InputAdornment>
      );
    }
  }

  const togglePasswordVisibility = () => {
    let field = passRef.current
    if (field.type === "password") {
      setShowPassword(true);
      field.type = "text";
    } else {
      setShowPassword(false);
      field.type = "password";
    }
  }

  const serverOptionsList = [
    { title: "United States", icon: "us" },
    { title: "Germany", icon: "de" },
    { title: "Dev", icon: "dev" },
  ];

  const buildOptions = ()=>{
    return serverOptionsList.map((item) => {
      return <MenuItem key={item.title} value={item.icon} selected={item.icon===server}>{item.title}</MenuItem>;
    });
  }

  const getFlagIcon = ()=>{
    switch(server){
      case "de":
        return <FlagIcon code={server} />;
      case "us":
        return <FlagIcon code={server} />;
      case "dev":
        return <CodeIcon className={classes.icon}/>;
      default:
        return <StorageIcon className={classes.icon} />;
    }
  }
  const getServerTitle = ()=>{
    switch(server){
      case "de":
        return "Germany";
      case "us":
        return "United States";
      case "dev":
        return "Development";
      default:
        return "Select Server";
    }
  }

  return (
    <Router>
      <ThemeProvider theme={themeConfig}>
        <CssBaseline />
        <Container
          fixed
          disableGutters
          className={clsx(classes.root, classes.flexContainer)}
        >
          {!session.isLoading ? (
            <Paper className={clsx(classes.paper, classes.flexContainer)}>
              <span className={classes.imageContainer}>
                <Image
                  src={photo}
                  aspectRatio={22/9}
                  errorIcon={<img src={Logo} alt={`${branding.companyName} logo`} className={classes.logo}/>}
                  alt={`${branding.companyName} logo`}
                  className={classes.logo}
                />
              </span>

              <Typography
                className={clsx(classes.centerText, classes.fontWeightLight)}
              >
                Welcome to the {branding.companyName} App!
                <br />
                To login, please provide your user id and password.
              </Typography>
              <Container
                fixed
                className={clsx(classes.formContainer, classes.flexContainer)}
              >
                <Formik
                  initialValues={{
                    username: "",
                    password: "",
                    server: lastServer,
                  }}
                  validate={handleValidation}
                  onSubmit={(values, setSubmitting) =>
                    handleSubmitLogin(values, setSubmitting)
                  }
                >
                  {({ submitForm, isSubmitting, isValid, dirty }) => (
                    <Form
                      className={classes.form}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <Field
                        variant="outlined"
                        component={TextField}
                        fullWidth
                        autoComplete="current-username"
                        name="username"
                        type="text"
                        label="User ID"
                      />
                      <br />
                      <Field
                        variant="outlined"
                        component={TextField}
                        fullWidth
                        type="password"
                        label="Password"
                        name="password"
                        autoComplete="current-password"
                        InputProps={{
                          endAdornment: getInputAdornment(),
                          inputRef: passRef,
                        }}
                      />

                      <Tooltip title="Server selection">
                        <Grid
                          container
                          className={
                            hasServerSelectionError
                              ? classes.selectedServer +
                                " hasServerSelectionError"
                              : classes.selectedServer
                          }
                          alignItems="center"
                        >
                          <Grid item>
                            <IconButton
                              color="primary"
                              aria-label="select server"
                              onClick={toggleOpenServerSelect}
                            >
                              {getFlagIcon()}
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <span onClick={toggleOpenServerSelect}>
                              {getServerTitle()}
                            </span>
                          </Grid>
                          <Grid item>
                            <FormControl
                              className={classes.formControl}
                              error={hasServerSelectionError}
                            >
                              <Select
                                id="server-select"
                                value={server}
                                onChange={(e) => handleServerSelect(e)}
                                open={openServerSelect}
                                onClose={toggleOpenServerSelect}
                                onOpen={toggleOpenServerSelect}
                              >
                                {buildOptions()}
                              </Select>
                            </FormControl>
                            {hasServerSelectionError && (
                              <FormHelperText className={classes.error}>
                                Server selection is required
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      </Tooltip>
                      {isSubmitting && (
                        <div className={classes.overlay}>
                          <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            className={classes.top}
                            classes={{
                              circle: classes.circle,
                            }}
                            size={60}
                            thickness={4}
                          />
                        </div>
                      )}
                      <Button
                        variant="contained"
                        className={classes.loginButton}
                        fullWidth
                        disabled={isSubmitting || !(isValid && dirty) || server.length===0 }
                        onClick={submitForm}
                      >
                        Login
                      </Button>
                      <Typography
                        className={clsx(
                          classes.loginHelp,
                          classes.fontWeightLight
                        )}
                      >
                        Don&apos;t remember your credentials?{" "}
                        <Link
                          color="primary"
                          to="/login"
                          onClick={() => {
                            window.open(`mailto:support@my-cloudline.com?subject=Forgot Password`)
                          }}>
                          Forgot Password
                        </Link>
                        <br></br>
                        Not a current user?{" "}
                        <Link
                          color="primary"
                          to="/login"
                          onClick={() => {
                            window.open(`mailto:support@my-cloudline.com?subject=Request Access`)
                          }}>
                          Request Access
                            </Link>
                      </Typography>
                    </Form>
                  )}
                </Formik>
              </Container>
            </Paper>
          ) : <div></div>}
        </Container>

        <a
          href="https://www.alkemydev.com"
          rel="noreferrer noopener"
          target="_blank"
        >
          <Tooltip title="Developed by Alkemy">
            <img
              src={alkemyLogo}
              alt="developed by alkemy"
              className={classes.devBy}
            />
          </Tooltip>
        </a>
      </ThemeProvider>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return { 
    session: state.session, 
    lastServer: state.session.lastServer,
    branding: state.branding,
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Login);
