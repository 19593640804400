import { createSlice } from "@reduxjs/toolkit";

const SmsNewConversation = createSlice({
    name: "smsNewConversation",
    initialState: {
        active: false,
        conversation_id: "new",
        number: "",
        countyCode: "",
        name: "New message",
        profileImage: null,
        lastMessage: {
            direction: "",
            dateTime: "",
            message: "",
        }
    },
    reducers: {
        setSmsNewConversationActive(state, action) {
            return (state = {
                ...state, 
                active: action.payload 
            });
        },
        setSmsNewConversationNumber(state, action) {
            return (state = {
                ...state, 
                number: action.payload.number,
                countryCode: action.payload.countryCode,
            });
        },
        resetSmsNewConversation(state) {
            return (state = {
                active: false,
                conversation_id: "new",
                number: "",
                countyCode: "",
                name: "New message",
                profileImage: null,
                lastMessage: {
                    direction: "",
                    dateTime: "",
                    message: "",
                }
            })
        }
    },
});

export const { setSmsNewConversationActive, setSmsNewConversationNumber, resetSmsNewConversation} = SmsNewConversation.actions;
export default SmsNewConversation.reducer;
