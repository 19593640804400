import React,{useState,useEffect,useRef,createRef} from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Fade, Backdrop } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ToggleButton from "@material-ui/lab/ToggleButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import Slider from "@material-ui/core/Slider";
import FastForwardIcon from "@material-ui/icons/FastForward";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  modal: {
    fontFamily: "Roboto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2, 3),
    borderRadius: "4px",
    boxShadow:
      "0px 19px 38px rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22)",
  },
  window: {
    fontSize: "18px",
    fontWeight: "300",
    padding: theme.spacing(2, 3),
    maxWidth: "100%",
    background: theme.palette.primary.light,
    borderRadius: "4px",
    boxShadow: "inset 0px 4.38132px 4.38132px rgba(0, 0, 0, 0.25)",
    color: "white",
  },
  close: {
    position: "absolute",
    right: "-.65em",
    top: "-.65em",
    background: "black",
    padding: "3px",
    color: "white",
    "&:hover": {
      background: "black",
    },
  },
  paper: {
    position: "relative",
    minWidth: "500px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    border: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: "4px",
  },
  title: {
    fontWeight: "300",
  },
  windowGrid: {
    marginBottom: "8px",
    justifyContent: "space-between",
  },
  timeline: {
    color: theme.palette.primary.light,
    width: "98%",
    margin: "15px auto",
    "& .MuiSlider-thumb": {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginLeft: "-7px",
      boxShadow: "inset 0px -4.38132px 4.38132px rgba(0, 0, 0, 0.25)",
    },
  },
  controls: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  volumeControl: {
    position: "absolute",
    right: "-5em",
    height: "100%",
    top: "0",
    backgroundColor: theme.palette.background.paper,
    border: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2,0),
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  volumeSlider: {
    margin: "10px",
  },
  volume: {
    color: theme.palette.text.secondary,
    background: "transparent",
    "&:hover": {
      "& svg": {
        color: theme.palette.primary.light,
      },
      background: "none",
    },
    "&.MuiToggleButton-root.Mui-selected": {
      background: "transparent",
    },
  },
  mute: {
    color: theme.palette.text.secondary,
    background: "transparent",
    "&:hover": {
      "& svg": {
        color: theme.palette.secondary.main,
      },
      background: "none",
    },
    "&.MuiToggleButton-root.Mui-selected": {
      background: "transparent",
    },
  },
  volumeSelected: {
    "& svg": {
      color: theme.palette.primary.light,
    },
  },
  muteSelected: {
    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
  pause: {
    "& svg": {
      color: theme.palette.primary.light,
    },
  },
}));

const AudioPlayer = (props)=>{
    const classes = useStyles();
    const {open,setOpen,src,from} = props;
    let intervalId = useRef(null);
    const player = createRef();
    const [selected, setSelected] = useState(false);
    const [muteSelected, setMuteSelected] = useState(false);

    const [showVolume, setShowVolume] = useState(false);
    const [playing,setPlaying] = useState(false);
    const [audioSrc, setAudioSrc] = useState(src);
    const [marker, setMarker] = useState(0);
    const [current, setCurrent] = useState("00:00:00");
    const [durationRaw, setDurationRaw] = useState(0);
    const [duration, setDuration] = useState("");
    const [seek, setSeek] = useState(false);

    useEffect(()=>{
      if(open && player.current!==null) {
        setDurationRaw(player.current.duration)
      }
      // eslint-disable-next-line
    },[player])

    // eslint-disable-next-line
    useEffect(()=>{
      setAudioSrc(src);
    })

    const handleClose = () =>{
      resetPlayer();
      setOpen(false);
    }

    const resetPlayer = () =>{
      setPlaying(false);
      setCurrent("00:00:00");
      setMarker(0);
      setDurationRaw(0);
      setDuration("");
      player.current.pause();
      player.current.currentTime=0;
    }
    
    const resetPlaybackRate = ()=>{
        player.current.playbackRate = 1.0
        if (seek) {
          window.clearInterval(intervalId.current);
          setSeek(false);
        }  
    }

    const handleTimeUpdate = () => {
        setMarker(player.current.currentTime);
        setCurrent(convertTime(player.current.currentTime,false));
    }

    const handleProgressBarChange = (event, newValue) => {
        player.current.currentTime = newValue
        resetPlaybackRate()
    }
    
    const handlePlay = ()=>{
        player.current.play();
        setPlaying(true);
        resetPlaybackRate();
    }

    const handlePause = ()=>{
        player.current.pause();
        setPlaying(false);
        resetPlaybackRate();
    }

    const handleSeek = (type) => {
        let aux = player.current;
        
        if (!seek) {
          setSeek(true);
          if (type === "forward") {
            intervalId.current = window.setInterval(
              () => (aux.currentTime += 3),
              100
            );
          } else {
            intervalId.current = window.setInterval(
              () => (aux.currentTime -= 3),
              100
            );
          }
        } else {
          setSeek(false);
          window.clearInterval(intervalId.current);
        } 
    }
    
    const handleVolumeChange = (event, newValue) => {
      if(player.current.volume>0 && player.current.volume<100){
        player.current.volume = newValue/100;
        player.current.muted = false;
      }
    };

    const handleVolumeMute = () => {
      player.current.muted = player.current.muted?false:true;
      setMuteSelected(!muteSelected);
    };

    const convertTime = (time,isDuration) =>{
        var hrs = Math.floor(time / 3600);
        var mins = Math.floor(time / 60);
        var secs = Math.floor(time % 60);

        let newTime;
        
        if (isDuration===false){
            if (hrs < 10) {
                hrs = "0" + String(hrs);
            }
  
            if (mins < 10) {
                mins = "0" + String(mins);
            }

            if (secs < 10) {
                secs = "0" + String(secs);
            }
            newTime = [hrs, mins, secs].join(":");
        }else{
            if(hrs>0){
                hrs = String(hrs) + "hr" + (hrs > 1 ? "s" : "");
            }else{
                hrs = ""
            }

            if(mins>0){
                mins = String(mins) + "min" + (mins > 1 ? "s" : "");
            }else{
                mins = "";
            }

            if(secs>0){
                secs = String(secs) + "sec"+ (secs>1?"s":"");
            }else{
                secs = ""
            }

            newTime = [hrs, mins, secs].join(" ");
        }

        return newTime;
    }

    // let title = audioSrc.split("/")[audioSrc.split("/").length - 1];
    let title="";
    if(from!==null){
      if(from.hasOwnProperty("name") && from.name!==""){
        title = from.name;  // voicemail with name field
      }else if(from.hasOwnProperty("number") && from.number!==""){
        title = from.number;  // voicemail with no name, but number instead
      }else{
        title = from.title;  // audio recording
      }
    }

    return (
      <Modal
        aria-labelledby="play-audio"
        aria-describedby="Audio player"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <IconButton
              aria-label="close"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>

            <div className={classes.window}>
              <Grid container className={classes.windowGrid}>
                <Grid item className={classes.title}>
                  <strong>{title.split(".")[0]}</strong>
                </Grid>
                <Grid item>{playing ? "Playing" : ""}</Grid>
              </Grid>
              <Grid container className={classes.windowGrid}>
                <Grid item>Length: {duration}</Grid>
                <Grid item>{current}</Grid>
              </Grid>
            </div>

            <audio
              ref={player}
              src={audioSrc}
              onCanPlay={(e) =>
                setDuration(convertTime(player.current.duration, true))
              }
              onTimeUpdate={handleTimeUpdate}
            ></audio>
            <Slider
              value={marker}
              min={0}
              max={durationRaw}
              aria-labelledby="continuous-slider"
              className={classes.timeline}
              onChange={handleProgressBarChange}
            />
            <div className={classes.controls}>
              <IconButton
                aria-label="rewind"
                className={classes.rewind}
                onClick={(e) => handleSeek("rewind")}
              >
                <FastRewindIcon fontSize="default" />
              </IconButton>
              {playing ? (
                <IconButton
                  aria-label="pause"
                  className={classes.pause}
                  onClick={handlePause}
                >
                  <PauseIcon fontSize="default" />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="play"
                  className={classes.play}
                  onClick={handlePlay}
                >
                  <PlayArrowIcon fontSize="default" />
                </IconButton>
              )}
              <IconButton
                aria-label="fast forward"
                className={classes.fastForward}
                onClick={(e) => handleSeek("forward")}
              >
                <FastForwardIcon fontSize="default" />
              </IconButton>
              <ToggleButton
                aria-label="volume"
                value={showVolume}
                className={classes.volume}
                onClick={() => {
                  setSelected(!selected);
                  setShowVolume(!showVolume);
                }}
                selected={selected}
                classes={{
                  selected: classes.volumeSelected,
                }}
              >
                <VolumeUpIcon fontSize="default" />
              </ToggleButton>
            </div>
            <div
              className={classes.volumeControl}
              style={{ display: showVolume ? "flex" : "none" }}
            >
              <Slider
                orientation="vertical"
                defaultValue={100}
                min={1}
                max={100}
                step={1}
                aria-labelledby="volume-slider"
                onChange={handleVolumeChange}
                className={clsx(classes.timeline, classes.volumeSlider)}
              />
              <ToggleButton
                aria-label="delete"
                className={classes.mute}
                onClick={handleVolumeMute}
                selected={muteSelected}
                value={muteSelected}
                classes={{
                  selected: classes.muteSelected,
                }}
              >
                <VolumeOffIcon fontSize="default" />
              </ToggleButton>
            </div>
          </div>
        </Fade>
      </Modal>
    );
}

AudioPlayer.propTypes = {
  src: PropTypes.string,
};

export default AudioPlayer;
