import React,{useState} from "react";
import { makeStyles,darken } from "@material-ui/core/styles";
import {
  Modal,
  Fade,
  Backdrop,
  Typography
} from "@material-ui/core";
import {Button,Grid,Paper} from "@material-ui/core";
import { setError } from "../store/features/error/errorSlice";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EmailAutocomplete from "./emailAutocomplete";
import { forwardViaEmail } from "../utils/utils";

const mapDispatchToProps = {
  setError,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      fontSize: "1.125rem",
      marginTop: 0,
      fontWeight: 200,
    },
    maxWidth: "100vw",
    maxHeight: "100vh",
  },
  autocomplete: {
    width: "100%",
  },
  heading: {
    marginTop: 0,
  },
  grid: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
  },
  primary: {
    background: theme.palette.primary.light,
    color: "white",
    marginLeft: theme.spacing(4),
    "&:hover": {
      background: darken(theme.palette.primary.light, 0.25),
    },
  },
  paper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    border: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: "4px",

    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      maxHeight: "100vh",
      maxWidth: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "500px",
      minHeight: "250px",
    },
  },
  buttonFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    paddingBottom: theme.spacing(2),
    "&>button": {
      maxHeight: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "unset",
      "&>button": {
        maxHeight: "unset",
      },
    },
  },
  close: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3),
    background: "black",
    color: "white",
    "&:hover": {
      background: "black",
    },
    [theme.breakpoints.up("md")]: {
      right: "-.65em",
      top: "-.65em",
    },
  },
  cancel: {
    background: theme.palette.text.secondary,
    color: "white",
    "&:hover": {
      background: darken(theme.palette.text.secondary, 0.25),
    },
  },
}));

const defaultEmailTemplate = (name, type) => `Hello,\n\n${name} has forwarded you a ${type}. Attached you will find a copy of your ${type}.\n\nIf you have any questions or problems, please send a message to support@my-cloudline.com\n\nSincerely,\nMyCloudline`;

const ForwardViaEmail = (props) => {
    const classes = useStyles();
    const { open, setOpen, setError, type, file, user, defaultValues } = props;
    const [hasEmailError, setEmailHasError] = useState(false);
    const [ recipient, setRecipient ] = useState([]);
    const [subject, setSubject] = useState(
      `Forwarded ${type} from ${user.firstName + " " + user.lastName}`
    );
    const [body, setBody] = useState(
      defaultEmailTemplate(user.firstName, type)
    );

    const resetModal = ()=>{
      setBody(defaultEmailTemplate(user.firstName, type));
      setSubject(
        `Forwarded ${type} from ${user.firstName + " " + user.lastName}`
      );
      setRecipient([]);
      setEmailHasError(false);
      setOpen(false);
    }

    const isEmailValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const handleValidation = (values) => {
      // validation here
      let test = recipient.map((email) => {
        if (!isEmailValid(email)) return true;
        return false;
      });

      if (test.indexOf(true) !== -1) {
        setEmailHasError(true);
      } else setEmailHasError(false);

      return true;
    };


    const handleSubmit = async () => {
      handleValidation();
      if(!hasEmailError && file!==null){
        let values = {
          ...defaultValues,
          id: type.toLowerCase() === "fax" ? file.fid : file.vid,
          group: type.toLowerCase() === "fax" ? file.group : null,
          type: type
        };

        let sanitizedBody = body.replace(/(?:\r\n|\r|\n)/g, '<br>');

        let data = {
          to: recipient.toString(),
          from: "no-reply@my-cloudline.com",
          subject: subject,
          body: sanitizedBody,
          title: file.hasOwnProperty("title")?file.title:"Voicemail"
        };

        try {
          let email = await forwardViaEmail(values, data);
          
          if(email instanceof Error) throw email.message;

          setError({
            visible: true,
            type: "success",
            message: "Email Sent Successfully.",
          });
          resetModal();
        } catch (error) {
          setError({
            visible: true,
            type: "error",
            message: error,
          });
        }
      }
      
    };

    return (
      <Modal
        aria-labelledby="forward-via-email-modal"
        aria-describedby="forward via email"
        className={classes.modal}
        open={open}
        onClose={(e) => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <IconButton
              aria-label="close"
              className={classes.close}
              size="small"
              onClick={(e) => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <Grid container className={classes.grid}>
              <Grid item xs={12}>
                <Typography
                  color="inherit"
                  variant="h5"
                  component="div"
                  className={classes.heading}
                >
                  Send {type} via Email
                </Typography>
              </Grid>
            </Grid>

            <Grid container className={classes.grid}>
              <Grid item xs={12}>
                <Typography color="inherit" variant="body1" component="div">
                  Please type or copy and paste the email addresses below.
                </Typography>
                <EmailAutocomplete
                  value={recipient}
                  setValue={setRecipient}
                  placeholder="Recipient(s)"
                  className={classes.autocomplete}
                  hasError={hasEmailError}
                  setHasError={setEmailHasError}
                />
              </Grid>
            </Grid>

            {/* <Grid container className={classes.grid}>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bcc}
                          onChange={() => setBcc(!bcc)}
                          name="bcc"
                        />
                      }
                      label="Send as BCC to All Recipients"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid> */}

            {/* <Grid container className={classes.grid}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <FormGroup>
                    <TextField
                      id="subject"
                      label="Subject"
                      placeholder="Please type your email subject."
                      defaultValue={subject}
                      onChange={setSubject}
                      variant="outlined"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container className={classes.grid}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <FormGroup>
                    <TextField
                      id="message"
                      label="Message"
                      multiline
                      rows={6}
                      defaultValue={body}
                      variant="outlined"
                      placeholder="Please type the body of your message."
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid> */}

            <Grid container className={classes.grid}>
              <Grid item xs={12}>
                Attached {type}: &emsp;{file !== null ? file.title : ""}
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.buttonFooter}>
              <Button
                variant="contained"
                size="medium"
                onClick={(e) => setOpen(false)}
                className={classes.cancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="medium"
                disabled={recipient.length===0 || hasEmailError}
                className={classes.primary}
                onClick={handleSubmit}
              >
                Email
              </Button>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForwardViaEmail);