import { createSlice } from "@reduxjs/toolkit";

const PersonalPhonebookSlice = createSlice({
  name: "personalPhonebook",
  initialState: [],
  reducers: {
    setPersonalPhonebook(state, action) {
      const { personaldirectory } = action.payload;
      return (state = personaldirectory);
    },
  },
});

export const { setPersonalPhonebook, getPersonalPhonebookEntry } = PersonalPhonebookSlice.actions;
export default PersonalPhonebookSlice.reducer;
