import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { connect } from "react-redux";
import { setError } from "../store/features/error/errorSlice";
import { 
  setActiveCall,
  setSipSessions,
  setCallingNumber,
  setCalling,
  setSipCalling,
} from "../store/features/user/userSlice";
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useStyles } from "./styles/viewStyles";

import SmsContactsList from '../components/SmsContactsList';
import SmsMessagesList from '../components/SmsMessagesList';

const mapDispatchToProps = {
  setError,
  setActiveCall,
  setSipSessions,
  setCallingNumber,
  setCalling,
  setSipCalling,
};

const SmsView = (props) => {
  const classes = useStyles();
  const { 
    window,
    history,
    voip,
    setCallingNumber,
    setCalling,
    setSipCalling,
  } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [value, setValue] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDialClick = () => {
    if (value !== null) {
      setCalling(true);
    }
  };

  const handleSipDialClick = () => {
    setSipCalling(true);
    setCallingNumber(value);
    history.push("/dashboard/call/phone/all")
  }

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <div className={classes.root}>
      <nav className={classes.drawerSms} aria-label="call bar">
        <Hidden mdUp>
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerSmsPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div
              className={
                mobileOpen
                  ? clsx(classes.menuSmsOpen, classes.menuButton)
                  : clsx(classes.menuSmsClosed, classes.menuButton)
              }
            >
              <IconButton
                aria-label="open menu"
                onClick={handleDrawerToggle}
                className={classes.menuButtonIcon}
              >
                {mobileOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <div className={classes.smsMessagesContainer}>
              <SmsContactsList
                setValue={setValue}
              />
            </div>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            classes={{
              paper: classes.drawerSmsPaper,
            }}
            variant="permanent"
            open
          >
            <div className={classes.smsMessagesContainer}>
              <SmsContactsList
                history={history}
                setValue={setValue}
              />
            </div>
          </Drawer>
        </Hidden>
      </nav>
      <SmsMessagesList
        //handleDialClick={handleDialClick}
        handleDialClick={voip.active === 'yes' ? handleSipDialClick : handleDialClick}
        setValue={setValue} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    user: state.user,
    phonebook: state.phonebook,
    voip: state.user.voip,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SmsView)
);
