import moment from 'moment';
import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import SmsMessagesListItem from '../SmsMessagesListItem';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CallIcon from "@material-ui/icons/CallOutlined";
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from "./styles";
import SendIcon from '@material-ui/icons/Send';
import PersonIcon from '@material-ui/icons/Person';
import EmojiPicker from '../EmojiPicker'
import { setSmsContact, resetSmsContact } from "../../store/features/smsConversations/smsContactSlice";
import { setNewSms, clearSms } from "../../store/features/smsConversations/smsNewMessageSlice";
import { setNewMms, clearMms } from "../../store/features/smsConversations/smsNewMmsSlice";
import { setSmsNewConversationNumber, resetSmsNewConversation } from "../../store/features/smsConversations/smsNewConversationSlice";
import MuiPhoneNumber from "material-ui-phone-number";
import { getSmsContactConversations, sendSms, } from "../../utils/utils";
import { resetSession } from "../../store/features/session/sessionSlice";
import { useDropzone } from "react-dropzone";
import AddIcon from '@material-ui/icons/Add';
import { ImageBackground, TouchableHighlight, Text } from "react-native";

const Avatar2 = withStyles({
  root: {
    alignSelf: 'center'
  }
})(Avatar);

const mapDispatchToProps = {
  setSmsNewConversationNumber,
  resetSmsNewConversation,
  setSmsContact,
  resetSmsContact,
  setNewSms,
  clearSms,
  setNewMms,
  clearMms,
  resetSession,
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const SmsMessagesList = (props) => {
  const {
    history,
    session,
    resetSession,
    smsContact,
    smsNewConversation,
    smsNewMessage,
    smsNewMms,
    user,
    setSmsContact,
    resetSmsContact,
    setSmsNewConversationNumber,
    resetSmsNewConversation,
    setNewSms,
    clearSms,
    setNewMms,
    clearMms,
    setValue,
    handleDialClick,
  } = props;
  const classes = useStyles();

  const defaultValues = {
    server: session.lastServer,
    token: session.token,
  };

  const [conversationData, setConversationData] = useState({ number: "", name: "", profileImage: null, messages: [] })
  const [emojiPicker, setEmojiPicker] = useState(false);

  const getMessages = () => {
    let fetchMessages = getSmsContactConversations(defaultValues, smsContact);

    Promise.all([fetchMessages])
      .then((res) => {
        for (var i = 0; i < res.length; i++) {
          if (res[i] instanceof Error && res[i].message === "Unauthorized") {
            resetSession();
            history.push('/login');
            return;
          }
        }

        if (res[0].Conversation && res[0].Conversation.length) {
          setConversationData(res[0].Conversation[0])
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useInterval(() => {
    if (smsContact.id && smsContact.id !== "new") {
      getMessages();
    }
  }, 30000);

  useEffect(() => {
    // simpleBarRef.current.recalculate();
    if (smsContact.id) {
      if (smsContact.id === "new") {
        setConversationData({ number: smsNewConversation.number, 
                              name: smsNewConversation.name, 
                              profileImage: null, 
                              messages: [] });
      }
      else {
        getMessages();
      }
    }
    // eslint-disable-next-line
  }, [smsContact, smsNewConversation.number, smsNewConversation.name])

  useEffect(() => {
    setValue(conversationData.number)
    scrollToBottom()
  }, [conversationData, setValue]);

  const onEmojiClickOutside = () => {
    setEmojiPicker(false);
  }

  const handleEmojiPicker = () => {
    setEmojiPicker(!emojiPicker);
  }

  const addEmoji = (e) => {
    // console.log("addEmoji: " + JSON.stringify(e))
    setNewSms(smsContact.id, setNewSms({ id: smsContact.id, message: smsNewMessage[smsContact.id] + e.native }))
    handleEmojiPicker();
  }

  const handleSubmit = e => {
    e.preventDefault();
    
    const fromPhone = user.conversation.number;

    const toPhone = (smsContact.id === "new") ? smsNewConversation.number : conversationData.number;
    if (toPhone === "") {
      alert("You need to specify the number of the receiver.");
      return;
    }

    const countryCode = (smsContact.id === "new") ? smsNewConversation.countryCode 
                                                  : "";
    const toPhonePretty = (smsContact.id === "new") ? `${countryCode} ${toPhone}`
                                                    : conversationData.number_pretty;

    const mmsMedia = (smsNewMms[smsContact.id]) ? smsNewMms[smsContact.id].src : "";

    let sendSmsPromise = sendSms(defaultValues, fromPhone, toPhone, countryCode, toPhonePretty, smsNewMessage[smsContact.id], mmsMedia);
    Promise.all([sendSmsPromise])
      .then(() => {
        if (smsContact.id === "new") {
          clearSms("new");
          clearMms("new");
          resetSmsNewConversation();
          resetSmsContact();
        }
        else {
          setSmsContact({ conversation_id: smsContact.id })
          getMessages();
          clearSms(smsContact.id);
          clearMms(smsContact.id);
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Failed to send sms! " + err);
      });
  };

  const handleKeypress = e => {
    // it triggers by pressing the enter key
    if (e.key === 'Enter' && e.shiftKey === false) {
      handleSubmit(e);
    }
  };

  const handleClearMedia = () => clearMms(smsContact.id);

  const handlePhoneChange = (value, country) => {
    const countryCode = `+${country.dialCode}`;
    const inputNumber = value.substring(countryCode.length+1);
    
    setValue(inputNumber.replace(/\D/g,''))
    setSmsNewConversationNumber({number: inputNumber, countryCode: countryCode});
  }

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const renderMessages = () => {
    if (!conversationData)
      return <div></div>

    let i = 0;
    let messageCount = conversationData.messages.length;
    let tempMessages = [];

    while (i < messageCount) {
      let previous = conversationData.messages[i - 1];
      let current = conversationData.messages[i];
      let next = conversationData.messages[i + 1];
      let isMine = current.direction === "sent";
      let currentMoment = moment(current.dateTime);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.dateTime);
        let previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous.direction === current.direction;

        if (prevBySameAuthor && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }

        if (previousDuration.as('hours') < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.dateTime);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.direction === current.direction;

        if (nextBySameAuthor && nextDuration.as('hours') < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <SmsMessagesListItem
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  }

  const renderHeader = (smsContact) => {
    if (smsContact.id === "")
      return null;

    return <div className={classes.smsMessagesTop}>
      {smsContact.id !== "new" ? renderStandardHeader()
                               : renderNewMessageHeader()}
      <IconButton
        aria-label="call"
        aria-haspopup="true"
        onClick={handleDialClick}
        className={classes.callContactButton}
      >
        <CallIcon />
      </IconButton>
    </div>
  }

  const getName = () => {
    return (conversationData.name && conversationData.name !== "") 
      ? conversationData.name
      : (conversationData.number_pretty &&conversationData.number_pretty !== "") 
        ? conversationData.number_pretty
        : conversationData.number;
  }

  const renderStandardHeader = () => {
    const name = getName();
    return <div className={classes.imageNameDivHeader}>
      {conversationData.profileImage ? <Avatar2 key="smsHeaderImage" alt={name} src={conversationData.profileImage} />
                                     : <PersonIcon key="smsHeaderImage" className={classes.smsNoContactsPhoto} />}
      <div key="smsHeaderName" >{name}</div>
    </div>
  }

  const renderNewMessageHeader = () => {
    return <div className={classes.newMessagePhoneContainer}>
      <div className={classes.newMessagePhoneLabel}>To:</div>
      <MuiPhoneNumber
        autoFocus
        className={classes.newMessagePhone}
        defaultCountry={"us"}
        onlyCountries={["us"]}
        disableAreaCodes={true}
        value={smsNewConversation.number}
        placeholder={"Search..."}
        onChange={(value, country, inputNumber) => handlePhoneChange(value, country, inputNumber)}
        InputProps={{
          disableUnderline: true,
          placeholder: "Search...",
        }}
      />
    </div>
  }

  const smsCharCounter = (messageLength) => {
    if (messageLength <= 160) {
      return 160 - messageLength;
    }
    else if (messageLength < 918) {
      return 153 - messageLength % 153;
    }
    else {
      return 0;
    }
  }

  const smsMessageCounter = (messageLength) => {
    if (messageLength < 161) {
      return 1;
    }
    else {
      return Math.ceil(messageLength / 153);
    }
  }

  const RenderSmsCharacterCounter = () => {
    // const msgLength = smsNewMessage[smsContact.id].length
    const msgLength = (Object.prototype.hasOwnProperty.call(smsNewMessage, smsContact.id))
                      ? smsNewMessage[smsContact.id].length
                      : 0;
    if (msgLength > 150) {
      if (msgLength > 160) {
        return <p className={classes.smsCharacterCounter}>
          {smsCharCounter(msgLength)} / {smsMessageCounter(msgLength)}
        </p>
      } else {
        return <p className={classes.smsCharacterCounter}>
          {smsCharCounter(msgLength)}
        </p>
      }
    }
    return null;
  }

  const onDrop = useCallback(acceptedFiles => {
    // Loop through accepted files
    acceptedFiles.map(file => {
      // Initialize FileReader browser API
      const reader = new FileReader();
      // onload callback gets called after the reader reads the file data
      reader.onload = function(e) {
        // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it. 
        setNewMms({ id: smsContact.id, mms: {src: e.target.result, use: true }});
      };
      // Read the file as Data URL (since we accept only images)
      reader.readAsDataURL(file);
      return file;
    });
  }, [setNewMms, smsContact.id]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: "image/jpeg, audio/wav, audio/x-wav, audio/midi, audio/sp-midi, audio/mid, audio/mpeg3, audio/mpeg, audio/aac, audio/qcelp, audio/amr", 
    multiple: false,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  // const RenderMMSPreviewWithCard = () => {
  //   if (!media)
  //     return null;

  //   return <Card className={classes.root}>
  //     <CardHeader
  //       action={
  //         <IconButton aria-label="remove">
  //           <CloseIcon />
  //         </IconButton>
  //       }
  //     />
  //     <CardMedia
  //       className={classes.media}>
  //         <Image src={media.src}/>
  //     </CardMedia>
  //   </Card>
  // }

  const RenderMMSPreview = () => {
    if (Object.prototype.hasOwnProperty.call(smsNewMms, smsContact.id) && smsNewMms[smsContact.id]) {
      return <ImageBackground source={smsNewMms[smsContact.id].src} style={{
        display: "flex",
        height: 256,
        width: 256,
      }}>
        <TouchableHighlight onPress={handleClearMedia}>
          <Text style={{
            color: "white",
            fontSize: 24,
            fontWeight: "bold",
            textAlign: "center",
            backgroundColor: "#000000a0"
          }}>Remove</Text>
        </TouchableHighlight>
      </ImageBackground>
    }
    return null;
  }

  const messagesEndRef = useRef(null);
  return (
    <div className={classes.smsMessagesList}>
      {renderHeader(smsContact)}
      <div className={classes.smsMessagesListContainer}>
        {renderMessages()}
        <div ref={messagesEndRef} />
      </div>
      <div className={classes.smsCompose}>
        <div className={classes.smsComposeBox}>
          <IconButton
            style={{ padding: '4px 4px 8px 4px', marginLeft: '4px', maxWidth: 48, maxHeight: 48 }}
            aria-label="media"
            aria-haspopup="true"
            onClick={open}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            style={{ padding: '4px 4px 8px 4px', maxWidth: 48, maxHeight: 48 }}
            aria-label="emoji"
            aria-haspopup="true"
            onClick={(e) => handleEmojiPicker()}
          >
            <InsertEmoticonIcon />
          </IconButton>
          <EmojiPicker
            render={emojiPicker}
            onClickOutside={() => onEmojiClickOutside()}
            selectEvent={addEmoji}
          />
          <div className={classes.smsDropInputContainer}
            // Dropzone: is set based on the parent element of the dropzone area. This element determines the width and height of the dropzone area  
            {...getRootProps()}>
              <input {...getInputProps()} />
            {isDragActive ? (
              <div className={classes.smsDropInputDiv}>Release to drop media here</div>
            ) :
              <div style={{display: "flex", width: "-webkit-fill-available"}}>
                <InputBase
                  className={classes.smsComposeInput}
                  id="outlined-basic-email"
                  multiline
                  value={smsNewMessage[smsContact.id]}
                  onChange={e => setNewSms({ id: smsContact.id, message: e.target.value })}
                  onKeyPress={handleKeypress}
                  placeholder="Type a message..."
                  fullWidth
                  inputProps={{
                    maxLength: 918,
                  }} />
                  {RenderMMSPreview()}
              </div>
              }
          </div>
          <div>
            {RenderSmsCharacterCounter()}
            <IconButton
              style={{ padding: '8px', marginRight: '16px', maxWidth: 48, maxHeight: 48 }}
              aria-label="emoji"
              aria-haspopup="true"
              onClick={(e) => handleSubmit(e)}
            >
              <SendIcon style={{
                width: '2rem',
                height: '2rem',
                maxWidth: '2rem',
                maxHeight: '2rem',
              }} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
    smsContact: state.smsContact,
    smsNewConversation: state.smsNewConversation,
    smsNewMessage: state.smsNewMessage,
    smsNewMms: state.smsNewMms,
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmsMessagesList);