import React from "react";
import {
  withRouter,
  Route,
  Redirect
} from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = (props) => {
  const { loggedIn, checkLocalSession, children, ...rest } = props;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
    loggedIn: state.session.loggedIn,
  };
};

export default withRouter(
  connect(mapStateToProps)(PrivateRoute)
);