import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import Drawer from '@material-ui/core/Drawer';
import { initiateCall } from "../utils/utils";
import { 
  setActiveCall,
  setSipSessions,
 } from "../store/features/user/userSlice";
import { setError } from "../store/features/error/errorSlice";
import { 
  renderDrawerMenu,
  sipUseEffect,
  webRtcNotifyCallback,
  sipDialClick,
  getPhoneBookEntry,
} from "../utils/common";
import ConferenceTable from "../components/conferenceTable";
import HelpMenu from "../components/helpMenu";
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { WebRtcContextJsSIP } from "../index";
import { useStyles } from "./styles/viewStyles";

const mapDispatchToProps = {
  setError,
  setActiveCall,
};

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//     flexDirection: "row",
//     flexGrow: 1,
//   },
//   content: {
//     display: "flex",
//     flexDirection: "column",
//     flexGrow: 1,
//   },
//   h100: {
//     height: "100%",
//   },
//   toolbar: theme.mixins.toolbar,
// }));

const ConferenceView = (props) => {
  const classes = useStyles();
  const { 
    match,
    session,
    phonebook,
    setActiveCall,
    history,
    voip,
  } = props;
  const tab = match.params.type;
  const [value, setValue] = useState(null);
  const [calling, setCalling] = useState(false);
  const [sipCalling, setSipCalling] = useState(false);
  const [sipMode, setSipMode] = useState("newSipCall");
  const isCurrentTab = (item) => tab.toLowerCase() === item.type.toLowerCase();
  const [mobileOpen, setMobileOpen] = useState(false);

  const webRtcContextJsSIP = React.useContext(WebRtcContextJsSIP);

  const handleDialClick = () => {
    setCalling(true);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  var controller = new AbortController();
  var signal = controller.signal;
  useEffect(() => {
    if (value !== null && calling) {
      let values = {
        token: session.token,
        server: session.lastServer,
        calledDevice: "810",
        preferredDevice: "office",
      };

      async function makeCall() {
        try {
          const call = await initiateCall(values, signal);

          if (
            typeof call === "object" &&
            call.result.toLowerCase() === "success"
          ) {
            setError({
              visible: true,
              type: "success",
              message: "Success: Call Connected!",
            });
            setCalling(false);
          } else if (typeof call === "object") {
            throw new Error(call.text);
          } else {
            setError({
              visible: true,
              type: "warning",
              message: call,
            });
            setCalling(false);
          }
        } catch (err) {
          setError({
            visible: true,
            type: "error",
            message: err,
          });
          setCalling(false);
        }
      }
      makeCall();
    }
    // eslint-disable-next-line
  }, [value,calling]);

  const notifyCB = webRtcNotifyCallback(phonebook, setValue, setSipMode, setSipCalling, webRtcContextJsSIP, setActiveCall, setSipSessions, history, setError);
  webRtcContextJsSIP.setNotifyCB(notifyCB);

  const handleSipDialClick = sipDialClick(value, webRtcContextJsSIP, setSipMode, setSipCalling);

  useEffect(() => {
    if (value && !value.hasOwnProperty("voicemail")) {
      setValue(getPhoneBookEntry(phonebook, value))
    } 
    sipUseEffect(value, sipCalling, sipMode, webRtcContextJsSIP, setActiveCall)
    // eslint-disable-next-line
  }, [sipCalling])

  const container = window !== undefined ? () => window.document.body : undefined;

  const drawerMenuItems = [
    {
      text: "Personal",
      type: "personal",
      to: "personal"
    },
    {
      text: "Public",
      type: "public",
      to: "public"
    },
  ];

  const conferenceMenu = null;

  const drawerMenu = renderDrawerMenu(
    classes,
    drawerMenuItems,
    isCurrentTab,
    handleDrawerToggle,
    '/dashboard/conference/'
  );

  useEffect(() => {
  }, [tab]);

  return (
    <div className={classes.root}>
    <HelpMenu />
    <nav className={classes.drawer} aria-label="conference bar">
      <Hidden mdUp>
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div
            className={
              mobileOpen
                ? clsx(classes.menuOpen, classes.menuButton)
                : clsx(classes.menuClosed, classes.menuButton)
            }
          >
            <IconButton
              aria-label="open menu"
              onClick={handleDrawerToggle}
              className={classes.menuButtonIcon}
            >
              {mobileOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          {drawerMenu()}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawerMenu()}
        </Drawer>
      </Hidden>
    </nav>
    <ConferenceTable
      title="Conference Manager"
      menu={true}
      tab={tab}
      setValue={setValue}
      menuItems={conferenceMenu}
      handleDialClick={voip.active === "yes" ? handleSipDialClick : handleDialClick}
    />
  </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    phonebook: state.phonebook,
    voip: state.user.voip,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConferenceView)
);
