import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    smsMessagesList: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 1,
        maxWidth: '100%',
        minWidth: 0,
        overflowX: 'hidden',
        overflowY: 'hidden',
        position: 'relative',
        zIndex: 0,
        width: '-webkit-fill-available',
    },
    smsMessagesTop: {
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        flexShrink: '0',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        padding: '10px',
        position: 'relative',
        zIndex: '0',
        borderStyle: 'hidden hidden solid hidden',
        borderWidth: 'thin',
        borderBottomColor: 'rgba(0, 0, 0, 0.12)',
    },
    smsMessagesListContainer: {
         padding: 10,
         paddingBottom: 70,
         display: 'flex',
         flexDirection: 'column',
         overflowY: 'auto',
         overflowX: 'hidden',
         position: 'relative',
         width: '-webkit-fill-available',
         flex: 1,
    },
    smsCompose: {
        margin: '0px',
        padding: '10px 0px 10px 0px',
        display: 'flex',
        boxSizing: 'border-box',
        bottom: 0,
        borderStyle: 'solid hidden hidden hidden',
        borderWidth: 'thin',
        borderTopColor: theme.palette.action.focus,
        backgroundColor: theme.palette.background.paper,
        justifyContent: 'flex-end',
    },
    smsComposeBox: {
        display: 'flex',
        bottom: 0,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        width: '100%',
        position: 'relative',
    },
    smsDropInputContainer: {
        width: 'inherit',
    },
    smsDropInputDiv: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 800,
        padding: 8,
    },
    smsComposeInput: {
        display: 'flex',
        alignItems: 'flex-end',
        paddingBottom: '10px',
        fontSize: 16,
    },
    smsNoContactsPhoto: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        objectFit: 'cover',
        marginRight: 10,
        color: 'white',
        background: theme.palette.primary.dark,
    },
    phoneInput: {
        '& input': {
            borderColor: 'green',
            borderWidth: 2,
        },
    },
    newMessagePhoneContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    newMessagePhoneLabel: {
        paddingRight: '8px',
    },
    newMessagePhone: {
        background: theme.palette.background.light,
        borderRadius: 4,
    },
    smsCharacterCounter: {
        fontSize: 12,
        margin: 0,
        paddingLeft: 8,
    },
    imageNameDivHeader: {
        display: 'inline-flex',
        alignItems: 'center',
    },
}));

export { useStyles }