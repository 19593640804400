import React, { useState,useEffect,createRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import LockIcon from "@material-ui/icons/Lock";
import {
  Grid,
  Paper,
  Button,
  LinearProgress,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { ToggleButtonGroup } from 'formik-material-ui-lab';
import { ToggleButton } from "@material-ui/lab";
import PasswordVisibility from "./passwordVisibility";

const useStyles = makeStyles((theme) => ({
  root: {
    "& h3": {
      fontWeight: 400,
      marginTop: 0,
      marginBottom: theme.spacing(4),
    },
    "& .MuiGrid-root:first-of-type h3": {
      marginBottom: theme.spacing(6),
    },
    "& button": {
      padding: theme.spacing(1.5),
    },
  },
  paper: {
    height: "100%",
    padding: theme.spacing(2, 4),
    marginRight: 0,
    "&>div": {
      marginBottom: theme.spacing(4),
    },
  },
  flexContainer: {
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
  },
  updateButton: {
    margin: "auto",
    maxWidth: "410px",
  },
  gridLine: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    "& p": {
      marginRight: "auto",
    },
    "& label": {
      marginRight: 0,
    },
  },
  marginReset: {
    marginBottom: "0 !important",
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  faxNumberContainer: {
    marginTop: theme.spacing(6.5),
    "&>div": {
      marginBottom: theme.spacing(4),
    },
  },
  smsNumberContainer: {
    marginTop: theme.spacing(3),
    "&>div": {
      marginBottom: theme.spacing(4),
    },
  }
}));

const AdditionalSettings = (props) => {
  const {
    session,
    user,
    updateUserProfile,
    setUser,
    getUserProfileData,
  } = props;
  const { 
    preferredDevice,
    preferredDeviceNumbers,
    voicemail,
    fax,
    conversation,
    incomingProviderNumber,
    webapp,
  } = user
  const classes = useStyles()
  const passRef = createRef()
  const [timeZone, setTimeZone] = useState(user.timeZone)
  const [faxFormat, setFaxFormat] = useState(fax.format)
  const [devicePreference, setDevicePreference] = useState(preferredDevice)
  const [showPassword, setShowPassword] = useState(false)
  const [formChanged, setFormChanged] = useState(false)
  const [faxEnabled, setFaxEnabled] = useState(fax.active)
  const [voicemailEnabled, setVoicemailEnabled] = useState(voicemail.active)
  const [conversationEnabled, setConversationEnabled] = useState(conversation.active)
  const [mobile, setMobile] = useState(preferredDeviceNumbers.mobile)
  const [custom, setCustom] = useState(preferredDeviceNumbers.custom)
  const [clickToDial, setClickToDial] = useState(webapp.features.clickToDial.autoDial)
  // const [incomingProviderNumberValue, setincomingProviderNumberValue] = useState(incomingProviderNumber.number);

  // eslint-disable-next-line
  useEffect(() => {
    if (
      preferredDevice !== devicePreference ||
      fax.format !== faxFormat ||
      user.timeZone !== timeZone ||
      fax.active !== faxEnabled ||
      voicemail.active !== voicemailEnabled ||
      conversation.active !== conversationEnabled ||
      mobile !== preferredDeviceNumbers.mobile ||
      custom !== preferredDeviceNumbers.custom || 
      clickToDial !== webapp.features.clickToDial.autoDial // ||
      // incomingProviderNumberValue !== incomingProviderNumber.number
    ) {
      setFormChanged(true);
    } 
    else {
      setFormChanged(false);
    }
  });

  const handleValidation = (values) => {
    const errors = {};

    return errors;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    let data = {
      token: session.token,
      server: session.lastServer,
      userProfile: {
        firstName: user.firstName,
        lastName: user.lastName,
        preferredDevice: devicePreference,
        timeZone: timeZone,
        preferredDeviceNumbers: {
          mobile: mobile,
          custom: custom,
        },
        voicemail: {
          active: voicemailEnabled,
        },
        fax: {
          email: values.faxEmail,
          active: faxEnabled,
          format: faxFormat,
        },
        conversation: {
          active: conversationEnabled,
        },
        incomingProviderNumber: incomingProviderNumber,
        clickToDial: {
          autoDial: clickToDial
        }
      },
    };

    try {
      await updateUserProfile(data);

      setSubmitting(false);

      try {
        await getUserProfileData(
          {
            token: session.token,
            server: session.lastServer,
          },
          setUser
        );
      } catch (error) {
        setSubmitting(false);
        console.error(error);
      }
    } catch (error) {
      setSubmitting(false);
      console.error(error);
    }
  };

  const generateTimeZoneOptions = () => {
    let timeZones = [
      "UTC-12",
      "UTC-11",
      "UTC-10",
      "UTC-9",
      "UTC-8",
      "UTC-7",
      "UTC-6",
      "UTC-5",
      "UTC-4",
      "UTC-3",
      "UTC-2",
      "UTC-1",
      "UTC-0",
      "UTC+1",
      "UTC+2",
      "UTC+3",
      "UTC+4",
      "UTC+5",
      "UTC+6",
      "UTC+7",
      "UTC+8",
      "UTC+9",
      "UTC+10",
      "UTC+11",
      "UTC+12",
    ];
    return timeZones.map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ));
  };

  return (
    <Formik
      initialValues={{
        office: preferredDeviceNumbers.office,
        mobile: preferredDeviceNumbers.mobile,
        custom: preferredDeviceNumbers.custom,
        incomingProviderNumber: incomingProviderNumber.number,
        smsNumber: conversation.number,
        voicemailEnabled: voicemail.active,
        vacationMode: false,
        voicemailPassword: voicemail.secret,
        faxEnabled: fax.active,
        faxDeliverAs: fax.format,
        faxEmail: fax.email,
        faxNumber: fax.number,
        clickToDial: webapp.features.clickToDial.autoDial,
      }}
      validate={handleValidation}
      onSubmit={(values, setSubmitting) => handleSubmit(values, setSubmitting)}
    >
      {({ submitForm, isSubmitting }) => (
        <Form onSubmit={(e) => e.preventDefault()}>
          <Grid
            container
            direction="row"
            className={classes.root}
            spacing={3}
            alignItems="stretch"
            justify="center"
          >
            <Grid item xs={12} md>
              <Paper className={classes.paper}>
                <h3>My Devices</h3>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel id="preferred-device-label">
                    Preferred Device
                  </InputLabel>
                  <Select
                    labelId="preferred-device-label"
                    id="preferred-device"
                    value={devicePreference}
                    label="Preferred Device"
                    name="preferredDevice"
                    onChange={(e) => setDevicePreference(e.target.value)}
                  >
                    <MenuItem value="office">Office</MenuItem>
                    <MenuItem value="mobile">Mobile</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                </FormControl>
                <Field
                  variant="outlined"
                  component={TextField}
                  fullWidth
                  disabled
                  name="office"
                  type="text"
                  label="Office"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  variant="outlined"
                  component={TextField}
                  fullWidth
                  InputProps={{
                    value: mobile,
                    onChange: (e) => setMobile(e.target.value),
                  }}
                  name="mobile"
                  type="text"
                  label="Mobile"
                />
                <Field
                  variant="outlined"
                  component={TextField}
                  fullWidth
                  InputProps={{
                    value: custom,
                    onChange: (e) => setCustom(e.target.value),
                  }}
                  name="custom"
                  type="text"
                  label="Custom"
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md>
              <Paper className={classes.paper}>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.gridLine,
                    classes.marginReset
                  )}
                >
                  <p>Voicemail</p>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={voicemailEnabled === "yes" ? true : false}
                        name="voicemailEnabled"
                        onChange={(e) =>
                          setVoicemailEnabled(e.target.checked ? "yes" : "no")
                        }
                      />
                    }
                    aria-label="Enable Voicemail"
                    label="Enabled"
                  />
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.gridLine,
                    classes.marginReset
                  )}
                >
                  <p>Mailbox: &emsp;{voicemail.mailbox}</p>
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.gridLine,
                    classes.marginBottom
                  )}
                >
                  <p>Vacation Mode</p>
                  <FormControlLabel
                    control={<Switch disabled={true} name="vacationMode" />}
                    aria-label="Enable Vacation Mode"
                    label="Enabled"
                  />
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.gridLine,
                    classes.marginBottom
                  )}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="preferred-device-label">
                      TimeZone
                    </InputLabel>
                    <Select
                      labelId="timeZone-label"
                      id="timeZone"
                      value={timeZone}
                      label="TimeZone"
                      name="timeZone"
                      onChange={(e) => setTimeZone(e.target.value)}
                    >
                      {generateTimeZoneOptions()}
                    </Select>
                  </FormControl>
                </div>

                <h3>Voicemail Password</h3>
                <Field
                  variant="outlined"
                  component={TextField}
                  fullWidth
                  name="voicemailPassword"
                  type="password"
                  label="Password"
                  autoComplete="voicemail-password"
                  disabled
                  InputProps={{
                    endAdornment: (
                      <PasswordVisibility
                        showPassword={showPassword}
                        togglePasswordVisibility={setShowPassword}
                        passRef={passRef}
                      />
                    ),
                    inputRef: passRef,
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md>
              <Paper className={classes.paper}>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.gridLine,
                    classes.marginReset
                  )}
                >
                  <p>Fax</p>
                  <FormControlLabel
                    control={
                      <Switch
                        name="faxEnabled"
                        checked={faxEnabled === "yes" ? true : false}
                        onChange={(e) =>
                          setFaxEnabled(e.target.checked ? "yes" : "no")
                        }
                      />
                    }
                    aria-label="Enable Fax"
                    label="Enabled"
                  />
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.gridLine,
                    classes.marginReset
                  )}
                >
                  <p>Deliver As</p>
                  <Field
                    component={ToggleButtonGroup}
                    type="checkbox"
                    value={faxFormat}
                    name="faxDeliverAs"
                    exclusive
                    aria-label="fax delivery format"
                    className={classes.faxDeliverAs}
                    onChange={(e, newValue) => setFaxFormat(newValue)}
                  >
                    <ToggleButton value="PDF" aria-label="PDF">
                      PDF
                    </ToggleButton>
                    <ToggleButton value="TIF" aria-label="TIF">
                      TIF
                    </ToggleButton>
                    <ToggleButton value="PDF,TIF" aria-label="PDF and TIF">
                      Both
                    </ToggleButton>
                  </Field>
                </div>
                <div className={classes.faxNumberContainer}>
                  <Field
                    variant="outlined"
                    component={TextField}
                    fullWidth
                    disabled
                    name="faxEmail"
                    type="email"
                    label="Fax Email"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockIcon color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    variant="outlined"
                    component={TextField}
                    fullWidth
                    disabled
                    name="faxNumber"
                    type="text"
                    label="Fax Number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockIcon color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md>
              <Paper className={classes.paper}>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.gridLine,
                    classes.marginReset
                  )}
                >
                  <p>Conversation</p>
                  <FormControlLabel
                    control={
                      <Switch
                        name="conversationEnabled"
                        checked={conversationEnabled === "yes" ? true : false}
                        onChange={(e) =>
                          setConversationEnabled(e.target.checked ? "yes" : "no")
                        }
                      />
                    }
                    aria-label="Enable Conversation"
                    label="Enabled"
                  />
                </div>
                <div className={classes.smsNumberContainer}>
                  <Field
                    variant="outlined"
                    component={TextField}
                    fullWidth
                    disabled
                    name="smsNumber"
                    type="text"
                    label="Sms Number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockIcon color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    variant="outlined"
                    component={TextField}
                    fullWidth
                    disabled
                    name="incomingProviderNumber"
                    type="text"
                    label="Incoming Provider Number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockIcon color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="click-to-dial-input-label">
                      Click-To-Dial
                    </InputLabel>
                    <Select
                      labelId="click-to-dial-label"
                      id="click-to-dial"
                      value={clickToDial}
                      label="Click-To-Dial"
                      name="clickToDial"
                      onChange={(e) => setClickToDial(e.target.value)}
                    >
                      <MenuItem value="yes">Auto Dial On</MenuItem>
                      <MenuItem value="no">Auto Dial Off</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              className={clsx(classes.flexContainer, classes.buttonContainer)}
            >
              {isSubmitting && <LinearProgress />}

              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting || !formChanged}
                onClick={submitForm}
                className={classes.updateButton}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

AdditionalSettings.propTypes = {
  user: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  getUserProfileData: PropTypes.func.isRequired,
};

export default AdditionalSettings;
